import css from '@emotion/css';
import { theme } from '../../constants/theme';

const SearchDetailsStyles = css `

@media (max-width:800px) {

body{
/*background-color: #183D4E !important;*/
}
  .map {
    width: 100% !important;
  }
  .searchContainer {
    display: block !important;
		background-color:#FFFFFF !important;
		

  }
  
  .resultSection .container {
    width: 100% !important;
  }
  
  .resultSection .subContainer {
    min-height: 30px !important;
  }

  .resultSection {
  border-bottom:#CCCCCC 1px solid  !important;
  }


  .childComponent .container {
    width: 100% !important;

  }
  
  .container {
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
    box-shadow: inset 0 -1px 0 0 #ffffff !important;
    position: sticky;

}
.css-yf5vpw .container{
background-color:#ffffff !important;
}
  .subComponent {
    width: 100% !important;
  min-height: 200px !important;
border-bottom: #FFFFFF 2px solid !important;
background-image: linear-gradient(#f6f6f6, #ffffff) !important;
  }
     .subComponent div {
    width: 100% !important;
	background-image: linear-gradient(#f6f6f6, #ffffff) !important;

  }

    .subComponent p{
	padding:4px 0px !important;
		width:90% !important;
  }
  
.headerContent {
width:90% !important;
border:none !important;
background-color: #f6f6f6  !important;
margin:0px !important;
height: 60px !important;
padding:0px !important;
}

.headerContent p, .subHeader {
width:90% !important;
color: #999999 !important;
  text-transform:uppercase  !important;
  font-size: 20px !important;
  line-height: normal !important;
  font-weight: 900 !important;
height: 60px !important;
  border:none !important;
  flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
background-color: #f6f6f6  !important;


}

.subContent p  {
width:90% !important;
border:none !important;
background-color: none !important;
}
 .subContent , .contactInfo {
border:none !important;
background-color: none !important;

}

p.contactInfo , .contactComponent {
  padding: 0px !important;
}



  
 /* .tootlbar  {
    justify-content: end !important;
  }  */
  .searchDetailsComponent .toolbarSelectForm {
    width: 100% !important;
  height: auto !important;
  border-radius: 4px;
  flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
justify-content: center !important;
padding:0px !important;
margin: 0px !important;

  }
  
  .css-d95cxv, .css-cssveg  {  
      width: 50% !important;
flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
  border-radius: 4px;
    background-color: #FFFFFF !important;
	height:40px !important;
    }
  
    input[type=text], .MuiInputBase-input, .MuiInputBase-root {
      font-size: 16px !important;
	  vertical-align: middle !important;
	  height:40px !important;
width: 100% !important;
display: inline-block !important;
position:relative !important;
float:left !important;
padding:0px !important;
line-height: 40px !important;
text-overflow: ellipsis;
margin: 0px !important;
text-indent: 4px !important;
background-color: #FFFFFF !important;
    }
	input[type="text"]::placeholder {  
                  
                /* Firefox, Chrome, Opera */ 
                padding: 0px !important;
				text-align:left;
				width: 100% !important;
				display: inline-block !important;
				margin: 0px !important;

            } 
.searchComponent {
  width: 100% !important;
  height: auto !important;
  border-radius: 0px;
  flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
justify-content: center !important;
padding:0px !important;
margin: 0px !important;
}

.MuiCardContent-root {
    padding: 17px;
	width:90% !important;
}

  
}
/*DESKTOP*/

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.grey};
  
}
.searchContainer {
  display: flex;
  z-index: 0;
}
.headerContent {
  display: flex;
  justify-content: space-between;
}
.subContent {
  display: flex;
  justify-content: space-between;
 height: 80px;
  align-items: center;
}
.tootlbar {
  z-index: 1;
  padding-bottom: 1px;
  width: 100%;
  height: auto;
  display: inline-block;

}

.resultSection {
  z-index: 100 !important;
  padding-bottom: 23px;
}
.cardTitle {
  font-size: 16px;
  font-weight: normal;
  text-decoration:underline;
  line-height:30px;
  color: ${theme.colors.dark};
  
}
.searchContact{
  margin: 0 auto;
  display: flex;
  background-color: ${theme.colors.white};
}
.map {
  width: calc(100% - 600px);
  height: calc(100vh - 72px);
}
.subComponent {
  width: 600px;
  height: calc(100vh - 364px);
  overflow-y: auto;
  cursor: pointer;
  background-color: ${theme.colors.white};

}
.subComponent .buttonComponent{
margin-left:10px;
}
.MuiCardContent-root {
    padding: 17px;
}

.contactComponent {
  padding-right: 17px;
  text-align-last: right;
}
.buttonComponent {
text-align:center !important;
line-height:18px !important;
  color: ${theme.colors.white};
  background-color: ${theme.colors.blue};
  height: 44px;
  font-weight: bold;
  font-size: 14px;
  box-shadow: none;
  
}

.buttonComponent:hover {
  background-color: ${theme.colors.darkBlue};
}

.header {
  width: 456px;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.blue};
}

.MuiPaper-elevation1{
  box-shadow: none;
}

.contactInfo {
  font-size: 16px;
  line-height: normal;
  color: ${theme.colors.darkGrey};
}

.subHeader {
  font-size: 20px;
  font-weight: 900;
  color:#999999  !important;
  text-transform:uppercase  !important;
   line-height:30px;
},

.pagination {
  height: 100%;
  width: 100%;
}
`
export default SearchDetailsStyles;
