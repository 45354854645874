/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FormControl, TextField, SvgIcon } from "@material-ui/core";

import useSearchCount from "../../../../hooks/search-count.hook";
import SearchSelectResults from "./search-select-results";
import { SearchSelectContainer } from "./styles";
import BusinessIcon from "@material-ui/icons/StoreRounded";
import OrgIcon from "@material-ui/icons/BusinessRounded";
import ServiceIcon from "@material-ui/icons/BusinessCenterRounded";
import ProductIcon from "@material-ui/icons/CategoryRounded";
import CovidProductIcon from "@material-ui/icons/LocalHospitalRounded";
import Lang from '../../lang';

const CovidServiceIcon = () => (
  <SvgIcon>
    <path d="M20,6h-4V4c0-1.1-0.9-2-2-2h-4C8.9,2,8,2.9,8,4v2H4C2.9,6,2,6.9,2,8v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M10,4h4v2h-4V4z M15,15h-2v2c0,0.55-0.45,1-1,1s-1-0.45-1-1v-2H9c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1h2v-2 c0-0.55,0.45-1,1-1s1,0.45,1,1v2h2c0.55,0,1,0.45,1,1C16,14.55,15.55,15,15,15z" />
  </SvgIcon>
);
const searchCountKeys = {
  business_name: {
    name: "Business Names",
    Icon: BusinessIcon,
    slug: "businessName",
  },
  organization: { name: "Organizations", Icon: OrgIcon, slug: "organization" },
  service: { name: "Services", Icon: ServiceIcon, slug: "service" },
  product: { name: "Products", Icon: ProductIcon, slug: "product" },
  covid_19_services: {
    name: "COVID-19 Service",
    Icon: CovidServiceIcon,
    slug: "covid19service",
  },
  covid_19_products: {
    name: "COVID-19 Product",
    Icon: CovidProductIcon,
    slug: "covid19product",
  },
};

const SearchSelect = ({
  type,
  description,
  setDescription,
  setSearchFilter,
  withAutocomplete,
  searchFilter,
  sendSearch,
}) => {
  const [results, loading] = useSearchCount(description);
  // const [activeItem, setActiveItem] = useState(-1);
  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      sendSearch();
    }
  };

  return (
    <SearchSelectContainer>
      <FormControl
        classes={
          type === "home"
            ? { root: "homeSearchForm" }
            : { root: "toolbarSearchForm" }
        }
      >
        <TextField
          value={description}
          placeholder = {Lang().fields.searchSelect}
          onChange={({ target }) => {
            setDescription(target.value);
          }}
          classes={
            type === "home" ? { root: "homeSearch" } : { root: "toolbarSearch" }
          }
          InputProps={{
            disableUnderline: true,
          }}
          onKeyDown={onKeyDown}
          // onFocus={() => {
          //   setActiveItem(-1);
          // }}
        />
      </FormControl>
      {description && withAutocomplete && (
        <SearchSelectResults
          {...{
            term: description,
            results,
            loading,
            setSearchFilter,
            searchCountKeys,
          }}
        />
      )}
    </SearchSelectContainer>
  );
};

export default SearchSelect;
