import css from '@emotion/css';
import { theme } from '../../constants/theme';

const DetailPageStyles = css `
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};

}

.tootlbar {
  z-index: 1;

}
.mainContainer {
  padding-left: 156px;
  padding-top: 20px;
  padding-right:30px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  
}

.returnList {
  font-size: 14px;
  color:  ${theme.colors.lightGrey};
  padding-left: 10px;
  line-height: 20px;
}

.LinkData {
  font-size: 14px;
  color:  ${theme.colors.blue};
  line-height: 20px;
  padding-left: 10px;
  cursor: pointer;
}

.LinkDataSocial {
  font-size: 14px;
  color:  ${theme.colors.blue};
  line-height: 20px;
  cursor: pointer;

}
.detailContainer {
  padding-top: 35px;
  padding-bottom: 34px;
  box-shadow: inset 0 -1px 0 0 #e8e9ea;

}


.productInfo{
  padding-top: 12px;
}
.topContainer {
  display: flex;
  color:  ${theme.colors.blue};
  cursor: pointer;
}
.tootlbar {
  z-index: 1;
}

.contactData {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-top: 24px;
}
.contactName{
  font-size: 16px;
  font-weight: bold;
}

.contactSubInfo {
  font-size: 14px;
  color: ${theme.colors.lightGrey};
}

.dataContent{
  padding-left: 12px;
}
.websiteComponent{
  display: flex;
  flex-direction: row;
  color: ${theme.colors.blue};
  padding-top: 16px;
}
.websiteComponent1{
  display: flex;
  flex-direction: row;
  color: ${theme.colors.blue};
  padding-top: 16px;
  justify-content: space-between;

}
.socialWebsites{
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  justify-content: space-between;
}

.socialContent {
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-bottom: 32px;
  box-shadow: inset 0 -1px 0 0 #e8e9ea;
}
.header1 {
  font-size: 18px;
  font-weight: bold;
}

.subheader {
  font-size: 14px;
  font-weight: bold;
}
.businessInfo{
  width: 30%;
  text-align: justify;
}
.data{
  padding-top: 24px;
  box-shadow: inset 0 -1px 0 0 #e8e9ea;
  padding-bottom: 32px;
}
.headerComponent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header{
  line-height: 50px;
  font-size: 44px;
  font-weight: 800;
}
.section {
  padding-top: 12px;
  padding-bootom: 12px;
}
.business{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.address {
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.lightGrey};
}
.addressContainer {
  padding-top: 16px;
}
.detailComponent {
  display: flex;

}
`
export default DetailPageStyles;
