import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';

import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { stepMessages } from '../shared/register-data';
import splashLogo from '../../assets/Splash_Logo.png';
import SPGlobals from './globals';
import { supplierData } from '../shared/register-data';

import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        },
    },
}));

export class Settings extends Component
{
	
    render() {
	
	    const imgSplashLogo = {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		};
		
	    const layout = {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	        paddingTop: '45px',
	    	
	    };

	    const paper = {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	    }
	    
	    const headerPage = {
            marginBottom: '27px',
        };
	    
	    const switchTwoFactor = (status) => {
	    	supplierData.two_factor_auth = status;
            
            BpWebSocket.send({
                call: 'supplierProfileUpdateRequest',
                payload: {
                    'id': supplierData.id,
                    'two_factor_auth': supplierData.two_factor_auth,
                }
            });
	    }
	
	    return (
	        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
		      <img style={imgSplashLogo} src={splashLogo} />
		    </div>
	        <main style={layout}>
	        <Paper style={paper}>
		        <Typography style={headerPage} component="h2" variant="h4" align="center">
		            Account and System Settings
		        </Typography>
	              
                <div id="two-factor-switch" style={{marginLeft: '21px'}} >
                  <FormControl>
	                <FormLabel id="two-factor-label">Two-factor login:</FormLabel>
	                <RadioGroup
	                  defaultValue={supplierData.two_factor_auth}
	                  aria-labelledby="two-factor-label"
	                  name="two-factor-status"
                	  onChange={(e, value) => {
                		  switchTwoFactor(value)
            		  }}
	                >
	                    <FormControlLabel value="1" control={<Radio />} label={"Enabled"} />
	                	<FormControlLabel value="0" control={<Radio />} label={"Disabled"} />
	                </RadioGroup>
	              </FormControl>

	            </div>
	            
	        </Paper>
	        </main>
	        </React.Fragment>
	    );

    }
}

export default Settings;


/*import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

import Lang from '../shared/lang';
import SPGlobals from './globals';
import BpWebSocket from '../shared/bp-websocket';
import homeic1 from '../../assets/HomePage_ic1.png';
import homeic2 from '../../assets/HomePage_ic2.png';
import homeic3 from '../../assets/HomePage_ic3.png';
import splashLogo from '../../assets/Splash_Logo.png';
import homePageLogo from '../../assets/HomePage_Logo.png';

import { supplierData, stepMessages } from '../shared/register-data';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        },
    },
}));

export default function Settings() {
	const classes = useStyles();
	
	const CSS = {
	    headerPage: {
	        marginBottom: '27px',
	    },
		
		imgSplashLogo: {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		},
		
	    layout: {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	    },
	    paper: {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	    }
	}
	
	return (
	        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
		      <img style={CSS.imgSplashLogo} src={splashLogo} />
		    </div>
	        <main style={CSS.layout}>
	        <Paper style={CSS.paper}>
	            <div>
	              <form noValidate autoComplete="off">
	              
                    <div id="contact-method-options">
	                  <FormControl>
		                <FormLabel id="contact-method-label">Choose contact method:</FormLabel>
		                <RadioGroup
		                  aria-labelledby="contact-method-label"
		                  name="contact-method-group"
	                	  onChange={(e, value) => {
	                		  //SPGlobals.items.twoFactorContactMethod = value;
                		  }}
		                >
		                    <FormControlLabel value='' control={<Radio />} label={"E-mail to: "} />
		                	<FormControlLabel value='' control={<Radio />} label={"Text to: "} />
		                </RadioGroup>
		              </FormControl>
		            </div>
		            
			        
	                
	               </form> 
	             </div>
	            
	            
	        </Paper>
	        </main>
	        </React.Fragment>
	    );
	
    return (
        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
	           <img style={CSS.imgSplashLogo} src={splashLogo} />
	         </div>
	        <main style={CSS.layout}>
	         <Paper style={CSS.paper}>
		         <Typography style={CSS.headerPage} component="h2" variant="h4" align="center" className={classes.titleBlocksHeader}>
		           General Settings
		         </Typography>
	            
		         <div id="two-factor-switch">
		             <FormControl>
		                <FormLabel id="two-factor-auth-status">Two-factor authentication:</FormLabel>
		                <RadioGroup
		                  aria-labelledby="two-factor-auth-status"
		                  name="two-factor-status"
		             	  onChange={(e, value) => {
		             		  
		         		  }}
		                >
		                    <FormControlLabel value="1" control={<Radio />} label={"Enabled"} />
		                	<FormControlLabel value="0" control={<Radio />} label={"Disabled"} />
		                </RadioGroup>
		              </FormControl>
	              </div>
	             
	         </Paper>
	        </main>

        </React.Fragment>
    );
}*/