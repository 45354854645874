import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Lang from '../shared/lang';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { supplierData } from '../shared/register-data';
import BpWebSocket from '../shared/bp-websocket';
import SPGlobals from './globals';


const useStyles = makeStyles((theme) => ({
	  root: {
	    '& > *': {
	      margin: theme.spacing(1)
	    },
	  },
})); 	

export default function BasicPageCompanyDetails() {
	var ProvinceSelected = supplierData.province;
	
	const classes = useStyles();
    const field = {
		marginLeft: '7px',
        marginRight: '7px',
        width: '36ch'	
    }
    const headerSection = {
        marginBottom: '9px',
    };
    const headerPage = {
        marginBottom: '27px',
    };
    
    const CSS = {
    	updateBtn: {
    		display: 'none',
    		color: 'white'
    	}
    };
    
    const updateCompanyDetails = () => {
    	
    	supplierData.companyname = document.getElementById('companyname').value;
    	supplierData.address = document.getElementById('address').value;
    	supplierData.city = document.getElementById('city').value;
    	supplierData.description = document.getElementById('description').value;
    	supplierData.province = ProvinceSelected.toLowerCase();
    	supplierData.postalcode = document.getElementById('postalcode').value;
    	supplierData.phonenumber = document.getElementById('phonenumber').value;
    	supplierData.email = document.getElementById('email').value;
    	supplierData.mobile = document.getElementById('mobile').value;
    	supplierData.faxnumber = document.getElementById('faxnumber').value;
    	
        BpWebSocket.send({
            call: 'supplierProfileUpdateRequest',
            payload: {
                'id': supplierData.id,
                'companyname': document.getElementById('companyname').value,
                'address': document.getElementById('address').value,
                'city': document.getElementById('city').value,
                'description': document.getElementById('description').value,
                'province': ProvinceSelected,
                'postalcode': document.getElementById('postalcode').value,
                'phonenumber': document.getElementById('phonenumber').value,
                'email': supplierData.email,
                'tollfree': supplierData.mobile,
                'faxnumber': document.getElementById('faxnumber').value
            }
        });
        
        let updateButton = document.querySelector('#update-btn')
    	
    	updateButton.disabled = true;
        updateButton.innerText = 'Updated';
    }
    
    const handleProvinceChange = input => e => {
        //e.target.name|value;
        ProvinceSelected = e.target.value.toUpperCase();
        onKeyUp_ActivateBtn();
    };
    
    const onKeyUp_ActivateBtn = () => {
		let updateButton = document.querySelector('#update-btn')
    	
    	updateButton.disabled = false;
        updateButton.innerText = 'Update';
	};
    
	return (
        <React.Fragment>
           
            <form className={classes.root} noValidate autoComplete="off">
            	<Typography variant="body1" gutterBottom style={headerSection}>
                	{Lang().SP.basic.companyOverview}
	            </Typography>
            
            	<TextField onKeyUp={onKeyUp_ActivateBtn} style={field} id="companyname" label={Lang().register.companyname} defaultValue={supplierData.companyname} variant="outlined" />
            	<TextField onKeyUp={onKeyUp_ActivateBtn} style={field} id="email" label={Lang().register.email} defaultValue={supplierData.email} variant="outlined" />
            	<br />
            	<TextField onKeyUp={onKeyUp_ActivateBtn} style={field} id="phonenumber" label={Lang().register.phonenumber} defaultValue={supplierData.phonenumber} variant="outlined" />
            	<TextField onKeyUp={onKeyUp_ActivateBtn} style={field} id="mobile" label={Lang().register.mobile} defaultValue={supplierData.mobile} variant="outlined" />
            	<br />
            	<TextField onKeyUp={onKeyUp_ActivateBtn} style={field} id="faxnumber" label={Lang().register.faxnumber} defaultValue={supplierData.faxnumber} variant="outlined" />
            	
            	<div style={{height: '12px'}}></div>
            	<Typography variant="body1" gutterBottom style={headerSection}>
	             {Lang().SP.basic.companyAddress}
	            </Typography>
            	
            	<TextField onKeyUp={onKeyUp_ActivateBtn} style={field} id="address" label={Lang().register.address} defaultValue={supplierData.address} variant="outlined" />
            	<TextField onKeyUp={onKeyUp_ActivateBtn} style={field} id="city" label={Lang().register.city} defaultValue={supplierData.city} variant="outlined" />
            	<br />
            	<FormControl style={field} variant="outlined">	
	            	<InputLabel id="province-select-label">{Lang().register.province}</InputLabel>
	            	<Select id="province" labelId="province-select-label" defaultValue={supplierData.province} label={Lang().register.province}
	            	  onChange={handleProvinceChange()}>
		                <MenuItem value="ab">AB</MenuItem>
		                <MenuItem value="bc">BC</MenuItem>
		                <MenuItem value="mb">MB</MenuItem>
		                <MenuItem value="nb">NB</MenuItem>
		                <MenuItem value="nl">NL</MenuItem>
		                <MenuItem value="ns">NS</MenuItem>
		                <MenuItem value="nt">NT</MenuItem>
		                <MenuItem value="nu">NU</MenuItem>
		                <MenuItem value="on">ON</MenuItem>
		                <MenuItem value="pe">PE</MenuItem>
		                <MenuItem value="qc">QC</MenuItem>
		                <MenuItem value="sk">SK</MenuItem>
		                <MenuItem value="yt">YT</MenuItem>
		            </Select>
	            </FormControl>
	            <TextField onKeyUp={onKeyUp_ActivateBtn} style={field} id="postalcode" label={Lang().register.postalcode} defaultValue={supplierData.postalcode} variant="outlined" />
            	<br />
            	
            	<TextField onKeyUp={onKeyUp_ActivateBtn} id="description" style={{paddingRight: '15px'}} label={Lang().register.description} defaultValue={supplierData.description} variant="outlined"
            		fullWidth
                    multiline
                    rows={5} />
            	<br />
            	
	            <div style={{marginTop: '39px', textAlign: 'center'}}>
	            	<Button id="update-btn" className={classes.button_update} variant="contained" color="primary" onClick={updateCompanyDetails}>
                    	{Lang().register.update}
                    </Button>
                </div>
            </form>    
        
        </React.Fragment>
    );
}