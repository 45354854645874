/** @jsx jsx */
import { jsx } from '@emotion/core'
import HomePageStyles from './styles';
import nacca from '../../assets/NACCA-logo.png';
import itac from '../../assets/ITAC-logo.png';
import cando from '../../assets/CANDO-logo.png';
import ccab from '../../assets/CCAB-logo.png';
import niedb from '../../assets/NIEDB-logo.png';
import pauktuutit from '../../assets/PIWC-logo.png';
import isc from '../../assets/ISC-logo.png';
import jedi from '../../assets/JEDI-logo.png';
import afn from '../../assets/AFN-logo.png';
import neda from '../../assets/NEDA-logo.png';
import pspc from '../../assets/PSPC-logo.png';

import {
  Box,
  Typography
} from '@material-ui/core';
import SearchBar from '../shared/search-bar';
import Lang from '../shared/lang';

const HomePage = (props) => {
	
  document.location.href = '/#/supplier-page';
  
  if (Lang().symbol == 'EN') {
	  var langSwitchLink = '/fr';
	  var langSwitchText = 'FR';
	  var registerLinkLabel = 'HERE';
  } else {
	  var langSwitchLink = '/en';
	  var langSwitchText = 'EN';
	  var registerLinkLabel = 'ICI';
  }
  	
  return (
    <div css={HomePageStyles}>
      <Box classes={{root: 'backgroundImage'}}>
        <Box classes={{root: 'outerComponent'}}>

			<Box>
            <Typography classes={{root: 'title'}}> {Lang().headings.homeMain} </Typography>
          </Box>
          <Box classes={{root: 'subContainer'}}>
            <Typography classes={{root: 'subtitle'}}>
              {Lang().headings.slogan}
            </Typography>
          </Box>
          <Box classes={{root: 'searchComponent'}}>
            <SearchBar type={'home'} searchTerm={''} filter={''}/>
          </Box>
          <div class="homeintro">
            {Lang().headings.about}
            <div>
             {Lang().headings.registerUrlLabel} <a target="_blank" href="https://www.taskforce-covid19.ca/#!/start">{registerLinkLabel}</a>
            </div>
          </div>
<div class="outerLogoComponent">
	
		<img src={jedi}  height="90px"  alt="Logo" style={{objectFit: 'contain'}} />
    	<img src={afn}  height="90px"  alt="Logo" style={{objectFit: 'contain'}} />
    	<img src={ccab}  max-height="100%" max-width="100%"  alt="Logo" style={{objectFit: 'contain'}} />
    	<img src={itac}  max-height="100%" max-width="100%"  alt="Logo" style={{objectFit: 'contain'}} />
    	<img src={cando}   max-height="100%" max-width="100%"  alt="Logo" style={{objectFit: 'contain'}} />
    	<img src={neda}   height="90px"  alt="Logo" style={{objectFit: 'contain'}} />
   
		<img src={isc}   max-height="100%" max-width="100%"  alt="Logo" style={{objectFit: 'contain'}} />
		<img src={pspc}  style="margin: 0 0 0 27px"  width="315px"  alt="Logo" style={{objectFit: 'contain'}} />
   

</div>
        </Box>
      </Box>
    </div>
  );
};

export default HomePage;
