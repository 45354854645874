import { useDebouncedCallback } from "use-debounce";
import { useState, useEffect } from "react";
import { getSearchCount } from "../services/api.service";

const useSearchCount = (term) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [debouncedCallback] = useDebouncedCallback(
    // function
    async (term) => {
      if (term) {
        try {
          setResults(await getSearchCount(term));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    // delay in ms
    200
  );

  useEffect(() => {
    setLoading(true);
    debouncedCallback(term);
  }, [debouncedCallback, term]);

  return [results, loading];
};

export default useSearchCount;
