/**
 * Supplier Profile global use object
 * 
 */
import homeic1 from '../../assets/HomePage_ic1.png';

export const supplierOrg = {
    'HOMEIC1': homeic1,
}

const SPGlobals = {
    items: {}
}

export default SPGlobals;
export const getSupplierLogo = (org) => supplierOrg[org];