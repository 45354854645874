import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { selectorsMetadata } from '../shared/register-data';
import Lang from '../shared/lang';

let BPSocket = "wss://imscbpsock.datataskforce.ca";

export class NullPage extends Component
{
    continue = () => {
        this.props.nextStep();
    };

    doProccess = () => {

        const nextStep = this.continue;

        function socket_onmessage_callback(e) {
            const obj = JSON.parse(atob(e.data));
            const productObj = obj.payload.demand.Product;
            const serviceObj = obj.payload.demand.Service;

            productObj.forEach(function(item, i, arr) {
            	if (Lang().symbol == 'EN') {
            		productArr.push(item.name);
            	} else {
            		productArr.push(item.name_fr);
            	}
            });

            serviceObj.forEach(function(item, i, arr) {
	            if (Lang().symbol == 'EN') {
	            	serviceArr.push(item.name);
	          	} else {
	          		serviceArr.push(item.name_fr);
	          	}
            });

            selectorsMetadata.products_supply = productArr;
            selectorsMetadata.services_supply = serviceArr;
            selectorsMetadata.products_retool = productArr;
            selectorsMetadata.services_retool = serviceArr;

            nextStep();
        }

        const conn = new WebSocket(BPSocket);
        const myJSON = JSON.stringify({call: 'syncToDomain'});

        const productArr = [];
        const serviceArr = [];

        conn.onopen = function(e) {
            console.log("Connection established!");
            document.getElementById("initProcess").style.display = "block";
            document.getElementById("initFailed").style.display = "none";
            conn.send(myJSON); //json encoded
        };

        conn.onmessage = function(e) {
        	socket_onmessage_callback(e);
        };

        conn.onerror = function(event) {
            document.getElementById("initFailed").style.display = "block";
            document.getElementById("initProcess").style.display = "none";
        };


    };

    render() {

        this.doProccess();

        setTimeout(function(){}, 3000);

        const paper = {
            marginTop: '18px',
            marginBottom: '18px',
            padding: '28px',
        };
        const layout = {
            width: '680px',
            marginLeft: 'auto',
            marginRight: 'auto',
        };
        const initProcess = {
            marginBottom: '8px',
            display: 'none',
        };
        const initFailed = {
            marginBottom: '8px',
            display: 'none',
        };

        return (
            <React.Fragment>
            <CssBaseline />
            <main style={layout}>
            <Paper style={paper}>

            <React.Fragment>
            <Typography component="h2" variant="h4" align="center" id="initProcess" style={initProcess}>
                {Lang().register.init}
            </Typography>
            <Typography component="h2" variant="h4" align="center" id="initFailed" style={initFailed}>
                {Lang().register.initFail}
            </Typography>
            </React.Fragment>

            </Paper>
            </main>
            </React.Fragment>
        );
    }
}

export default NullPage;
