/* French language labels
 */

const French = {
    symbol: 'FR',
    menu: {
  	    aboutUs: 'À propos',
  	    covidHelp: 'Support COVID-19',
  	    newsletter: 'Infolettre',
  	    contactUs: 'Nous joindre',
    },
	headings: {
		homeMain: 'La base de données de fournisseurs autochtones pour les produits et services liés à la COVID-19',
		slogan: "Pour mobiliser la chaîne d'approvisionnement autochtone",
		about: "Afin de combattre la pandémie de COVID-19, un groupe d'organisations autochtones ont formé l'Équipe spéciale COVID-19 pour les entreprises autochtones, qui a créé la plus grande base de données d'entreprises autochtones vérifiées étant en mesure de fournir de l'équipement de protection personnel (ÉPI), de même que d'autres produits et services médicaux.",
	    registerUrlLabel: 'Pour enregistrer votre entreprise, cliquez',
	    registerUrl: 'http://taskforce.dev.cbs-inc.ca:2221/fr/#/register-page/'
	},
	buttons: {
		homePageSearch: 'Recherche',
		filterByBusinessName: 'Nom de l\'entreprise',
		filterByOrganization: 'Organisation',
		filterByService: 'Services',
		filterByProducts: 'Produits',
		filterByCovidService: 'Produits COVID-19',
		filterByCovidProduct: 'Services COVID-19'		
	},
	fields: {
		searchSelect: "Nom d'entreprise, par service ou par produit",
		placesSelect: "Province, par ville, ou par code postal"
	},
	labels: {
		filterBy: 'Filtrer par',
		resultsFor: 'Résultats pour'
	},
	SP: {
		login: {
			openLabel: 'Ouvrir',
			preamble: 'Entrez votre clé d\'accès et ouvrez votre profil de fournisseur',
		},
		basic: {
			companyOverview: 'Présentation de l\'entreprise',
			companyAddress: 'Adresse de la société',
		},
		marketing: {
			socialLinks: 'Mettez à jour les liens de vos pages sociales',
			websiteFieldLabel: 'Site principal',
		}
	},
	register: {
    	update: 'Mettre à jour',
		close: 'Fermer',
    	init: 'Initialisation!',
		initFail: 'Initialisation échouée!',
		auth: 'Autorisation!',
		authFail: 'L\'autorisation à échouée!',
		sync: 'Synchronisation!',
		syncFail: 'La synchronisation a échoué!',
		indigenousSupplier: 'Soumission des détails du fournisseur autochtone',
		governmentCanadaHeader: 'Le gouvernement du Canada est actuellement le plus important acheteur public de biens et de services au Canada, achetant pour environ 22 milliards de dollars chaque année.',
		councilAdvancementHeader: "La Stratégie d'approvisionnement auprès des entreprises autochtones (SAEA) participe actuellement à une initiative de diverses organisations autochtones nationales, s’unissant afin de faire face à la pandémie de COVID-19.",
		membersThereforeHeader: "Nos membres sont invités à faire partie d’une base de données exhaustive et interrogeable sur les entreprises autochtones, qui sera utilisée par les ministères fédéraux à la recherche de fournisseurs potentiels dans tous les secteurs d’activité économique.",
		fillingAttachedHeader: "En remplissant le formulaire Google ci-joint, vous serez inscrit en tant que fournisseur. Cela permettra aux acheteurs du gouvernement de communiquer avec vous au sujet de précieuses opportunités commerciales, et ainsi contribuer à la croissance économique de votre entreprise.",
		registrarsName: "Nom du registraire",
		organization: 'Quelle organisation autochtone nationale a communiqué avec vous?',
		cando: "CANDO",
		itac: "ATAC",
		nacca: "NACCA",
		psab: "PSAB",
		other: "Other",
		supplier: "Je consens à ce que les informations de mon entreprise fassent partie de la base de données des fournisseurs",
		yes: "OUI",
		no: "NON",
		requiredQuestion: 'Ceci est une question requise',
		next: 'Suivant',
		back: 'Retour',
		companyInformationHeader: 'Informations sur l’entreprise',
		companyname: 'Nom de l’entreprise',
		address: 'Adresse',
		city: 'Ville',
		province: 'Province',
		postalcode: 'Code postal (exemple de format: Z9Z 9Z9)',
		email: 'Courriel',
		phonenumber: 'Numéro de téléphone (example de format: 123-456-7890)',
		tollfree: 'Numéro sans frais (example de format: 1-800-123-4567)',
		faxnumber: 'Numéro de fax (example de format: 123-456-7890)',
		description: 'Company Description (example formats: consulting, transportation, safety training, etc.)',
		products: 'What products and services does your company offer?',
		website: 'URL du site web de l’entreprise',
		facebook: 'URL de la age Facebook',
		twitter: 'Twitter URL',
		LinkedIn: 'URL LinkedIn',
		other: 'Autre',
		yourAnswer: 'Ta Réponse',
		primaryContactDetailsHeader: 'Coordonnées principales',
		firstname: 'Prénom',
		lastname: 'Nom',
		title: 'Titre (exemple de format: PDG, DPF, président, etc.)',
		phonenumber3: 'Numéro de téléphone (example de format: 123-456-7890)',
		extension: 'Numéro de poste',
		email3: 'Courriel',
		findMoreHeader: 'Find out more – click here.',
		canadaLookingHeader: 'Le Canada est à la recherche de fournisseurs autochtones afin de contribuer au combat contre la maladie à coronavirus COVID-19.',
		responsecovid: "Êtes-vous une entreprise en mesure de fournir des produits et services pouvant soutenir les efforts du Canada contre la COVID-19?",
		canadasSuppliers: "Appel à tous les fournisseurs",
		products_supply: 'Lesquels de ces produits êtes-vous en mesure de fournir?',
		products_supply_other: 'Other Products',
		services_supply: 'Votre entreprise peut-elle fournir les services suivants ?',
		services_supply_other: 'Other Services',
		comments_supply: 'Commentaires :',
		canadaSuppliersHeader: "Appel à tous les fournisseurs - Capacité de réoutillage",
		business_retool: "Votre entreprise est-elle en mesure de se réoutiller afin de fournir des produits et services pouvant soutenir les efforts du Canada contre la COVID-19?",
		retoolingHeader: 'Réoutillage',
		products_retool: 'Lesquels des PRODUITS suivants votre entreprise est-elle en mesure de produire si elle se réoutille ?',
		products_retool_other: 'Other Products',
		services_retool: 'Lesquels des SERVICES suivants votre compagnie est-elle en mesure de fournir si elle se réoutille ?',
		services_retool_other: 'Other Services',
		comments_retool: 'Commentaires:',
		сompanyNonCovidHeader: 'Produits et services non-covid de l’entreprise',
		evenBusiness: "Même si votre entreprise n’est pas directement liée à COVID, votre liste et vos services apparaîtront là où 1000 acheteurs gouvernementaux sont constamment à la recherche de fournisseurs. Par conséquent, ils peuvent retourner à vous s’ils ont besoin d’autres produits et services.",
		noncovid_products: "Quels produits non-covid votre entreprise offre-t-elle?",
		noncovid_services: "Quels services non-covid votre entreprise offre-t-elle?",
		noncvd_supply_comments: 'Commentaires :',
		сandosFreeHeader: "Abonnement gratuit d’un an de Cando",
		registeringDatabaseHeader: "Avec cette initiative, un abonnement gratuit d’un an à Cando (Conseil pour l’avancement des agents de développement autochtones) est offert.",
		candoMembership: "L’adhésion à Cando est un investissement dans le développement économique des Autochtones, car elle sert des centaines d’ADE et de chefs d’entreprise partout au Canada. Pour être précis, cette adhésion comporte des possibilités telles que l’accès à des événements de réseautage, des webinaires, des prix spéciaux sur les conférences, des communiqués de presse exclusifs et la chance de gagner de nombreux prix lorsque vous vous abonnez à leurs événements. De plus, Cando a sélectionné une variété d’outils et de liens provenant d’une vaste gamme de littératures sur le développement économique autochtone conçues pour permettre aux membres de suivre les tendances les plus récentes et les plus pertinentes en matière de développement économique autochtone.",
		memberofcando: 'Voulez-vous être membre de Cando?',
		already: "Je suis déjà membre",
		submit: "Nous faire parvenir",
	}
};

export default French;