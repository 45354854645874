import css from '@emotion/css';
import { theme } from '../../../constants/theme';

const ToolBarStyles = css `

@media (max-width:800px){

.container {
  height: auto !important;
  min-height:150px !important;
      width: 100% !important;
      flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
padding: 10px 0px !important;
margin:0px !important;
justify-content: center !important;
  background-color:  ${theme.colors.white};
box-shadow: none;
}

.css-yf5vpw .container{
background-color:#ffffff !important;
}
.subContainer {
  display: inline-flex;
  padding-left: 0px !important;
  padding-top: 0px !important;

}
.Typography{
  font-size: 24px;
  font-weight: 900;
  color:#999999 !important;
  text-transform:uppercase  !important;
  line-height:26px   !important;
  padding: 0px  16px !important;
   display:block !important;
   position:relative !important;
   float:left !important;
   height:26px !important;

}
.Typography2{
  font-size: 16px  !important;
  font-weight: normal !important;
  margin-left: 22px !important;
  padding: 0px !important;
  color:  #666666 !important;
   line-height:26px   !important;
   display:block !important;
   position:relative !important;
   float:left !important;
   height:26px !important;

}

.Typography1{

  font-size: 16px  !important;
  font-weight: normal !important;
  margin-left: 22px !important;
  padding-top: 0px !important;
  color:  #666666 !important;
  padding: 0px !important;
  line-height:26px   !important;
   display:block !important;
   position:relative !important;
   float:left !important;
   height:26px !important;

}
.reulstsCOotaner{

}
}
	
	
	
.container {
  width: 600px;
  height: 190px;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
    box-shadow: inset 0 -1px 0 0 #e8e9ea;
    position: sticky;

}
.subContainer {
  display: inline-flex;
  padding-left: 0px;
  padding-top: 32px;
  height:auto;
  min-height:30px;
  
}
.Typography{
  font-size: 20px;
  font-weight: 900;
  color:#999999 !important;
  text-transform:uppercase  !important;
   line-height:30px;
}
.Typography2{
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
 line-height:30px;
  font-weight: normal;
  padding-left:32px;
  letter-spacing: normal;
  padding-right: 7px;
  color:  ${theme.colors.dark};
}
.Typography1{

  padding-left: 32px;
 font-size: 20px;
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height:30px;
 letter-spacing: normal;
 color: ${theme.colors.lightGrey};
}

.reulstsCOotaner{

}
`
export default ToolBarStyles;