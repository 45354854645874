import nacca from '../assets/nacca-verified.png';
import itac from '../assets/itac-verified.png';
import ccab from '../assets/ccab-verified.png';
import niedb from '../assets/NIEDB.png';
import pauktuutit from '../assets/Pauktuutit.png';
import isc from '../assets/ISC.png';
import cando from '../assets/cando-verified.png';
import Lang from '../components/shared/lang';

export const requestHeaders = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'Access-Control-Allow-Origin': '*',
}

// export const baseURL = 'http://localhost:8080'; // local Go URL
export const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '';

export const mapOrg = {
  'CANDO': cando,
  'CCAB': ccab,
  'ITAC': itac,
  'NACCA': nacca,
  'NIEDB': niedb,
  'ISC': isc,
  'Pauktuutit': pauktuutit,
  'PSAB': cando
}

export const filterTypes = [
  {
    key: 1,
    label: Lang().buttons.filterByBusinessName,
    state: false,
    value: 'businessName'
  },
  {
    key: 2,
    label: Lang().buttons.filterByOrganization,
    state: false,
    value: 'organization'
  },
  {
    key: 3,
    label: Lang().buttons.filterByService,
    state: false,
    value: 'service'
  },
  {
    key: 4,
    label: Lang().buttons.filterByProducts,
    state: false,
    value: 'product'
  },
  {
    key: 5,
    label: Lang().buttons.filterByCovidService,
    state: false,
    value: 'covid19service'
  },
  {
    key: 6,
    label: Lang().buttons.filterByCovidProduct,
    state: false,
    value: 'covid19product'
  }
]

export const organizationDetails = [{id:"PSAB",organization:"Council for the Advancement of Native Development Officers", first_name:"David",last_name:"Acco", telephone:"514-434-9433", ext:"N/A", email:"dacco@acosysconsulting.com"},
  {id:"CCAB",organization:"Canadian Council for Aboriginal Business", first_name:"Philip",last_name:"Ducharme", telephone:"416-961-8663", ext:"230", email:"pducharme@ccab.com"},
  {id:"ITK",organization:"Inuit Tapiriit Kanatami", first_name:"David",last_name:"Acco", telephone:"514-434-9433", ext:"N/A", email:"dacco@acosysconsulting.com"},
  {id:"ITAC",organization:"Indigenous Tourism Association of Canada", first_name:"David",last_name:"Acco", telephone:"514-434-9433", ext:"N/A", email:"dacco@acosysconsulting.com"},
  {id:"NACCA",organization:"National Aboriginal Capital Corporations Association", first_name:"Mark",last_name:"Dokis", telephone:"613-688-0894 ", ext:"503", email:"mdokis@nacca.ca"},
  {id:"Pauktuutit Inuit Women of Canada",organization:"Pauktuutit Inuit Women of Canada", first_name:"Christine",last_name:"Lund", telephone:"613-238-3977", ext:"253", email:"clund@pauktuutit.ca"},
  {id:null,organization:"N/A", first_name:"",last_name:"", telephone:"N/A", ext:"N/A", email:"N/A"}]

export const getOrgLogo = (org) => {
	 if (org == null) {
             return '';
	 }
	
	 if (typeof org !== 'object'
	     || false == org.hasOwnProperty('organization')
	     || typeof org.organization == 'undefined'
	     || org.organization == null
         ) {
		 return '';
	 }
	 
	 return mapOrg[org.organization];
};
