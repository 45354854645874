import React, { Component } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import FormGroup from "@material-ui/core/FormGroup";
import { registerData } from '../shared/register-data';
import Lang from '../shared/lang';
let BPSocket = "wss://imscbpsock.datataskforce.ca";

export class SevenPage extends Component
{
    createGuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }
    continue = e => {
        const response = new Object();
        const guid = this.createGuid();
        response.call = 'NewBusinessData';
        this.props.values.id = guid;
        this.props.values.products_supply = registerData.products_supply;
        this.props.values.products_supply_other = registerData.products_supply_other;
        this.props.values.services_supply = registerData.services_supply;
        this.props.values.services_supply_other = registerData.services_supply_other;
        this.props.values.comments_supply = registerData.comments_supply;
        this.props.values.products_retool = registerData.products_retool;
        this.props.values.products_retool_other = registerData.products_retool_other;
        this.props.values.services_retool = registerData.services_retool;
        this.props.values.services_retool_other = registerData.services_retool_other;
        this.props.values.comments_retool = registerData.comments_retool;
        response.payload = this.props.values;

        console.log('response ', response);

        const myJSON = JSON.stringify(response);
        let socketState = this.props.socketState;
        const next = this.props.nextStep;

        let conn = new WebSocket(BPSocket);
        conn.onopen = function(e) {
            console.log("Connection established!");
            console.log('myJSON ', myJSON)
            conn.send(myJSON);
            e.preventDefault();
            next();
        };

        conn.onerror = function(event) {
            console.error("WebSocket error observed:", event);
            e.preventDefault();
            document.getElementById("error").style.display = "block";
        };

        conn.onmessage = function(e) {
            console.log('e ', e);
        };
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const errorVisible = {
            display: 'none',
            color: 'red',
            marginTop: '5px',
        };
        const paper = {
            marginTop: '18px',
            marginBottom: '18px',
            padding: '28px',
        };
        const layout = {
            width: '680px',
            marginLeft: 'auto',
            marginRight: 'auto',
        };
        const headerMargins = {
            marginBottom: '12px',
        };
        const requireColor = {
            color: 'red',
        }
        const requireText = {
            color: 'red',
            display: 'none',
        }
        const headerName = {
            paddingBottom: '8px',
            marginBottom: '26px',
            marginTop: '12px',
            paddingTop: '8px',
            paddingLeft: '8px',
            backgroundColor: '#868B8E',
            color: '#FFFFFF',
        };
        const headerBlocks = {
            marginBottom: '8px',
        };
        const buttons = {
            display: 'flex',
            justifyContent: 'flex-end',
        };
        const titleBlocks = {
            marginBottom: '8px',
        };
        const button = {
            marginTop: '8px',
            marginLeft: '8px',
        };
        const beforeLink = {
            fontSize: 'smaller',
            margin: '8px',
        }
        const { values, handleChange } = this.props;

        return (
            <React.Fragment>
            <CssBaseline />
            <main style={layout}>
            <Paper style={paper}>

                <React.Fragment>
                <Typography component="h2" variant="h4" align="center" style={titleBlocks}>
                    {Lang().register.indigenousSupplier}
                </Typography>

                <Typography variant="body1" gutterBottom style={headerName}>
                    {Lang().register.сandosFreeHeader}
                </Typography>

                <Typography variant="body1" gutterBottom style={beforeLink}>
                    {Lang().register.registeringDatabaseHeader}
                </Typography>

                <Typography variant="body1" gutterBottom style={beforeLink}>
                    {Lang().register.candoMembership}
                </Typography>

                <Typography variant="body1" component="span" gutterBottom className={headerBlocks}>
                    {Lang().register.memberofcando}
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset">
                        <RadioGroup aria-label="memberofcando" name="memberofcando" value={values.memberofcando} onChange={handleChange('memberofcando')}>
                        <FormControlLabel value="yes" control={<Radio />} label={Lang().register.yes} />
                        <FormControlLabel value="no" control={<Radio />} label={Lang().register.no} />
                        <FormControlLabel value="already" control={<Radio />} label={Lang().register.already} />
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <React.Fragment>
                <div style={buttons}>
                    <Button onClick={this.back} style={button}>
                        {Lang().register.back}
                    </Button>
                    <Button
                    variant="contained"
                    color="primary"
                    style={button}
                    onClick={this.continue}
                    >
                        {Lang().register.submit}
                    </Button>
                    </div>
                    <div id="error" style={errorVisible}>The server error occured. Please try again in few minutes. Contact our support if necessary.</div>
                </React.Fragment>
                </React.Fragment>

            </Paper>
            </main>
            </React.Fragment>
        );
    }
}

export default SevenPage;
