import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SupplyProfile from './SupplyProfile';
import Retooling from './Retooling';
import BasicPageCompanyDetails from './BasicPageCompanyDetails';
import { supplierData, stepMessages, lastEdit } from '../shared/register-data';
import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';
import SPGlobals from './globals';
import CssBaseline from '@material-ui/core/CssBaseline';
import splashLogo from '../../assets/Splash_Logo.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
    </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
    	marginTop: '18px',
        marginBottom: '18px',
        padding: '28px',
    },
    layout: {
    	width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'block',
        marginTop: '27px',
    },
    tab_color: {
        backgroundColor: 'white',
    },
    button_close: {
        marginTop: '8px',
        marginLeft: '8px',
        marginBottom: '12px',
        float: 'right'
    },

    button_update: {
        marginTop: '8px',
        marginLeft: '8px',
        marginBottom: '12px',
    },
    titleBlocksHeader: {
        marginBottom: '15px',
    },
    timeStamp: {
        float: 'right',
        fontStyle: 'italic',
        color: 'rgba(0, 0, 0, 0.54)',
    },
}));

export default function ProfileTabs() {  

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const rule1 = () => {
        const textValue = document.getElementById('companyname').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule2 = () => {
        const textValue = document.getElementById('address').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule3 = () => {
        const textValue = document.getElementById('city').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule4 = () => {
        const textValue = document.getElementsByName("province")[0].value;
        if(textValue === '') {
            return false;
        } else {
            return true;
        }
    }
    const rule5 = () => {
        const textValue = document.getElementById('postalcode').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule6 = () => {
        const textValue = document.getElementById('email').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule7 = () => {
        const textValue = document.getElementById('phonenumber').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule8 = () => {
        const textValue = document.getElementById('firstname').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule9 = () => {
        const textValue = document.getElementById('lastname').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule10 = () => {
        const textValue = document.getElementById('title').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule11 = () => {
        const textValue = document.getElementById('phonenumber3').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    const rule12 = () => {
        const textValue = document.getElementById('email3').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }

    const logOut = () => {
        stepMessages.sync = 'init';
        SPGlobals
            .items
                .SupplierPage.showScreen('login');
    };

    const getCurrentTime = () => {
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        return date + ' ' + time;
    };

    const updateDetails = () => {
        let stepState = false;
        let focusState = false;

        if(!rule1()) {
            document.getElementById("companyname").focus();
            document.getElementById("companyname-label").style.color = "#f44336";
            focusState = true;
            stepState = true;
        } else {
            document.getElementById("companyname-label").style.color = "#0099CE";
        }
        if(!rule2()) {
            if(!focusState) {
                document.getElementById("address").focus();
                focusState = true;
                stepState = true;
            }
            document.getElementById("address-label").style.color = "#f44336";
        } else {
            document.getElementById("address-label").style.color = "#0099CE";
        }
        if(!rule3()) {
            if(!focusState) {
                document.getElementById("city").focus();
                focusState = true;
                stepState = true;
            }
            document.getElementById("city-label").style.color = "#f44336";
        } else {
            document.getElementById("city-label").style.color = "#0099CE";
        }
        if(!rule4()) {
            console.log('prov-rule')
            if(!focusState) {
                document.getElementById("province").focus();
                focusState = true;
                stepState = true;
            }
            document.getElementById("province").style.color = "antiquewhite";
        } else {
            document.getElementById("province").style.background = "white";
        }
        if(!rule5()) {
            if(!focusState) {
                document.getElementById("postalcode").focus();
                focusState = true;
                stepState = true;
            }
            document.getElementById("postalcode-label").style.color = "#f44336";
        } else {
            document.getElementById("postalcode-label").style.color = "#0099CE";
        }
        if(!rule6()) {
            if(!focusState) {
                document.getElementById("email").focus();
                focusState = true;
                stepState = true;
            }
            document.getElementById("email-label").style.color = "#f44336";
        } else {
            document.getElementById("email-label").style.color = "#0099CE";
        }
        if(!rule7()) {
            if(!focusState) {
                document.getElementById("phonenumber").focus();
                focusState = true;
                stepState = true;
            }
            document.getElementById("phonenumber-label").style.color = "#f44336";
        } else {
            document.getElementById("phonenumber-label").style.color = "#0099CE";
        }

        if(!stepState) {
            const dateTime = getCurrentTime();
            var timeEl = document.getElementById("timeEditDetails");

            lastEdit[3] = 'last edit was ' + dateTime;
            timeEl.innerHTML = 'last edit was ' + dateTime;

            console.log('SUP ', supplierData);
            const payload = {
                'id': supplierData.id,
                'companyname': supplierData.companyname,
                'address': supplierData.address,
                'city': supplierData.city,
                'description': supplierData.description,
                'province': supplierData.province.toUpperCase(),
                'postalcode': supplierData.postalcode,
                'phonenumber': supplierData.phonenumber,
                'email': supplierData.email,
                'tollfree': supplierData.tollfree,
                'faxnumber': supplierData.faxnumber,
                'services': supplierData.service,
            };
            BpWebSocket.send({
                call: 'supplierProfileUpdateRequest',
                payload: payload
            });
        }
    };

    const updateSupply = () => {
        const dateTime = getCurrentTime();
        var timeEl = document.getElementById("timeEditSupply");

        lastEdit[4] = 'last edit was ' + dateTime;
        timeEl.innerHTML = 'last edit was ' + dateTime;

        console.log('SUP ', supplierData);
        const payload = {
            'responsecovid': '',
            'business_retool': '',
            'noncovid_products': '',
            'noncovid_services': '',
            'noncvd_supply_comments': '',
            'memberofcando': ''
        };
        BpWebSocket.send({
            call: 'supplyUnitUpdateRequest',
            payload: payload
        });
    };

    const updateRetooling = () => {
        const dateTime = getCurrentTime();
        var timeEl = document.getElementById("timeEditRetooling");

        lastEdit[5] = 'last edit was ' + dateTime;
        timeEl.innerHTML = 'last edit was ' + dateTime;

        console.log('SUP ', supplierData);
        const payload = {
            'responsecovid': '',
            'business_retool': '',
            'noncovid_products': '',
            'noncovid_services': '',
            'noncvd_supply_comments': '',
            'memberofcando': ''
        };
        BpWebSocket.send({
            call: 'retoolUnitUpdateRequest',
            payload: payload
        });
    };

    const update = () => {
        const tab1 = document.getElementById("full-width-tab-0").getAttribute("aria-selected");
        const tab2 = document.getElementById("full-width-tab-1").getAttribute("aria-selected");
        const tab3 = document.getElementById("full-width-tab-2").getAttribute("aria-selected");

        console.log('tab1 ', tab1);
        console.log('tab2 ', tab2);
        console.log('tab3 ', tab3);

        if(tab1) {
            updateDetails();
        }

        // if(tab2) {
        //     updateSupply();
        // }
        //
        // if(tab3) {
        //     updateRetooling();
        // }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    
    const CSS = {
		imgSplashLogo: {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		}
    };

    if(stepMessages.sync === 'init') {
        /*
    	BpWebSocket.send({
            call: 'syncSupplierProfile',
            payload: {
                id: supplierData.id
            }
        });
        */
        return (
            <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
	            <Paper className={classes.paper}>
	
	            <Typography component="h2" variant="h4" align="center" className={classes.titleBlocksHeader}>
	                Supplier profile management
	            </Typography>
	
	            <React.Fragment>
	                <Typography component="h2" variant="h4" align="center" id="syncProcess">
	                {Lang().register.sync}
	                </Typography>
	            </React.Fragment>
	
	            </Paper>
            </main>
            </React.Fragment>
        );
    }

    if(stepMessages.sync === 'synced') {
        return (
            <React.Fragment>
            <CssBaseline />
            <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
	           <img style={CSS.imgSplashLogo} src={splashLogo} />
	        </div>
            <main className={classes.layout}>
	            <Paper className={classes.paper}>
	
	            <Typography component="h2" variant="h4" align="center" className={classes.titleBlocksHeader}>
	                Supplier profile management
	            </Typography>
	            
	            <div id="tabs" className={classes.root}>
	                <AppBar position="static" color="default">
	                    <Tabs
	                        className={classes.tab_color}
	                        value={value}
	                        onChange={handleChange}
	                        indicatorColor="primary"
	                        textColor="primary"
	                        variant="fullWidth"
	                        aria-label="full width tabs example"
	                    >
	                    <Tab label="basic details" {...a11yProps(0)} />
	                    <Tab label="Supply profile" {...a11yProps(1)} />
	                    
	                    </Tabs>
	                </AppBar>
	                <SwipeableViews
	                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
	                    index={value}
	                    onChangeIndex={handleChangeIndex}
	                    >
	                    <TabPanel value={value} index={0} dir={theme.direction}>
	                        <div className={classes.timeStamp}>
	                            <Typography id="timeEditDetails" component="span">
	                            {lastEdit[3]}
	                            </Typography>
	                        </div>
	                        <BasicPageCompanyDetails />
	                    </TabPanel>
	                    <TabPanel value={value} index={1} dir={theme.direction}>
	                        <div className={classes.timeStamp}>
	                            <Typography id="timeEditSupply" component="span">
	                            {lastEdit[4]}
	                            </Typography>
	                        </div>
	                        <SupplyProfile />
	                    </TabPanel>
	                    <TabPanel value={value} index={2} dir={theme.direction}>
	                        <div className={classes.timeStamp}>
	                            <Typography id="timeEditRetooling" component="span">
	                            {lastEdit[5]}
	                            </Typography>
	                        </div>
	                        <Retooling />
	                    </TabPanel>
	                </SwipeableViews>
	            </div>
	
	            </Paper>
            </main>
            </React.Fragment>
        );
    }
}
