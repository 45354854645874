/** @jsx jsx */
import { jsx } from "@emotion/core";
import { SearchSelectResultsContainer, NoDataContainer } from "./styles";
import {
  List,
  ListItem,
  Paper,
  ListItemText,
  SvgIcon,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import * as R from "ramda";
import CloudIcon from "@material-ui/icons/CloudOffRounded";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchResultRow from "./search-result-row";

const SearchSelectResults = ({
  term,
  results,
  loading,
  activeItem,
  setActiveItem,
  setSearchFilter,
  searchCountKeys,
}) => {
  const resultsLength = R.when(
    R.identity,
    R.pipe(R.filter(R.identity), R.keys, R.length)
  )(results);

  const moveDown = () => {
    if (activeItem > 0) setActiveItem(activeItem - 1);
  };

  const moveUp = () => {
    if (activeItem < resultsLength - 1) {
      console.log("object");
      setActiveItem(activeItem + 1);
    }
  };

  return (
    <SearchSelectResultsContainer>
      <Paper elevation={0}>
        {loading ? (
          <List dense>
            {Array(3)
              .fill({})
              .map((key, idx) => {
                return (
                  <ListItem key={idx}>
                    <ListItemAvatar>
                      <Skeleton variant="circle" width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Skeleton width={140} />}
                      secondary={<Skeleton width={60} />}
                    />
                  </ListItem>
                );
              })}
          </List>
        ) : !resultsLength ? (
          <NoDataContainer>
            <CloudIcon color="disabled" />
            <Typography component="span" color="textSecondary">
              No results
            </Typography>
          </NoDataContainer>
        ) : (
          <List dense>
            {R.keys(searchCountKeys)
              .filter((key) => results && results[key])
              .map((key, idx) => (
                <SearchResultRow
                  key={key}
                  row={searchCountKeys[key]}
                  result={results && results[key]}
                  active={idx === activeItem}
                  moveDown={moveDown}
                  moveUp={moveUp}
                  term={term}
                  setSearchFilter={setSearchFilter}
                />
              ))}
          </List>
        )}
      </Paper>
    </SearchSelectResultsContainer>
  );
};

export default SearchSelectResults;
