import css from '@emotion/css';
import { theme } from '../../../constants/theme';

const PaginationStyles = css `
  .container {
    height: 72px;
    width: 600px;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: ${theme.colors.white};
  }
  .page {
    margin: auto;
  }
  .MuiPaginationItem-page.Mui-selected {
      background-color:  ${theme.colors.blue};
      color: ${theme.colors.white};
  }
`

export default PaginationStyles;
