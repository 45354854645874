import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { supplierData, selectorsMetadata, stepMessages } from '../shared/register-data';
import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: '8px',
        marginLeft: '8px',
    },
    paper: {
        marginTop: '18px',
        marginBottom: '18px',
        padding: '28px',
    },
    layout: {
        width: '680px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headerName: {
        paddingBottom: '8px',
        marginBottom: '26px',
        marginTop: '12px',
        paddingTop: '8px',
        paddingLeft: '8px',
        backgroundColor: '#868B8E',
        color: '#FFFFFF',
    },
    headerMargins: {
        marginBottom: '12px',
    },
    headerBlocks: {
        marginBottom: '22px',
    },
    titleBlocks: {
        marginBottom: '8px',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function Retooling() {

    const products_retool = selectorsMetadata.products_retool;
    const services_retool = selectorsMetadata.services_retool;

    const classes = useStyles();
    const theme = useTheme();

    const [products, setProducts] = React.useState(supplierData.products_retool);
    const [services, setServices] = React.useState(supplierData.services_retool);

    const handleChange = (event) => {
        const name = event.target.name;
        supplierData[name] = event.target.value;
    };

    const handleChangeProducts = (event) => {
        setProducts(event.target.value);
        supplierData.products_retool = event.target.value;
    };
    const handleChangeServices = (event) => {
        setServices(event.target.value);
        supplierData.services_retool = event.target.value;
    };

    if(stepMessages.syncretool === 'init') {
        BpWebSocket.send({
            call: 'syncRetoolToProfile',
            payload: {
                supplierId: supplierData.id
            }
        });
        return (
            <React.Fragment>
            <Typography component="h2" variant="h4" align="center" id="syncProcess">
                {Lang().register.sync}
            </Typography>
            </React.Fragment>
        );
    }

    if(stepMessages.syncretool === 'synced') {

        const products = supplierData.products_retool;
        const services = supplierData.services_retool;

        return (
            <React.Fragment>

            <Typography variant="body1" component="span" gutterBottom>
            {Lang().register.products_retool}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                <Select
                    labelId="products_retool"
                    id="products_retool"
                    multiple
                    value={products}
					onChange={handleChangeProducts}
                    input={<Input id="products_retool_chip" />}
                    renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                ))}
                </div>
                )}
                    MenuProps={MenuProps}
                >
                {products_retool.map((name) => (
                    <MenuItem key={name} value={name} style={getStyles(name, products, theme)}>
                    {name}
                    </MenuItem>
                ))}
                </Select>
                </FormControl>
                </Grid>
            </Grid>

            <Typography variant="body1" component="span" gutterBottom>
            {Lang().register.services_retool}
            </Typography>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
            <Select
            labelId="services_retool"
            id="services_retool"
            multiple
            value={services}
			onChange={handleChangeServices}
            input={<Input id="services_retool_chip" />}
            renderValue={(selected) => (
            <div className={classes.chips}>
                {selected.map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                ))}
                </div>
            )}
            MenuProps={MenuProps}
            >
            {services_retool.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, services, theme)}>
                {name}
                </MenuItem>
            ))}
            </Select>
            </FormControl>
            </Grid>
            </Grid>

            <Typography variant="body1" component="span" gutterBottom>
            {Lang().register.comments_retool}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <TextField
                id="comments_retool"
                name="comments_retool"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange}
                defaultValue={supplierData.comments_retool}
                />
                </Grid>
            </Grid>

            </React.Fragment>
        );
    }
}