/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Link,
  Grid,
  Dialog,
  ButtonBase
} from '@material-ui/core';
import LoadingScreen from '../shared/loading-screen';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import DetailPageStyles from './styles';
import {organizationDetails, getOrgLogo}  from '../../constants/config';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {getSupplierDetailsByID} from '../../services/supplier.service';

const DetailPage = (props) => {
  const {id,  setId} = props

  const handleClose = () => {
    setSupplierData([])
    setContactInfo([])
    setId(undefined)
    setInfo([]);
  }
  const [supplierData, setSupplierData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ contactInfo, setContactInfo ] = useState([]);
  const [org,setOrg] = useState(undefined);
  const [info, setInfo] = useState({id:"",organization:"", first_name:"",last_name:"", telephone:"", ext:"", email:""})
  
  const retrieveSupplierByID = async (supplierID) => {
    setLoading(true);
    try {
      const inputData = await getSupplierDetailsByID(supplierID);
      if (inputData) {
    	console.info(inputData);
    	setSupplierData(inputData[0]);
        setContactInfo(inputData);

        //console.info(organizationDetails.filter(detail => detail.id == inputData[0].organization)[0]);
        setOrg(inputData[0].organization);
        /*setInfo({
        	id: "PSAB",
        	organization: "Council for the Advancement of Native Development Officers",
        	first_name: "David",
        	last_name: "Acco",
        	telephone: "514-434-9433", 
        	ext:"N/A", 
        	email:"dacco@acosysconsulting.com"
        });*/
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(id!==undefined){
      const fetchData = async (id) => {
        console.log(id);
        await retrieveSupplierByID(id);
      };
      fetchData(id);
    }
    
    
  }, [id]);
  
  console.info(supplierData);
  console.info(contactInfo);
  console.log('===================');
  
  //supplierData.facebook = supplierData.facebook.length > 9 ? supplierData.facebook : '';
  //supplierData.linkedIn = supplierData.linkedIn.length > 9 ? supplierData.linkedIn : '';
  //supplierData.pinterest = supplierData.pinterest.length > 9 ? supplierData.pinterest : '';
  //supplierData.twitter = supplierData.twitter.length > 9 ? supplierData.twitter : '';
  //supplierData.instagram = supplierData.instagram.length > 9 ? supplierData.instagram : '';
  //supplierData.rss_feeds = supplierData.rss_feeds.length > 9 ? supplierData.rss_feeds : '';
  //supplierData.Youtube = supplierData.Youtube.length > 9 ? supplierData.Youtube : '';
  //supplierData.flickr = supplierData.flickr.length > 9 ? supplierData.flickr : '';
  
  return (
    <Dialog maxWidth={"lg"} fullWidth={true} onClose={handleClose} open={(id !== undefined)}>
       <IconButton aria-label="close" style={{position:'absolute', right: 1, top: 1, color: 'lightgrey'}} onClick={()=>handleClose()}>
          <CloseIcon />
        </IconButton>
      <div css={DetailPageStyles}>
      {loading && <LoadingScreen/>}
      { supplierData &&
          <Box classes={{root:'mainContainer'}}>

            <Box classes={{root: 'detailContainer'}}>

              <Box classes={{root: 'headerComponent'}}>
              { supplierData.name && <Typography classes={{root: 'header'}}>{supplierData.name}</Typography>}
              </Box>
            </Box>
            <Box classes={{root: 'socialContent'}}>
               <Box classes={{root:'businessInfo'}}>   
            	  <Box classes={{root:'addressContainer'}}>
	                <Typography classes={{root: 'address'}}>
	                  {supplierData.address1}
	                </Typography>
	                <Typography classes={{root: 'address'}}>
	                    {supplierData.city} {supplierData.province} {supplierData.postal_code}
	                </Typography>
	              </Box>
	              <Box onClick={()=>{setOrg(supplierData.org);console.log(supplierData.org)}}>
	                <img src={getOrgLogo(supplierData)} height="50" width="64" alt="Logo" style={{objectFit: 'cover'}}  />
	              </Box>
                </Box>
                
                <Box style={{width: '100%'}}>
                <Grid container spacing={2}>
                {contactInfo.map(item =>(
                  <Grid container item xs={6} spacing={2} key={item.id}>
                  <Box classes={{root: 'contactData'}}>
                    { item.last_name && <Typography classes={{root: 'contactName'}}>{item.first_name} {item.last_name}</Typography>}
                    { item.title && <Typography classes={{root: 'contactSubInfo'}}>{item.title}</Typography>}
                    { item.cont_phone &&  <Box classes={{root: 'websiteComponent'}}>
                      <PhoneIcon/>
                      <Typography classes = {{root: 'returnList'}}>{item.cont_phone}</Typography>
                    </Box>
                	}
                    { item.mobile && <Box classes={{root: 'websiteComponent'}}>
                      <PhoneAndroidIcon/>
                      <Typography classes = {{root: 'returnList'}}>{item.mobile}</Typography>
                    </Box>
                    }
                    { item.email && <Box classes={{root: 'websiteComponent'}}>
                      <EmailIcon/>
                      <Typography classes = {{root: 'returnList'}}>{item.cont_email}</Typography>

                    </Box>
                      }
                    </Box>
                  </Grid>
                  ))}
                  </Grid>
                  </Box>
                
            </Box>
            <Box classes={{root: 'socialContent'}}>
              <Box classes={{root:'businessInfo'}}>
                <Typography classes={{root: 'header1'}}>
                  Business Information
                </Typography>
                { supplierData.website && <Box classes={{root: 'websiteComponent'}}>
                  <LanguageIcon/>
                  <Link classes = {{root: 'LinkData'}} href={supplierData.website} target="_blank" rel="noreferrer">{supplierData.website}</Link>
                </Box>}
                { supplierData.phoneNumber && <Box classes={{root: 'websiteComponent'}}>
                  <PhoneIcon/>
                  <Typography classes = {{root: 'returnList'}}>{supplierData.phoneNumber}</Typography>
                </Box>}
                { supplierData.email && <Box classes={{root: 'websiteComponent'}}>
                  <EmailIcon/>
                  <Link classes = {{root: 'returnList'}} href={'mailto:' + supplierData.email}>{supplierData.email}</Link>
                </Box>}
              </Box>
              <Box classes={{root:'businessInfo'}}>
                <Typography  classes={{root: 'header1'}}>
                  Social Media
                </Typography>
                <Box classes={{root: 'websiteComponent1'}}>
                  <Grid   container spacing={2}>
                    <Grid container item  xs={6} spacing={2}>
                    {supplierData.facebook &&
                    <Grid container item xs>
                    <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.facebook} target="blank">Facebook</Link>
                    </Grid>}
                    {supplierData.linkedIn &&
                    <Grid container item xs>
                      <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.linkedIn} target="blank">LinkedIn</Link>
                    </Grid>}
                    {supplierData.pinterest &&
                    <Grid container item xs>
                      <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.pinterest}  target="blank">Pinterest</Link>
                    </Grid>}
                    {supplierData.twitter &&
                    <Grid container item xs>
                      <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.twitter} target="blank">Twitter</Link>
                    </Grid>}
                    {supplierData.instagram &&
                    <Grid container item xs>
                      <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.instagram}  target="blank">Instagram</Link>
                    </Grid>}
                    {supplierData.rss_feeds &&
                    <Grid container item xs>
                      <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.rss_feeds}  target="blank">RSS Feed</Link>
                    </Grid>}
                    {supplierData.Youtube &&
                    <Grid container item xs>
                      <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.youtube}  target="blank">Youtube</Link>
                    </Grid>}
                    {supplierData.flickr &&
                    <Grid container item xs>
                    <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.flickr}  target="blank">Flickr</Link>
                    </Grid>}
                  </Grid>
                  </Grid>

                </Box>
              </Box>
            </Box>
            <Box classes={{root:'data'}}>
              <Typography classes={{root: 'header1'}}>
                About this business
              </Typography>
              <Typography>
                {supplierData.description}
              </Typography>
              <Box classes={{root: 'productInfo'}}>
                { supplierData.afi && <Box classes={{root: 'detailComponent'}}>
                  <Typography classes={{root: 'subheader'}}>
                    AFI:
                  </Typography>
                  <Typography classes={{root: 'dataContent'}}>
                    {supplierData.afi}
                  </Typography>
                  </Box>}
              </Box>
              <Box classes={{root: 'productInfo'}}>
                { supplierData.service && <Box classes={{root: 'detailComponent'}}>
                  <Typography classes={{root: 'subheader'}}>Services: </Typography>
                  <Typography classes={{root: 'dataContent'}}>{supplierData.service} </Typography>
                </Box>}
                {supplierData.product && <Box  classes={{root: 'detailComponent'}}>
                <Typography classes={{root: 'subheader'}}>Products:</Typography>
                <Typography classes={{root: 'dataContent'}}>{supplierData.product} </Typography>
              </Box>}
              </Box>
              <Box classes={{root: 'productInfo'}}>
                { supplierData.covidService && <Box classes={{root: 'detailComponent'}}>
                  <Typography classes={{root: 'subheader'}}>COVID-19 Services: </Typography>
                  <Typography classes={{root: 'dataContent'}}>{supplierData.covidService}</Typography>
                  </Box>
                }
                { supplierData.covidProduct && <Box classes={{root: 'detailComponent'}}>
                    <Typography classes={{root: 'subheader'}}>COVID-19 Products: </Typography>
                    <Typography classes={{root: 'dataContent'}}>{supplierData.covidProduct}</Typography>
                  </Box>
                }
              </Box>
              <Box classes={{root: 'productInfo'}}>
                { supplierData.comments && <Box  classes={{root: 'detailComponent'}}>
                  <Typography classes={{root: 'subheader'}}>Comments:</Typography>
                  <Typography classes={{root: 'dataContent'}}>{supplierData.comments} </Typography>
                </Box>}
              </Box>
            </Box>
            <Box classes={{root:'data'}}>
            
                {supplierData.conName && <Box classes={{root:'businessInfo'}}>

                <Typography classes={{root: 'header1'}}>
                  Concierge:
                </Typography>
                <Box>
                <Grid container spacing={2}>
                    <Grid container item xs={6} spacing={2}>
                        <Box classes={{root: 'contactData'}}>
                            <Typography classes={{root: 'contactName'}}> {supplierData.conName} </Typography>
	                        <Box classes={{root: 'websiteComponent'}}>
	                            <PhoneIcon />
	                            <Typography classes = {{root: 'returnList'}}> {supplierData.conPhone} </Typography>
	                        </Box>
	                        <Box classes={{root: 'websiteComponent'}}>
	                            <EmailIcon/>
	                            <ButtonBase href={`mailto:${info.email}`}>
	                                <Typography classes={{root: 'returnList'}}> {supplierData.conEmail} </Typography>
	                            </ButtonBase>
	                        </Box>
                        </Box>
                    </Grid>
                 </Grid>
                 </Box>
            
            </Box>}
                
            </Box>
          </Box>
        }


      </div>
    </Dialog>
  );
};

export default DetailPage;
