/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Box, Typography, Button, Divider } from "@material-ui/core";
import SearchDetailsStyles from "./styles";
import { useHistory } from "react-router-dom";
import ToolBar from "./toolbar";
import ResultsSection from "./results";
import PaginationSection from "./pagination";
import MapComponent from "./map-component";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DetailModal from '../new-search-details-component'
import LoadingScreen from "../shared/loading-screen";
import { getOrgLogo } from "../../constants/config";
import NoResultsPage from "../no-results";
import ContactCard from "./contact-card"
import {
  useQueryParams,
  StringParam,
  withDefault,
  ArrayParam,
  NumberParam,
} from "use-query-params";

import { useParams } from "react-router-dom";
import useSuppliers from "../../hooks/suppliers.hook";
import {useState} from "react"

const SearchDetails = (props) => {
  const { term: searchTerm } = useParams();
  const [{ state, city, zip, page, filter }] = useQueryParams({
    state: StringParam,
    city: StringParam,
    zip: StringParam,
    page: withDefault(NumberParam, 1),
    filter: ArrayParam,
  });

  const [id, setId] = useState(undefined)
  const [open, toggleModal] = useState(false)
  const [org,setOrg] = useState(undefined)
  const history = useHistory();

  const navigateToDetails = (e) => {
      console.log(e);
	  setId(e.key)
    // if (e.key) {
    //   history.push(`/search-details/${e.key}`);
    // }
  };

  const { results, count, total, loading, markers } = useSuppliers();
  const entites = {
    '&#039;': "'",
    '&quot;': '"',
  };
  
  for (var i = 0; i < results.length; i++) {
      if (typeof results[i] !== 'undefined') {
        if (typeof results[i].name === 'string') results[i].name = results[i].name.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof results[i].organization === 'string') results[i].organization = results[i].organization.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof results[i].service === 'string') results[i].service = results[i].service.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof results[i].product === 'string') results[i].product = results[i].product.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof results[i].covidService === 'string') results[i].covidService = results[i].covidService.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof results[i].covidProduct === 'string') results[i].covidProduct = results[i].covidProduct.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof results[i].website === 'string') results[i].website = results[i].website.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof results[i].address1 === 'string') results[i].address1 = results[i].address1.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof results[i].email === 'string') results[i].email = results[i].email.replace(/&#?\w+;/g, match => entites[match]);
      }
  }

  return (
    <div css={SearchDetailsStyles}>
      {loading && <LoadingScreen />}
      <Box classes={{ root: "tootlbar" }}>
        <ToolBar searchTerm={searchTerm} filter={filter} />
      </Box>
      <Box classes={{ root: "container" }}>
        <Box classes={{ root: "searchContainer" }}>
          <Box classes={{ root: "childComponent" }}>
            <Box classes={{ root: "resultSection" }}>
              <ResultsSection
                searchTerm={searchTerm}
                count={total}
                filter={filter}
              />
            </Box>
            <Box classes={{ root: "subComponent" }}>
              <Card>
                {results && results.length
                  ? results.map((item) => (
                      <Box key={item.key} id={item.key}>
                        <CardContent
                          key={"C" + item.key}
                        >
                          <Box classes={{ root: "headerContent" }}>
                            {item.name && (
                              <Typography
                                gutterBottom
                                classes={{ root: "subHeader" }}
                              >
                                {item.name}
                              </Typography>
                            )}
                            <Typography classes={{root: "logo"}} onClick={()=>{setOrg(item);console.log(item)}}>
                              <img
                                src={getOrgLogo(item)}
                                height="50"
                                width="64"
                                alt="Logo"
                                style={{ objectFit: "cover" }}
                              />
                            </Typography>
                          </Box>
                          <Box onClick={() => navigateToDetails(item)}>
                            {item.service && (
                              <Typography classes={{ root: "cardTitle" }}>
                                SERVICES
                              </Typography>
                            )}
                            {item.service && (
                              <Typography
                                gutterBottom
                                classes={{ root: "header" }}
                              >
                                {item.service}
                              </Typography>
                            )}
                            {item.product && (
                              <Typography classes={{ root: "cardTitle" }}>
                                PRODUCTS
                              </Typography>
                            )}
                            {item.product && (
                              <Typography
                                gutterBottom
                                classes={{ root: "header" }}
                              >
                                {item.product}
                              </Typography>
                            )}
                            {item.covidService && (
                              <Typography classes={{ root: "cardTitle" }}>
                                COVID-19 SERVICES
                              </Typography>
                            )}
                            {item.covidService && (
                              <Typography
                                gutterBottom
                                classes={{ root: "header" }}
                              >
                                {item.covidService}
                              </Typography>
                            )}
                            {item.covidProduct && (
                              <Typography classes={{ root: "cardTitle" }}>
                                COVID-19 PRODUCTS
                              </Typography>
                            )}
                            {item.covidProduct && (
                              <Typography
                                gutterBottom
                                classes={{ root: "header" }}
                              >
                                {item.covidProduct}
                              </Typography>
                            )}
                          </Box>
                        </CardContent>
                        <CardActions classes={{ root: "subContent" }}>
                          <Button
                            target="_blank"
                            href={item.website}
                            variant="contained"
                            classes={{ root: "buttonComponent" }}
                          >
                            Visit Website
                          </Button>
                          <Box classes={{ root: "contactComponent" }}>
                            {item.address1 && (
                              <Typography
                                variant="body2"
                                classes={{ root: "contactInfo" }}
                              >
                                {item.address1} {item.city} {item.province}
                              </Typography>
                            )}
                            {item.phoneNumber && (
                              <Typography
                                variant="body2"
                                classes={{ root: "contactInfo" }}
                              >
                                {item.phoneNumber}
                              </Typography>
                            )}
                            {item.email && (
                              <Typography
                                variant="body2"
                                classes={{ root: "contactInfo" }}
                              >
                                {item.email}
                              </Typography>
                            )}
                          </Box>
                        </CardActions>
                        <Divider />
                      </Box>
                    ))
                  : !loading && <NoResultsPage />}
              </Card>
            </Box>
            <PaginationSection
              classes={{ root: "pagination" }}
              // getPageNumber={selectedPageNumber}
              page={page}
              count={count}
              // oldFilter={oldFilter}
            />
          </Box>
          <Box classes={{ root: "map" }}>
            <MapComponent
              containerElement={<div style={{ height: "100%" }} />}
              mapElement={<div style={{ height: "100%" }} />}
              loadingElement={<div style={{ height: "100%" }} />}
              data={markers}
            />
          </Box>
        </Box>
      </Box>
      <ContactCard
        setOrg={setOrg}
        org={org}
      />
      <DetailModal
        setId={setId}
        id={id}
      />
    </div>
  );
};

export default SearchDetails;
