import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import FormGroup from "@material-ui/core/FormGroup";
import Lang from '../shared/lang';

export class FirstPage extends Component
{

    rule1 = () => {
        const textValue = document.getElementById('registrasName').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }

    rule2 = () => {
        if(document.querySelector('input[name="organization"]:checked') === null) {
            return false;
        } else {
            return true;
        }
    }

    rule3 = () => {
        if(document.querySelector('input[name="supplier"]:checked') === null) {
            return false;
        } else {
            return true;
        }
    }

    continue = e => {
        e.preventDefault();
        const rule1 = this.rule1();
        const rule2 = this.rule2();
        const rule3 = this.rule3();
        let stepState = true;

        if(!rule1) {
            document.getElementById("registrasName").focus();
            document.getElementById("registrasName-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("registrasName-label").style.color = "#0099CE";
        }

        if(!rule2) {
            document.getElementById("organization").style.display = "block";
            stepState = false;
        } else {
            document.getElementById("organization").style.display = "none";
        }

        if(!rule3) {
            document.getElementById("supplier").style.display = "block";
            stepState = false;
        } else {
            document.getElementById("supplier").style.display = "none";
        }

        if(stepState === true) {
            this.props.nextStep();
        }
    };
    render() {
    const layout = {
        width: '680px',
        marginLeft: 'auto',
        marginRight: 'auto',
    };
    const headerBlocks = {
        marginBottom: '8px',
    }
    const requireColor = {
        color: 'red',
    }
    const requireText = {
        color: 'red',
        display: 'none',
    }
    const titleBlocks = {
        marginBottom: '8px',
    }
    const paper = {
        marginTop: '18px',
        marginBottom: '18px',
        padding: '28px',
    }
    const buttons = {
        display: 'flex',
        justifyContent: 'flex-end',
    }
    const button = {
        marginTop: '8px',
        marginLeft: '8px',
    }
    const { values, handleChange } = this.props;

    return (
        <React.Fragment>
        <CssBaseline />
        <main style={layout}>
        <Paper style={paper}>
            <Typography component="h2" variant="h4" align="center" style={titleBlocks}>
                {Lang().register.indigenousSupplier}
            </Typography>
            <Typography variant="body2" gutterBottom style={titleBlocks}>
                {Lang().register.governmentCanadaHeader}
            </Typography>
            <Typography variant="body2" gutterBottom style={titleBlocks}>
                {Lang().register.councilAdvancementHeader}
            </Typography>
            <Typography variant="body2" gutterBottom style={titleBlocks}>
                {Lang().register.membersThereforeHeader}
            </Typography>
            <Typography variant="body2" gutterBottom style={titleBlocks}>
                {Lang().register.fillingAttachedHeader}
            </Typography>
            <React.Fragment>
            
            <br /> 
            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
	            Are you Indigenous Business?
	        </Typography>   
	        <Box component="span" style={requireColor}> * </Box>
	        <Grid container spacing={3}>
	            <Grid item xs={12} sm={6}>
	                <FormControl component="fieldset">
	                <RadioGroup aria-label="indigenousBusiness" name="indigenousBusiness" value={values.indigenousBusiness} onChange={handleChange('indigenousBusiness')}>
	                <FormControlLabel value="1" control={<Radio />} label={Lang().register.yes} />
	                <FormControlLabel value="0" control={<Radio />} label={Lang().register.no} />
	                </RadioGroup>
	                </FormControl>
	            </Grid>
	        </Grid>
	        <Box id="indigenousBusiness" component="p" style={requireText}> {Lang().register.requiredQuestion} </Box>
	        <br />
            
	        <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
	            {Lang().register.registrarsName}
	        </Typography>
            <Grid container spacing={3} style={headerBlocks}>
                <Grid item xs={12} sm={6}>
                <TextField
                required
                id="registrasName"
                name="registrasName"
                label= {Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('registrasName')}
                defaultValue={values.registrasName}
                />
                </Grid>
            </Grid>
            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
                {Lang().register.organization}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>
            <Grid container spacing={3} style={headerBlocks}>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                    <RadioGroup aria-label="organization" name="organization" value={values.organization} onChange={handleChange('organization')}>
                        <FormControlLabel value="cando" control={<Radio />} label="CANDO" />
                        <FormControlLabel value="itac" control={<Radio />} label="ITAC" />
                        <FormControlLabel value="ccab" control={<Radio />} label="CCAB" />
                        <FormControlLabel value="other" control={<Radio />} label={Lang().register.other} />
                    </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Box id="organization" component="p" style={requireText}> {Lang().register.requiredQuestion} </Box>
            
            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
                {Lang().register.supplier}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                    <RadioGroup aria-label="supplier" name="supplier" value={values.supplier} onChange={handleChange('supplier')}>
                    <FormControlLabel value="yes" control={<Radio />} label={Lang().register.yes} />
                    <FormControlLabel value="no" control={<Radio />} label={Lang().register.no} />
                    </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Box id="supplier" component="p" style={requireText}> {Lang().register.requiredQuestion} </Box>

            </React.Fragment>
            <React.Fragment>
                <div style={buttons}>
                    <Button
                    variant="contained"
                    color="primary"
                    style={button}
                    onClick={this.continue}
                    >
                        {Lang().register.next}
                    </Button>
                </div>
            </React.Fragment>
        </Paper>
        </main>
        </React.Fragment>

    );
    }
}

export default FirstPage;