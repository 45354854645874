/** @jsx jsx */
import { PlacesSelectContainer } from "./styles";
import { jsx } from "@emotion/core";
import { useState, useMemo } from "react";
import {
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import usePlacesAutocomplete from "use-places-autocomplete";
import PlacesSelectResults from "./places-select-results";
import { useQueryParam, StringParam, useQueryParams } from "use-query-params";

import ClearIcon from "@material-ui/icons/ClearRounded";
import * as R from "ramda";
import Lang from '../../lang';

const PlacesSelect = ({ type, location, setLocation, description }) => {
  const [selected, setSelected] = useState(false);
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["ca"] },
      types: ["(regions)"],
    },
    debounce: 300,
  });

  const [name] = useQueryParam("name", StringParam);
  useMemo(() => {
    if (name) {
      setValue(name);
      setSelected(true);
    }
  }, [name, setValue]);

  const [params, setParams] = useQueryParams({
    city: StringParam,
    state: StringParam,
    zip: StringParam,
    name: StringParam,
  });

  const hasLocation = R.pipe(R.filter(R.identity), R.isEmpty, R.not)(params);

  const clearParams = () => {
    setParams({
      city: undefined,
      state: undefined,
      zip: undefined,
      name: undefined,
    });
    setValue('')
  };

  return (
    <PlacesSelectContainer>
      <FormControl
        classes={
          type === "home"
            ? { root: "homeSelectForm" }
            : { root: "toolbarSelectForm" }
        }
      >
        <TextField
          value={value}
          placeholder = {Lang().fields.placesSelect}
          onChange={({ target }) => {
            setSelected(false);
            setValue(target.value);
          }}
          classes={
            type === "home" ? { root: "homeSource" } : { root: "toolbarSource" }
          }
          InputProps={{
            disableUnderline: true,
            ...(hasLocation
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={clearParams}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}),
          }}
        />
      </FormControl>
      <PlacesSelectResults
        results={data}
        setValue={setValue}
        clearSuggestions={clearSuggestions}
        selected={selected}
        setSelected={setSelected}
        setLocation={setLocation}
        term={description}
      />
    </PlacesSelectContainer>
  );
};

export default PlacesSelect;
