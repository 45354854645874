const registerData = {
    products_supply: [],
    products_supply_other: '',
    services_supply: [],
    services_supply_other: '',
    comments_supply: '',
    products_retool: [],
    products_retool_other: '',
    services_retool: [],
    services_retool_other: '',
    comments_retool: '',
};

const selectorsMetadata = {
    products_supply: [],
    services_supply: [],
    products_retool: [],
    services_retool: [],
};

const supplierData = {
    id: false,
    province: '',
    companyname: '',
    address: '',
    city: '',
    postalcode: '',
    email: '',
    phonenumber: '',
    tollfree: '',
    faxnumber: '',
    description: '',
    products: '',
    website: '',
    facebook: '',
    twitter: '',
    LinkedIn: '',
    other: '',
    firstname: '',
    lastname: '',
    title: '',
    phonenumber3: '',
    extension: '',
    email3: '',
    products_supply: [],
    products_supply_other: '',
    services_supply: [],
    services_supply_other: '',
    comments_supply: '',
    products_retool: [],
    products_retool_other: '',
    services_retool: [],
    services_retool_other: '',
    comments_retool: '',
};

const stepMessages = {
    login: 'init',
    sync: 'init',
    syncprof: 'init',
    syncretool: 'init',
};

const contactsData = [
    {'firstName':'', 'lastName':'', 'title':'', 'phoneNumber':'', 'email':'', 'description':''},
    {'firstName':'', 'lastName':'', 'title':'', 'phoneNumber':'', 'email':'', 'description':''},
    {'firstName':'', 'lastName':'', 'title':'', 'phoneNumber':'', 'email':'', 'description':''},
];

let lastEdit = ['', '', '', '', '', ''];

export { registerData,  selectorsMetadata, supplierData, stepMessages, contactsData, lastEdit };