import css from '@emotion/css';
import logo from '../../assets/background.jpg';
import { theme } from '../../constants/theme';

const HomePageStyles = css `


@media (max-width:800px){
   /* .homeSelectForm {display:none!important}
    .divider {display:none!important}*/
	body{
	overflow:auto !important;
	}
    
    .MuiButton-label {
      font-size: 16px !important;
}
    .homeComponent, .homeButtonComponent {
	width: 94% !important;
	display: flex !important;
      justify-content: center !important;
	  margin-left: 3% !important;
	  margin-right: 3% !important;
	  margin-top: 2% !important;
	  margin-bottom: 1% !important;
	  padding:0px !important;
	  border-radius: 4px;
	  background-color: white !important;
	  		  position:relative !important;

}
  input[type=text] {
      font-size: 16px !important;
	  vertical-align: middle !important;
	  height:40px;
width: 100% !important;
display: inline-block !important;
position:relative !important;
float:left !important;
padding:0px !important;
line-height: 40px !important;
text-overflow: ellipsis;
margin: 0px !important;
text-indent: 4px !important;
    }
	input[type="text"]::placeholder {  
                  
                /* Firefox, Chrome, Opera */ 
                padding: 0px !important;
				text-align:left;
				width: 100% !important;
				display: inline-block !important;
margin: 0px !important;

            } 
	
    
    
    .homeSearch, .homeSource {  
      width: 100% !important;
flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
line-height:40px  !important;
padding:0px  !important;
  border-radius: 4px;

    }
.css-d95cxv, .css-cssveg {  
      width: 50% !important;
flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
  border-radius: 4px;
    background-color: #FFFFFF !important;
	height:40px !important;
    }
	
 .homeSearchForm, .homeSelectForm {  
      width: 100% !important;
flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
  border-radius: 4px;
padding:0px !important;
margin: 0px !important;
    }


    
.searchComponent {
  width: 100% !important;
  height: 200px !important;
  border-radius: 4px;
  flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
justify-content: center !important;
padding:0px !important;
margin: 0px !important;
}

.subContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;

}
	
	.backgroundImage {
  background-image: url(${logo});
    background-color: #FFFFFF;
  background-position:top;
  /*width: 100vw;
  height: 100vh;
  background-size: cover;*/
  background-size: cover;
  min-width: 320px;
  width: 100%;
  height: auto;
  position: relative;
  }
  
.outerLogoComponent {
background-color:#FFFFFF;
  margin: 0px !important;
  padding: 15px 0px !important;
  bottom: 0px !important;
  width: 100%;
   height: auto;
display: flex;
  position:relative !important;
   justify-content: center !important;
    -webkit-box-align: center !important;
  align-items: center !important;


}

.outerLogoComponent img {
max-height:32%;
max-width: 32%;
display:block;

}
.outerComponent {
  height: 100% !important;
      width: 100% !important;
      flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
padding: 0px !important;
justify-content: center !important;
}

/*
.thumbnails ul li a:last-child {
font-weight:bold;
}*/

.homeintro {
	display: block;
	position:relative;
	padding-top:15% !important;
	color:#FFFfff;
	margin: auto;
  	width: 80%;
	text-align:justify;
}

.homeintro div {
	margin: 25px 0;
    text-align: center;
}

.homeintro div a {
	color: #6699CC;
}

}



/* THUMBNAILS */
a {
  color: #000;
}



.thumbnails {
  background-color: #69C;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: relative;
  float:left;
  width: 100%;
  height:auto;
  z-index: 3;
  margin:0px 0px 40px 0px;
    display: flex;
  justify-content: flex-start;

  
}

.thumbnails ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #69C;
}

.thumbnails li a {
  display: block;
  padding: 16px 16px 10px 16px;
  text-decoration: none;
  text-transform:uppercase;
}

.thumbnails li a:hover,
.thumbnails .menu-btn:hover {
  background-color: #386192;
  color:#CCC;
}

.thumbnails .logo {
  display: block;
  float: left;
  font-size: 2em;
  text-decoration: none;
}

/* menu */

.thumbnails .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.thumbnails .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 22px 20px;
  position: relative;
  user-select: none;
}

.thumbnails .menu-icon .navicon {
  background:  #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.thumbnails .menu-icon .navicon:before,
.thumbnails .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.thumbnails .menu-icon .navicon:before {
  top: 5px;
}

.thumbnails .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.thumbnails .menu-btn {
  display: none;
}

.thumbnails .menu-btn:checked ~ .menu {
  max-height: 280px;
}

.thumbnails .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.thumbnails .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.thumbnails .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.thumbnails .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.thumbnails .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
	
	.thumbnails {
  background-color: #69C;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: relative;
  float:left;
  width: 100%;
  height:auto;
  z-index: 3;
  margin:0px 0px 40px 0px;
    display: flex;
  justify-content: center ;

  
}

  .thumbnails li {
    float: left;
  }
  .thumbnails li a {
    padding: 18px 30px;
	  text-transform:none;

  }
  .thumbnails .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .thumbnails .menu-icon {
    display: none;
  }
}







/*DESKTOP*/


.searchComponent {
      margin: auto;
      width: 80% ;
      height: 100%;
      display: block;
      justify-content: space-between;
      height: max(5vw, 3em, 3rem)!important;
    }

.backgroundImage {
  background-image: url(${logo});
  /*width: 100vw;
  height: 100vh;
  background-size: cover;*/
  
  background-size: cover;
  min-height: 100%;
  min-width: 320px;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 27px 0 0 0;
  font-size: max(3vw, 1.5em, 1.5rem);
  font-weight: 800;
  text-align: center;
  color: ${theme.colors.white};
}

.subtitle {
  width: 100%;
  height: 100%;
  font-size: max(1.8vw, 1em, 1rem);
  text-align: center;
  color: ${theme.colors.white};
  margin: 16px 0px 0px 0px;
  flex-grow: 1;
}

.subContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;

}
.logoComponent {
  background-color: ${theme.colors.white};

  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}
.logosecComponent {
  background-color: ${theme.colors.white};
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

}

.outerLogoComponent {
background-color: #FFFFFF;
  margin-top:  80px !important;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap ;
  -webkit-box-pack: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  /*top: 400px !important;*/
  width: 100%;
  height:auto;
}

.outerComponent {
  height: 100%;
  min-height:50%;
  width: 100%;
  display:block;
  padding: 0px;
  margin: 0px;
}

.homeintro {
	display: block;
	position:relative;
	padding:50px 10px;
	color:#FFFfff;
	margin: auto;
  	width: 80%;
	text-align:justify;
}

.homeintro div {
	margin: 25px 0;
	text-align: center;
}

.homeintro div a {
	color: #6699CC;
	
}

`
export default HomePageStyles;