import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Lang from '../shared/lang';

export class SecondPage extends Component
{
    rule1 = () => {
        const textValue = document.getElementById('companyname').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    rule2 = () => {
        const textValue = document.getElementById('address').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    rule3 = () => {
        const textValue = document.getElementById('city').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    rule4 = () => {
        if(document.querySelector('input[name="province"]:checked') === null) {
            return false;
        } else {
            return true;
        }
    }
    rule5 = () => {
        const textValue = document.getElementById('postalcode').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    rule6 = () => {
        const textValue = document.getElementById('email').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    rule7 = () => {
        const textValue = document.getElementById('phonenumber').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }

    continue = e => {
        const response = new Object();
        const rule1 = this.rule1();
        const rule2 = this.rule2();
        const rule3 = this.rule3();
        const rule4 = this.rule4();
        const rule5 = this.rule5();
        const rule6 = this.rule6();
        const rule7 = this.rule7();
        let stepState = true;
        let focus = false;

        if(!rule1) {
            document.getElementById("companyname").focus();
            document.getElementById("companyname-label").style.color = "#f44336";
            stepState = false;
            focus = true;
        } else {
            document.getElementById("companyname-label").style.color = "#0099CE";
        }

        if(!rule2) {
            if(focus === false){
                document.getElementById("address").focus();
                focus = true;
            }
            document.getElementById("address-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("address-label").style.color = "#0099CE";
        }

        if(!rule3) {
            if(focus === false){
                document.getElementById("city").focus();
                focus = true;
            }
            document.getElementById("city-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("city-label").style.color = "#0099CE";
        }

        if(!rule4) {
            document.getElementById("province").style.display = "block";
            stepState = false;
        } else {
            document.getElementById("province").style.display = "none";
        }

        if(!rule5) {
            if(focus === false){
                document.getElementById("postalcode").focus();
                focus = true;
            }
            document.getElementById("postalcode-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("postalcode-label").style.color = "#0099CE";
        }

        if(!rule6) {
            if(focus === false){
                document.getElementById("email").focus();
                focus = true;
            }
            document.getElementById("email-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("email-label").style.color = "#0099CE";
        }

        if(!rule7) {
            if(focus === false){
                document.getElementById("phonenumber").focus();
                focus = true;
            }
            document.getElementById("phonenumber-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("phonenumber-label").style.color = "#0099CE";
        }

        if(stepState === true) {
            this.props.nextStep();
        }

        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
    const errorVisible = {
        display: 'none',
    };
    const paper = {
        marginTop: '18px',
        marginBottom: '18px',
        padding: '28px',
    };
    const layout = {
        width: '680px',
        marginLeft: 'auto',
        marginRight: 'auto',
    };
    const requireColor = {
        color: 'red',
    }
    const requireText = {
        color: 'red',
        display: 'none',
    }
    const headerName = {
        paddingBottom: '8px',
        marginBottom: '26px',
        paddingTop: '8px',
        paddingLeft: '8px',
        backgroundColor: '#868B8E',
        color: '#FFFFFF',
    };
    const headerBlocks = {
        marginBottom: '8px',
    };
    const headerMargins = {
        marginBottom: '12px',
    };
    const buttons = {
        display: 'flex',
        justifyContent: 'flex-end',
    };
    const titleBlocks = {
        marginBottom: '8px',
    };
    const button = {
        marginTop: '8px',
        marginLeft: '8px',
    };
    const formControl = {
        margin: '3px',
        width: '100%'
    };
    const { values, handleChange } = this.props;

    return (
        <React.Fragment>
        <CssBaseline />
        <main style={layout}>
        <Paper style={paper}>

        <React.Fragment>
            <Typography component="h2" variant="h4" align="center" style={titleBlocks}>
                {Lang().register.indigenousSupplier}
            </Typography>
            <Typography variant="body1" gutterBottom style={headerName}>
                {Lang().register.companyInformationHeader}
            </Typography>
            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
                {Lang().register.companyname}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    id="companyname"
                    name="companyname"
                    label={Lang().register.yourAnswer}
                    fullWidth
                    autoComplete="given-name"
                    onChange={handleChange('companyname')}
                    defaultValue={values.companyname}
                    />
                </Grid>
            </Grid>
            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
                {Lang().register.address}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                required
                id="address"
                name="address"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('address')}
                defaultValue={values.address}
                />
                </Grid>
            </Grid>
            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
                {Lang().register.city}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                required
                id="city"
                name="city"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('city')}
                defaultValue={values.city}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
                {Lang().register.province}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>

            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <FormControl component="fieldset" style={formControl}>
                    <Select
                        labelId="province"
                        id="province"
                        name="province"
                        onChange={handleChange('province')}
                        defaultValue={values.province}
                    >
                        <MenuItem value="ab">AB</MenuItem>
                        <MenuItem value="bc">BC</MenuItem>
                        <MenuItem value="mb">MB</MenuItem>
                        <MenuItem value="nb">NB</MenuItem>
                        <MenuItem value="nl">NL</MenuItem>
                        <MenuItem value="ns">NS</MenuItem>
                        <MenuItem value="nt">NT</MenuItem>
                        <MenuItem value="nu">NU</MenuItem>
                        <MenuItem value="on">ON</MenuItem>
                        <MenuItem value="pe">PE</MenuItem>
                        <MenuItem value="qc">QC</MenuItem>
                        <MenuItem value="sk">SK</MenuItem>
                        <MenuItem value="yt">YT</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
                {Lang().register.postalcode}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                required
                id="postalcode"
                name="postalcode"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('postalcode')}
                defaultValue={values.postalcode}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
                {Lang().register.email}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                required
                id="email"
                name="email"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('email')}
                defaultValue={values.email}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" component="span" gutterBottom style={headerBlocks}>
                {Lang().register.phonenumber}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                required
                id="phonenumber"
                name="phonenumber"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('phonenumber')}
                defaultValue={values.phonenumber}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" gutterBottom style={headerBlocks}>
                {Lang().register.tollfree}
            </Typography>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                id="tollfree"
                name="tollfree"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('tollfree')}
                defaultValue={values.tollfree}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" gutterBottom style={headerBlocks}>
                {Lang().register.faxnumber}
            </Typography>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                id="faxnumber"
                name="faxnumber"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('faxnumber')}
                defaultValue={values.faxnumber}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" gutterBottom style={headerBlocks}>
                {Lang().register.description}
            </Typography>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
	                id="description"
	                name="description"
	                label={Lang().register.yourAnswer}
	                fullWidth
	                autoComplete="given-name"
	                onChange={handleChange('description')}
	                defaultValue={values.description}
	                multiline
	                rows={5}
                />
                </Grid>
            </Grid>

            {/*<Typography variant="body1" gutterBottom style={headerBlocks}>
                {Lang().register.products}
            </Typography>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
	                id="products"
	                name="products"
	                label={Lang().register.yourAnswer}
	                fullWidth
	                autoComplete="given-name"
	                onChange={handleChange('products')}
	                defaultValue={values.products}
	                multiline
	                rows={5}
                />
                </Grid>
            </Grid>*/}

            <Typography variant="body1" gutterBottom style={headerBlocks}>
                {Lang().register.website}
            </Typography>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                id="website"
                name="website"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('website')}
                defaultValue={values.website}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" gutterBottom style={headerBlocks}>
                {Lang().register.facebook}
            </Typography>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                id="facebook"
                name="facebook"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('facebook')}
                defaultValue={values.facebook}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" gutterBottom style={headerBlocks}>
                {Lang().register.twitter}
            </Typography>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                id="twitter"
                name="twitter"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('twitter')}
                defaultValue={values.twitter}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" gutterBottom style={headerBlocks}>
                {Lang().register.LinkedIn}
            </Typography>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                id="LinkedIn"
                name="LinkedIn"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('LinkedIn')}
                defaultValue={values.LinkedIn}
                />
                </Grid>
            </Grid>

            <Typography variant="body1" gutterBottom style={headerBlocks}>
                {Lang().register.other}
            </Typography>
            <Grid container spacing={3} style={headerMargins}>
                <Grid item xs={12} sm={6}>
                <TextField
                id="other"
                name="other"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange('other')}
                defaultValue={values.other}
                />
                </Grid>
            </Grid>

            <React.Fragment>
                <div style={buttons}>
                    <Button onClick={this.back} style={button}>
                        {Lang().register.back}
                    </Button>
                    <Button
                    variant="contained"
                    color="primary"
                    style={button}
                    onClick={this.continue}
                    >
                        {Lang().register.next}
                    </Button>
                </div>
                <div id="error" style={errorVisible}>error message!</div>
            </React.Fragment>
        </React.Fragment>

        </Paper>
        </main>
        </React.Fragment>
    );
    }
}

export default SecondPage;