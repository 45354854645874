import css from '@emotion/css';
import { theme } from '../../../constants/theme';

const SearchBarStyles = css `

@media (max-width:800px){



.searchComponent {
  width: 100% !important;
  height: auto !important;
  border-radius: 0px;
  flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
justify-content: center !important;
padding:0px !important;
margin: 0px !important;
}

    .homeSearch, .homeSource {  
      width: 100% !important;
flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
line-height:40px  !important;
padding:0px  !important;
  border-radius: 4px;

    }


.toolbarSearch,  .toolbarSource {
 width: 100% !important;
  height: auto !important;
  border-radius: 0px;
  flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
justify-content: center !important;
padding:0px !important;
margin: 0px !important;
	box-shadow: none !important;
	background-color: #FFFFFF !important;
	border-bottom: none !important;

}
 



 .homeSearchForm, .homeSelectForm {  
      width: 100% !important;
flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
  border-radius: 4px;
padding:0px !important;
margin: 0px !important;
    }


.toolbarSelectForm {
 flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
justify-content: center !important;


}
.toolbarSearchForm {
flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
justify-content: center !important;
}

    .homeButtonComponent, .homeComponent, .searchDetailsComponent, .searchButtonComponent 
	{
	width: 94% !important;
	display: flex !important;
      justify-content: center !important;
	  margin-left: 3% !important;
	  margin-right: 3% !important;
	  margin-top: 2% !important;
	  margin-bottom: 1% !important;
	  padding:0px !important;
	  border-radius: 4px;
	  	  background-color: white !important;
		  position:relative !important;

}

.Button {
 flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
justify-content: center !important;

  font-size: 16px;
}

.toolbarButton {
  flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
justify-content: center !important;
font-size: 16px;

}
.iconButton {
  color: #ACC1D8 ;
}

.Button:hover {
  background-color: ${theme.colors.darkBlue};
}
.toolbarButton:hover {
  background-color: ${theme.colors.darkBlue};
}

.MenuItem {
  background-color: ${theme.colors.white};
}

.divider {
  height: 40px;
  width: 1px;
}

.toolbarDivider {
  height: 40px;
  width: 1px;
}




}









  /*DESKTOP*/


.searchComponent {
  width: 90%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  box-shadow: none;
  position: relative;
}

.homeSearch {  
  width: max(33vw, 4em, 3rem);
  ${theme.classes.searchField};
}


.homeSource {
  height: max(4vw, 4em, 3rem);
  width: max(33vw, 4em, 3rem);
  ${theme.classes.selectBar};
}
.toolbarSource {
  width: 400px;
  ${theme.classes.selectBar};
}
.homeSelectForm {
  padding-left: ${theme.paddings.paddingLong};

}
.homeSearchForm {
  /* padding-left: ${theme.paddings.paddingLong}; */
}


.toolbarSelectForm {
  padding-left: ${theme.paddings.paddingSmall};

}
.toolbarSearchForm {
  /* padding-left: ${theme.paddings.paddingSmall}; */
}

.homeButtonComponent{
    padding-left: ${theme.paddings.paddingLong};
    height: max(5vw, 3em, 3rem);
    width: max(9vw, 5em, 4rem);
    align-items: center;
}

.homeButtonComponent button{
    min-width:120px !important;
}

.searchButtonComponent{
    padding-left: ${theme.paddings.paddingSmall};
    height: 100%;
    width: 80px;
    border-radius: 4px;
}
.Button {
  ${theme.classes.button};
  font-size: 16px;
}

.toolbarButton {
  ${theme.classes.button};
  font-size: 16px;

}
.iconButton {
  color: #ACC1D8 ;
}

.Button:hover {
  background-color: ${theme.colors.darkBlue};
}
.toolbarButton:hover {
  background-color: ${theme.colors.darkBlue};
}

.MenuItem {
  background-color: ${theme.colors.white};
}

.divider {
  height: 48px;
  width: 1px;
}

.toolbarDivider {
  height: 48px;
  width: 1px;
}

.homeComponent {
  ${theme.classes.searchBox};
  padding:0px 20px;
}

.searchDetailsComponent {
  ${theme.classes.searchBox};
  padding: ${theme.paddings.paddingSmall};
}
`
export default SearchBarStyles;