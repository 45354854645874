/** @jsx jsx */
import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import MainPage from './MainPage';
import Login from './Login';
import TwoFactorLogin from './TwoFactorLogin';
import NullPage from './NullPage';
import SPGlobals from './globals';

//Pages
import Settings from './Settings';
import Header from './Header';
import HomePage from './HomePage';
import ProfileTabs from './ProfileTabs';
import BusinessPage from './BusinessPage';
import MarketingPage from './MarketingPage';
import ContactsPage from './ContactsPage';


export class SupplierPage extends Component {
	/**
	 * Parsing URL TODO: to be normalized by shared component
	 * 
	 */
	parseRequest() {
		let request = document.location.href.split('supplier-page/');
		if (request.length < 2) {
			return false;
		}
		
		let request_arr =  request[1].split(':');
		return {
			action: request_arr[0]? request_arr[0] : '',
			one: request_arr[1]? request_arr[1] : '',
			two: request_arr[2]? request_arr[2] : '',
		}
	};
	
	state = {
		screen: 'nullPage'
    };

    setCall = (call = 'init') => {
        this.setState({
            call: call
        });
    };

    // Proceed to next step
    showScreen = (add = 'nullPage') => {    	
    	this.setState({
            screen: add
        });
    };

    render() {
        SPGlobals.items.SupplierPage = this;
        SPGlobals.items.currentPage = this.state.screen;
        
        console.log('rendering: ' + this.state.screen);
        
        switch (this.state.screen) {
            case 'nullPage':
                return (
                <NullPage />
            );
            case 'login':
            	return (
                <Login />
            );
            case 'TwoFactorLogin':
            	return (
                <TwoFactorLogin />
            );	
            case 'Settings':
            	return (
            		<React.Fragment>
	            		<div className="App" style={{height: "54px"}}>
	                        <Header/>
	                    </div>
                    
                    	<Settings />
                    </React.Fragment>
                );		
            case 'Home_Page':
                return (
            		<React.Fragment>
	            		<div className="App" style={{height: "54px"}}>
	                        <Header/>
	                    </div>
                    
                    	<HomePage />
                    </React.Fragment>
                );
            case 'Basic_Page':
                return (
            		<React.Fragment>
	            		<div className="App" style={{height: "54px"}}>
	                        <Header/>
	                    </div>
                    
                    	<ProfileTabs />
                    </React.Fragment>
                );
            case 'Business_Page':
                return (
            		<React.Fragment>
	            		<div className="App" style={{height: "54px"}}>
	                        <Header/>
	                    </div>
                    
                    	<BusinessPage />
                    </React.Fragment>
                );
            case 'Marketing_Page':
                return (
            		<React.Fragment>
	            		<div className="App" style={{height: "54px"}}>
	                        <Header/>
	                    </div>
                    
                    	<MarketingPage />
                    </React.Fragment>
                );
            case 'Contacts_Page':
                return (
            		<React.Fragment>
	            		<div className="App" style={{height: "54px"}}>
	                        <Header/>
	                    </div>
                    
                    	<ContactsPage />
                    </React.Fragment>
                );
            default:
            	return (
                		<React.Fragment>
    	            		<div className="App">
    	                        <Header/>
    	                    </div>
                        
                        	<div className="App"><br /><br /><br /> Error 404 - page {this.state.screen} not found.</div>
                        </React.Fragment>
                    );
        }
    }
}

export default SupplierPage;