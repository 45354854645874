import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import splashLogo from '../../assets/Splash_Logo.png';
import SVGlobals from './globals';

import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';

import logoSrc from '../../assets/HomePage_Logo.png';


export class EmailPasswordReset extends Component
{
	displayNewPassForm = (status) => {
		if (status == true) {
			document.querySelector("#newpass-form").style.display = 'block';
		} else {
			document.querySelector("#newpass-form").style.display = 'none';
		}
    };
    
    displaySuccessMessage = (status) => {
		if (status == true) {
			document.querySelector("#success-msg").style.display = 'block';
		} else {
			document.querySelector("#success-msg").style.display = 'none';
		}
    };
	
	displayEmailError = (status) => {
		if (status == true) {
			document.querySelector("#error").style.display = 'block';
		} else {
			document.querySelector("#error").style.display = 'none';
		}
    };
	
    switchSubmitButtonStatus = (status) => {
		if (status == true) {
			document.querySelector('#submitemail-btn').disabled = false;
		} else {
			document.querySelector('#submitemail-btn').disabled = true;
		}
    };
    
    submitEmail = e => {
    	let email   = document.querySelector('#outlined-email-input').value;
    	
    	if (email.length < 5) {
    		return false;
    	}
    	
    	console.log("SSSSSSSSSSSSSSSSSSSSS: " + email);
    	
    	this.switchSubmitButtonStatus(false);
    	
    	BpWebSocket.send({
			call: 'emailPasswordReset',
			payload: {
				email: email
			}
		});
    	
    };
    
    handleFormSubmit = (event) => {
        event.preventDefault();
        this.submitEmail(event);
    }
    
    
    render() {
    	SVGlobals.items.EmailPasswordReset = this;
	
	    const imgSplashLogo = {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		};
		
	    const layout = {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	        paddingTop: '12px',
	    	
	    };
	    const headerBlocks = {
	        marginBottom: '8px',
	    }
	    const paper = {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	        width: '720px'
	    }
	    const button = {
	        marginBottom: '12px',
	        padding: '14px',
	        width: '100%'
	    }
	    
	    const field = {
	        width: '100%'
	    }
	
	    const codeText = { 
    		textAlign: 'center',
    		//letterSpacing: '18px',
    		fontSize: '27px',
    		//textTransform: 'uppercase'
		}
	    
	    const labelCodeText = {
    		
	    }
	    
	    const errorVisible = {
	        display: 'none',
	        color: 'red',
	        marginTop: '5px',
	        marginBottom: '12px',
	    };
	    
	    const form = {
    		width: '84%',
    		margin: 'auto',
    		textAlign: 'center'
	    }
	    
	    const headline = {
			textTransform: 'uppercase',
			color: '#94AFC3',
			fontSize: '45px',
			lineHeight: '54px',
	    }
	    
	    const logoImg = {
			width: '222px'
		}
	    
	    const logoStyle = {
    		textAlign: 'center',
	    }
	    
	    const preambleStyle = {
    		margin: 'auto',
    		width: '340px',
    		paddingTop: '27px',
    		paddingBottom: '45px'
	    }
	    
	    const successMsg = {
    		display: 'none',
    		fontSize: '27px',
    		textAlign: 'center'
	    }
	    
	    return (
	        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
		      <img style={imgSplashLogo} src={splashLogo} />
		    </div>
	        <main style={layout}>
	        <Paper style={paper}>
	        	<div style={logoStyle}>
	              <img style={logoImg} src={logoSrc} />
	            </div>
	            
	            <Typography style={headline} component="div" variant="caption" align="center">
	            	Indigenous<br />Suppliers Co-op
	            </Typography>
	        	
            	<Typography style={preambleStyle} component="div" variant="caption" align="center">
	              {Lang().SP.emailPasswordReset.preamble}
                </Typography>
                
                <br />
                
	            <div id="newpass-form">
	              <form noValidate autoComplete="off" style={form} onSubmit={this.handleFormSubmit}>
	                <TextField
	                	inputProps={{min: 0, style: codeText, spellCheck: 'false'}}
	                	InputLabelProps={{ style: labelCodeText }}
	                    style={field}
	                    id="outlined-email-input"
	                    name="outlined-email-input"
	                    label="Enter E-Mail Address Here"
	                    variant="outlined"
	                />
	                
	                <div style={{marginTop: '39px', textAlign: 'center'}}>
	                  <Button id="submitemail-btn" type="submit" variant="contained" color="primary" style={button}>
		            	Reset Password
		              </Button>
	                </div>
	                
			        <br /><br />
	                
			        <Typography id="error" style={errorVisible} component="span" variant="caption" align="center">
		              This address is not registered with Indigenous Suppliers.<br />
		              Please enter correct E-Mail address.
	                </Typography>
		              <br /><br />
		              <br />
	               </form> 
	             </div>
	             <div id="success-msg" style={successMsg}>
	             	Your E-Mail address has been verified. Please follow next step instructions sent to your inbox.
	             </div>
	        </Paper>
	        </main>
	        </React.Fragment>
	    );

    }
}

export default EmailPasswordReset;