import { searchSupplierDatabase, getSuppliersCount, getSupplierByID } from './api.service';

export const getSupplierData = async (term, page, city, state, zip, filter) => {
  try {
    const resultsData = await searchSupplierDatabase(term, page, city, state, zip, filter);
    if (resultsData.data) {
      return resultsData.data.map((sr) => {
        return {
          key: sr.id,
          product: sr.product,
          name: sr.business_name,
          address1:  sr.address? sr.address : '',
          city: sr.city ? sr.city: '',
          province: sr.province ? sr.province: '',
          postalCode: sr.postal_code? sr.postal_code: '',
          website: sr.website,
          phoneNumber: sr.phone,
          email: sr.email,
          service: sr.service,
          covidService: sr.covid_service,
          covidProduct: sr.covid_product,
          organization: sr.organizationID,
          description: sr.description,
          image:'',
          conName: sr.con_name,
          conPhone: sr.con_phone,
          conEmail: sr.con_email,
          orgName: sr.org_name
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};


export const getSupplierDetailsByID = async (supplierID) => {
  try {
    const resultsData = await getSupplierByID(supplierID);
    if (resultsData.data) {
      return resultsData.data.map((sr) => {
        return {
          product: sr.product,
          name: sr.business_name,
          address1:  sr.address? sr.address : '',
          city: sr.city ? sr.city: '',
          province: sr.province ? sr.province: '',
          postalCode: sr.postal_code? sr.postal_code: '',
          website: sr.website,
          phoneNumber: sr.phone,
          email: sr.email,
          service: sr.service,
          covidService: sr.covid_service,
          covidProduct: sr.covid_product,
          facebook: sr.facebook,
          youtube: sr.youtube,
          twitter: sr.twitter,
          linkedIn: sr.linkedIn,
          instagram: sr.instagram,
          flickr: sr.flickr,
          pinteres: sr.pinterest,
          rss_feeds: sr.rss_feeds,
          first_name: sr.first_name,
          last_name: sr.last_name,
          mobile: sr.mobile,
          cont_phone: sr.cont_phone,
          cont_email: sr.cont_email,
          title: sr.title,
          organization: sr.organizationID,
          afi: sr.afi,
          id: sr.supplierID,
          comments: sr.comments,
          conName: sr.con_name,
          conPhone: sr.con_phone,
          conEmail: sr.con_email,
          orgName: sr.org_name
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getSupplierCount = async (term,  city, state, zip, filter) => {
  try {
    const supplierDataCount= await getSuppliersCount(term, city, state, zip, filter);
    if (supplierDataCount.data ) {
      return {
        rows: supplierDataCount.data.numberOfRows,
        count: supplierDataCount.data.totalCount
      }
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};
