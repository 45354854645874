import axios from "axios";
import { baseURL, requestHeaders } from "../constants/config";

const API = axios.create({
  baseURL: `${baseURL}/api`,
  headers: requestHeaders,
});

export const searchSupplierDatabase = async (params) => {
  const { data }  = await API.get('/supplier', { params });
  return data || [];
};

export const getSuppliersCount = async (params) => {
  const { data }  = await API.get('/supplier/count', { params });
  return data;
};

export const getSupplierByID = async (supplierID) => {
  const baseUrl = `${baseURL}/api/supplier/details?id=${supplierID}`;
  const response = await axios({
    method: "GET",
    url: baseUrl,
    headers: {
      ...requestHeaders,
    },
  });
  return await response;
};

export const getSearchCount = async (term) => {
  const { data } = await API.get("/search", { params: { term } });
  return data;
};
