import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { supplierData, selectorsMetadata, stepMessages, contactsData } from '../shared/register-data';
import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';
import SPGlobals from './globals';

let BPSocketAddress = "wss://imscbpsock.datataskforce.ca";

var display = {
    init: function () {
        document.getElementById("initFailed").style.display = "none";
        document.getElementById("initProcess").style.display = "block";
    },

    failed: function () {
        document.getElementById("initFailed").style.display = "block";
        document.getElementById("initProcess").style.display = "none";
    },
}

var mappingData = (data) => {
	//console.info(data.payload);
	
    supplierData.province = (!data.payload.billing_address_state) ? '' : data.payload.billing_address_state.toLowerCase();
    supplierData.companyname = data.payload.name;
    supplierData.address = data.payload.billing_address_street;
    supplierData.city = data.payload.billing_address_city;
    supplierData.postalcode = data.payload.billing_address_postalcode;
    supplierData.primary_email = data.payload.email1;
    supplierData.phonenumber = data.payload.phone_office;
    supplierData.mobile = data.payload.phone_alternate;
    supplierData.faxnumber = data.payload.phone_fax;
    supplierData.description = data.payload.description;
    supplierData.service = data.payload.service;
    supplierData.website = data.payload.website;
    supplierData.facebook = data.payload.facebook;
    supplierData.twitter = data.payload.twitter;
    supplierData.LinkedIn = data.payload.linkedin;
    supplierData.email = data.payload.primary_email;
    
    //Detailed profile
    supplierData.indigenous_business = data.payload.indigenous_business;
    supplierData.year_founded = data.payload.year_founded;
    supplierData.number_employees = data.payload.number_employees;
    supplierData.percent_indigenous_employees = data.payload.percent_indigenous_employees;
    supplierData.business_legal_form = data.payload.business_legal_form;
    supplierData.duns_number = data.payload.duns_number;
    supplierData.cra_business_number = data.payload.cra_business_number;
    supplierData.tax_free = data.payload.tax_free;
    supplierData.provincial_business_number = data.payload.provincial_business_number;
    supplierData.gst_hst_number = data.payload.gst_hst_number;
    supplierData.qst_number = data.payload.qst_number;
    supplierData.pst_number = data.payload.pst_number;
    supplierData.procurement_business_number = data.payload.procurement_business_number;
    supplierData.payment_method = data.payload.payment_method;
    supplierData.indigenous_certification = data.payload.indigenous_certification;
    supplierData.quality_certification = data.payload.quality_certification;
    supplierData.two_factor_auth = data.payload.two_factor_auth == false ? '0' : data.payload.two_factor_auth;
    
    supplierData.contacts = data.payload.contacts;
    
    if (typeof data.payload.concierge != 'undefined'){
    	supplierData.concierge = {
			'email': data.payload.concierge.email,
			'firstName': data.payload.concierge.first_name,
			'institutionName': data.payload.concierge.institution_name,
			'institutionWebsite': data.payload.concierge.institution_website,
			'lastName': data.payload.concierge.last_name,
			'phoneWork': data.payload.concierge.phone_work
    	}
    }
    
};

var mappingDataContacts = (data) => { return false;

    let contacts = data.contacts;

    contacts.forEach(function(item, i, contacts) {
        contactsData[i].id = item.Id;
        contactsData[i].firstName = item.FirstName;
        contactsData[i].lastName = item.LastName;
        contactsData[i].title = item.Title;
        contactsData[i].phoneNumber = item.PhoneMobile;
        contactsData[i].email = item.primaryEmail;
        contactsData[i].description = item.Description;
        contactsData[i].supplierId = data.id;
    });
}

var mappingDataSupplier = (data) => {
    if(data.payload[0].products !== '') {
        supplierData.products_supply = data.payload[0].products.split(', ');
        supplierData.products_supply_other = data.payload[0].products.description;
    } else {
        supplierData.products_supply = [];
    }

    if(data.payload[0].services !== '') {
        supplierData.services_supply = data.payload[0].services.split(', ');
        supplierData.products_supply_other = data.payload[0].services.description;
    } else {
        supplierData.services_supply = [];
    }
}

var mappingDataRetool = (data) => {
    if(data.payload[0].products !== '') {
        supplierData.products_retool = data.payload[0].products.split(', ');
        supplierData.products_retool_other = data.payload[0].products.description;
    } else {
        supplierData.products_retool = [];
    }

    if(data.payload[0].services !== '') {
        supplierData.services_retool = data.payload[0].services.split(', ');
        supplierData.services_retool_other = data.payload[0].services.description;
    } else {
        supplierData.services_retool = [];
    }
}

var listener = {
    /**
     * Processes initialization message
     * 
     * @param object message  A message object
     */
	for_syncToDomain: function (message) {		
		if (message.call != 'syncToDomain'
			|| false == message.hasOwnProperty('payload')
		) {
			return false;
		}
		
		console.info('INCOMING MESSAGE for_syncToDomain: ');
		console.info(message);

		
		const productArr = [];
        const serviceArr = [];
        const productObj = message.payload.demand.Product;
        const serviceObj = message.payload.demand.Service;

        productObj.forEach(function(item, i, arr) {
            if (Lang().symbol == 'EN') {
                productArr.push(item.name);
            } else {
                productArr.push(item.name_fr);
            }
        });

        serviceObj.forEach(function(item, i, arr) {
            if (Lang().symbol == 'EN') {
                serviceArr.push(item.name);
            } else {
                serviceArr.push(item.name_fr);
            }
        });

        selectorsMetadata.products_supply = productArr;
        selectorsMetadata.services_supply = serviceArr;
        selectorsMetadata.products_retool = productArr;
        selectorsMetadata.services_retool = serviceArr;

        let request = SPGlobals.items.SupplierPage.parseRequest();
		
		switch (request.action) {
			case 'uid':
				BpWebSocket.send({
					call: 'authorizeByUid',
					payload: {
						uid: request.one
					}
				});
				
				break;
				
			default:
				SPGlobals
		        	.items
		        		.SupplierPage.showScreen('login');
				
				break;
		}
	},
	
	/**
	 * Processes authorize by Uid message
	 * 
	 * @param object message  A message object
	 */
	for_authorizeByUid: function (message) {	
		if (message.call != 'authorizeByUid'
			|| true != message.hasOwnProperty('payload')
			|| true != message.payload.hasOwnProperty('status')
		) {
			return false;
		}
		
		console.info('INCOMING MESSAGE for_authorizeByUid: ');
		console.info(message);

		
		switch (message.payload.status) {
			case 'SUPPLIER_GRANTED':
				SPGlobals.items.loginStatus = message.payload.status;
				supplierData.id = message.payload.profile.id;
				
				BpWebSocket.send({
		            call: 'syncSupplierProfile',
		            payload: {
		                id: supplierData.id
		            }
		        });
				
				break;
				
			case 'SUPPLIER_DENIED':
				SPGlobals
		        	.items
		        		.SupplierPage.showScreen('login');
				
				break;
	
			default:
				break;
		}
		
		return true;
	},
	
	/**
	 * Processes authorize message
	 * 
	 * @param object message  A message object
	 */
	for_authorize: function (message) {
		if (message.call != 'authorize'
			|| true != message.hasOwnProperty('payload')
			|| true != message.payload.hasOwnProperty('status')
		) {
			return false;
		}
		
		console.info('INCOMING MESSAGE for_authorize: ');
		console.info(message);		
		
		switch (message.payload.status) {
			case '2F_VER_REQ':
				SPGlobals.items.loginStatus = message.payload.status;
				SPGlobals.items.towFactorSessionKey = message.payload.sessionKey;
				SPGlobals.items.twoFactorContactMethod = message.payload.contactMethod;
				
				SPGlobals
			    	.items
			    		.SupplierPage.showScreen('TwoFactorLogin');
				
				break;
				
			case 'SUPPLIER_GRANTED':
				SPGlobals.items.loginStatus = message.payload.status;
				supplierData.id = message.payload.profile.id;
				
				BpWebSocket.send({
		            call: 'syncSupplierProfile',
		            payload: {
		                id: supplierData.id
		            }
		        });
				
				SPGlobals.items.profileRetrievingTimeout = 
					setTimeout(function(){
						SPGlobals
					    	.items
					    		.LoginPage.showLoginError();
					}, 9999);
				
				break;
				
			case 'SUPPLIER_DENIED':
				SPGlobals.items.loginStatus = message.payload.status;
				SPGlobals
			    	.items
			    		.LoginPage.showLoginError();
				
				break;
	
			default:
				break;
		}
		
		return true;
	},
	
	/**
	 * Processes authorize message
	 * 
	 * @param object message  A message object
	 */
	for_twoFactorAuthorize: function (message) {
		if (message.call != 'twoFactorAuthorize'
			|| true != message.hasOwnProperty('payload')
			|| true != message.payload.hasOwnProperty('status')
		) {
			return false;
		}
		
		console.info('INCOMING MESSAGE for_twoFactorAuthorize: ');
		console.info(message);	
		
		switch (message.payload.status) {
			case 'SUPPLIER_GRANTED':
				SPGlobals.items.loginStatus = message.payload.status;
				supplierData.id = message.payload.profileId;
				
				BpWebSocket.send({
		            call: 'syncSupplierProfile',
		            payload: {
		                id: supplierData.id
		            }
		        });
				
				break;
				
			case '2FCODE_LOGIN_ERROR':
				SPGlobals.items.TwoFactorLogin.showCodeError()
				
				break; 
	
			default:
				break;
		}
		
		return true;		
	},
	
	
	/**
	 * Processes sync supplier profile message
	 * 
	 * @param object message  A message object
	 */
	for_syncSupplierProfile: function (message) {
		if (message.call != 'syncSupplierProfile'
			|| false == message.hasOwnProperty('payload')
		) {
			return false;
		}
		
		console.info('INCOMING MESSAGE for_syncSupplierProfile: ');
		console.info(message);

        if (message.payload.contacts.length > 0) {
            //fill data
            mappingDataContacts(message.payload);
        }

        mappingData(message);
        stepMessages.sync = 'synced';
        clearTimeout(SPGlobals.items.profileRetrievingTimeout);
        
        SPGlobals
	    	.items
	    		.SupplierPage.showScreen('Home_Page');
	},

    for_syncSupplyToProfile: function (message) {
        if (message.call != 'syncSupplyToProfile'
            || false == message.hasOwnProperty('payload')
        ) {
            return false;
        }
        
        console.info('INCOMING MESSAGE for_syncSupplyToProfile: ');
		console.info(message);
        
        mappingDataSupplier(message);
        stepMessages.syncprof = 'synced';

        SPGlobals
            .items
                .SupplierPage.showScreen('Basic_Page');
    },

    for_syncRetoolToProfile: function (message) {
        if (message.call != 'syncRetoolToProfile'
            || false == message.hasOwnProperty('payload')
        ) {
            return false;
        }
        
        console.info('INCOMING MESSAGE for_syncRetoolToProfile: ');
		console.info(message);

        mappingDataRetool(message);
        stepMessages.syncretool = 'synced';

        SPGlobals
            .items
            .SupplierPage.showScreen('Basic_Page');
    },
	
	/**
	 * BPWebSocket Error handler
	 */
    for_errorAnswer: function (message) {
    	console.log(JSON.stringify(message));
    }
}

/**
 * NullPage handler for react framework
 * 
 * 
 */
export class NullPage extends Component
{	
	/**
     * Connecting to the BPWebSocket 
     * 
     * @param data
     */
    connect() {
    	BpWebSocket
    		.listenOnOpen(function (e) {
    			BpWebSocket.send({
    				call: 'syncToDomain'
    			});
    			
    		})
    		.listenOnMessage(function (e) {
    			listener.for_syncToDomain(e);
    			listener.for_authorize(e);
    			listener.for_authorizeByUid(e);
    			listener.for_syncSupplierProfile(e);
                listener.for_syncSupplyToProfile(e);
                listener.for_syncRetoolToProfile(e);
                listener.for_twoFactorAuthorize(e);
                
    		})
            .listenOnError(function (e) {
                listener.for_errorAnswer(e);
            })
    		.open(BPSocketAddress);
    };

    render() {    	
    	this.connect();

    	const layout = {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	    };
    	    
	    const paper = {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	    };
	    
        const initProcess = {
            marginBottom: '8px',
        };
        const initFailed = {
            marginBottom: '8px',
            display: 'none',
        };

        return (
            <React.Fragment>
            <CssBaseline />
            <main style={layout}>
	            <Paper style={paper}>
	
	            <React.Fragment>
	            <Typography component="h2" variant="h4" align="center" id="initProcess" style={initProcess}>
	                {Lang().register.init}
	            </Typography>
	            <Typography component="h2" variant="h4" align="center" id="initFailed" style={initFailed}>
	                {Lang().register.initFail}
	            </Typography>
	            </React.Fragment>
	
	            </Paper>
            </main>
            </React.Fragment>
        );
    }
}

export default NullPage;