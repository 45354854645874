import React, { Component } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import Lang from '../shared/lang';

export class FourthPage extends Component
{
    rule1 = () => {
        if(document.querySelector('input[name="responsecovid"]:checked') === null) {
            return false;
        } else {
            return true;
        }
    }

    continue = e => {
        const rule1 = this.rule1();
        let stepState = true;
        let focus = false;

        if(!rule1) {
            document.getElementById("responsecovid").style.display = "block";
            stepState = false;
        } else {
            document.getElementById("responsecovid").style.display = "none";
        }

        if(stepState === true) {
            let add = this.props.values.responsecovid === 'yes' ? 1 : 2;
            this.props.nextStep(add);
        }
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const errorVisible = {
            display: 'none',
        };
        const paper = {
            marginTop: '18px',
            marginBottom: '18px',
            padding: '28px',
        };
        const layout = {
            width: '680px',
            marginLeft: 'auto',
            marginRight: 'auto',
        };
        const requireColor = {
            color: 'red',
        }
        const requireText = {
            color: 'red',
            display: 'none',
        }
        const headerName = {
            paddingBottom: '8px',
            marginBottom: '26px',
            marginTop: '12px',
            paddingTop: '8px',
            paddingLeft: '8px',
            backgroundColor: '#868B8E',
            color: '#FFFFFF',
        };
        const headerBlocks = {
            marginBottom: '8px',
        };
        const buttons = {
            display: 'flex',
            justifyContent: 'flex-end',
        };
        const titleBlocks = {
            marginBottom: '8px',
        };
        const button = {
            marginTop: '8px',
            marginLeft: '8px',
        };
        const beforeLink = {
            fontSize: 'smaller',
        }
        const { values, handleChange } = this.props;

        return (
            <React.Fragment>
            <CssBaseline />
            <main style={layout}>
            <Paper style={paper}>

            <React.Fragment>
            <Typography component="h2" variant="h4" align="center" style={titleBlocks}>
                {Lang().register.indigenousSupplier}
            </Typography>
            <Typography component="span" style={beforeLink}>
                {Lang().register.findMoreHeader}
            </Typography>
            <Link href="https://buyandsell.gc.ca/calling-all-suppliers-help-canada-combat-covid-19" style={beforeLink}>
                https://buyandsell.gc.ca/calling-all-suppliers-help-canada-combat-covid-19
            </Link>
            <Typography variant="body1" gutterBottom style={headerName}>
                {Lang().register.canadaLookingHeader}
            </Typography>

            <Typography variant="body1" component="span" gutterBottom className={headerBlocks}>
                {Lang().register.responsecovid}
            </Typography>
            <Box component="span" style={requireColor}> * </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                    <RadioGroup aria-label="responsecovid" name="responsecovid" value={values.responsecovid} onChange={handleChange('responsecovid')}>
                    <FormControlLabel value="yes" control={<Radio />} label={Lang().register.yes} />
                    <FormControlLabel value="no" control={<Radio />} label={Lang().register.no} />
                    </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Box id="responsecovid" component="p" style={requireText}> {Lang().register.requiredQuestion} </Box>

            <React.Fragment>
            <div style={buttons}>
            <Button onClick={this.back} style={button}>
                {Lang().register.back}
            </Button>
            <Button
            variant="contained"
            color="primary"
            style={button}
            onClick={this.continue}
            >
                {Lang().register.next}
            </Button>
            </div>
            <div id="error" style={errorVisible}>error message!</div>
        </React.Fragment>

        </React.Fragment>
        </Paper>
        </main>
        </React.Fragment>
    );
    }
}

export default FourthPage;