import { useState, useEffect, useMemo } from "react";
import {
  searchSupplierDatabase,
  getSuppliersCount,
} from "../services/api.service";
import {
  useQueryParams,
  StringParam,
  ArrayParam,
  withDefault,
} from "use-query-params";
import { useParams } from "react-router-dom";
import * as R from "ramda";
import { getOrgLogo } from "../constants/config";
import { reverseGeocode } from "../constants/util";
import { mapLimit } from "async";

const parseSupplier = (sr) => {
  return {
    key: sr.id,
    product: sr.product,
    name: sr.business_name,
    address1: sr.address ? sr.address : "",
    city: sr.city ? sr.city : "",
    province: sr.province ? sr.province : "",
    postalCode: sr.postal_code ? sr.postal_code : "",
    website: sr.website,
    phoneNumber: sr.phone,
    email: sr.email,
    service: sr.service,
    covidService: sr.covid_service,
    covidProduct: sr.covid_product,
    organization: sr.organizationID,
    description: sr.description,
    image: "",
    conName: sr.con_name,
    conPhone: sr.con_phone,
    conEmail: sr.con_email,
    orgName: sr.org_name
  };
};

const getMarkers = async (data) => {
  const results = Promise.all(
    data.map(async (item) => {
      const address = `${item.address1}, ${item.city}, ${item.province}, ${item.postal_code}`;
      console.log(address);
      let description = item.description;
      if (description && description.length > 200) {
        description = description.substring(0, 200) + " ...";
      }
      const locationInfo = await reverseGeocode({ address });
      if (locationInfo && locationInfo.length !== 0) {
        const locationObject = {
          location: locationInfo[0].geometry.location,
          business: item.name,
          state: false,
          key: item.key,
          description: description,
          orgLogo: getOrgLogo(item.organization),
          website: item.website,
        };

        return locationObject;
      }
    })
  );
  return results;
};

const useSuppliers = () => {
  const [query, setParam] = useQueryParams({
    filter: ArrayParam,
    city: StringParam,
    state: StringParam,
    zip: StringParam,
    page: withDefault(StringParam, 1),
  });
  const [results, setResults] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const { term } = useParams();
  const params = R.pipe(
    R.merge({ term }),
    R.filter(R.identity),
    JSON.stringify
  )(query);
  useMemo(() => {
    setParam({ page: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.filter, query.city, query.state, query.zip, query.term]);

  useMemo(() => {
    const getSuppliers = async () => {
      setResults([]);
      setMarkers([]);
      setLoading(true);
      const data = await searchSupplierDatabase(JSON.parse(params));
      const counts = await getSuppliersCount(JSON.parse(params));
      setCount(counts.numberOfRows);
      setTotal(counts.totalCount);
      const result = data.map(parseSupplier);
      setResults(result);
      const marker = await getMarkers(result);
      setMarkers(marker);
      setLoading(false);
    };
    getSuppliers();
  }, [params]);

  return { results, count, total, loading, markers };
};

export default useSuppliers;
