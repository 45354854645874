import React from "react";
import HomePage from "./components/home-page";
import SearchDetails from "./components/search-details";
import DetailPage from "./components/search-details-component";
import RegisterPage from "./components/register-page";
import SupplierPage from "./components/supplier-page";
import Verification from "./components/verification";
import "./App.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { theme } from "./constants/theme";
import { QueryParamProvider } from "use-query-params";
import useScript from "react-script-hook";


import { HashRouter as Router, Switch, Route } from "react-router-dom";

const App = () => {
  const muiTheme = createMuiTheme(theme.muiTheme);
  const [loading] = useScript({
    src:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBSD_oORySGHMRyML6ZeOfJN6cj4JsyElY&libraries=places",
  });

  return (
    <MuiThemeProvider theme={muiTheme}>
      {!loading && (
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route exact path="/search-details/:id">
                <DetailPage />
              </Route>
              <Route exact path="/search-supplier/:term?">
                <SearchDetails />
              </Route>
              <Route exact path="/register-page/">
                <RegisterPage />
              </Route>
              <Route path="/supplier-page/">
                <SupplierPage />
              </Route>
              <Route path="/verification/">
		        <Verification />
		      </Route>
            </Switch>
          </QueryParamProvider>
        </Router>
      )}
    </MuiThemeProvider>
  );
};

export default App;
