import { css } from '@emotion/core';

const LoadingScreenStyles = css `

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: 'rgba(255, 255, 255, 0.7)';
  z-index: 7;
}

.circularProgress {
  position: absolute;
  top: 50%;
  left: 50%;
}

  `

export default LoadingScreenStyles;
