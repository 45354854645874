/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import {useState, useEffect} from 'react'
import {organizationDetails, getOrgLogo} from "../../../constants/config"
import { Dialog, Box, Grid, Typography, ButtonBase } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import LoadingScreen from '../shared/loading-screen';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ContactCardStyles from './style'
import PersonIcon from '@material-ui/icons/Person';

const ContactCard = (props) => {
    const { setOrg, org } = props
    const [info, setInfo] = useState({id:"",organization:"", first_name:"",last_name:"", telephone:"", ext:"", email:""})
    
    console.info('==========');
    console.info(org);
    
    useEffect(() => {
        if(org !== undefined){
            let details = {
        		organization: org.orgName,
        		conName: org.conName,
        		conPhone: org.conPhone,
        		conEmail: org.conEmail
            };
            
            setInfo(details)
        }
    }, [org])
    const handleClose = ()=>{
        setOrg(undefined)
    }
    return (
        <Dialog maxWidth={"md"} fullWidth={true} onClose={handleClose} open={(org !== undefined)}>
            <IconButton aria-label="close" style={{position:'absolute', right: 1, top: 1, color: 'lightgrey'}} onClick={()=>handleClose()}>
                <CloseIcon />
            </IconButton>
            <Box style={{position:'absolute', left: 15, top: 15}}>
                <img src={getOrgLogo(org)} height="50" width="64" alt="Logo" style={{objectFit: 'cover'}} onClick={()=>{setOrg(info.organization);console.log(info.organization)}} />
            </Box>
            <div css={ContactCardStyles}>
                <Box classes={{root:'mainContainer'}}>
                    <Box classes={{root: 'detailContainer'}}>
                        <Box classes={{root: 'headerComponent'}}>
                            
                            <Typography classes={{root: 'header'}}>{info.organization}</Typography>
                        </Box>
                        <Box classes={{root:'businessInfo'}}>
                            <Typography classes={{root: 'header1'}}>
                                Contact Information
                            </Typography>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid container item xs={6} spacing={2}>
                                        <Box classes={{root: 'contactData'}}>
                                            <Typography classes={{root: 'contactName'}}> {info.conName} </Typography>
                                        <Box classes={{root: 'websiteComponent'}}>
                                            <PhoneIcon/>
                                            <Typography classes = {{root: 'returnList'}}>{info.conPhone} </Typography>
                                        </Box>
                                        <Box classes={{root: 'websiteComponent'}}>
                                            <EmailIcon/>
                                            <ButtonBase href={`mailto:${info.conEmail}`}>
                                                <Typography classes={{root: 'returnList'}}>{info.conEmail}</Typography>
                                            </ButtonBase>
                                        </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                </Box>
                        </Box>
                        
                    </Box>
                </Box> 
            </div> 
        </Dialog>
    )
}

export default ContactCard
