import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { stepMessages } from '../shared/register-data';
import splashLogo from '../../assets/Splash_Logo.png';
import SPGlobals from './globals';

import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';


export class Login extends Component
{
	showLoginError = e => {
		document.querySelector("#error").style.display = "block";
		
		let loginButton = document.querySelector('#login-btn')
    	
    	loginButton.disabled = false;
    	loginButton.innerText = 'Sign In';
    };
	
	login = e => {
        //document.getElementById("authProcess").style.display = "block";
        //document.getElementById("error").style.display = "none";
        //document.getElementById("password_block").style.display = "none";
        
    	const access_key 
    		= document.getElementById("outlined-email-input").value + document.getElementById("outlined-password-input").value;
    	
    	BpWebSocket.send({
			call: 'authorize',
			payload: {
				pk: access_key
			}
		});
    	
    	let loginButton = document.querySelector('#login-btn')
    	
    	loginButton.disabled = true;
    	loginButton.innerText = 'Processing ...';
    };
    
    loginByEnterKey = e => {
    	if (e.keyCode != 13) { 
    		return false;
    	}
    	
    	this.login(e);
    };
    
    render() {
    	SPGlobals.items.LoginPage = this;

	    /*if(stepMessages.login === 'fail') {
	        document.getElementById("authProcess").style.display = "none";
	        document.getElementById("error").style.display = "block";
	        document.getElementById("password_block").style.display = "block";
	    }/**/
	
	    const imgSplashLogo = {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		};
		
	    const layout = {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	        paddingTop: '45px',
	    	
	    };
	    const headerBlocks = {
	        marginBottom: '8px',
	    }
	    const paper = {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	    }
	    const button = {
	        marginBottom: '12px',
	        padding: '14px',
	        width: '100%'
	    }
	    const field = {
	        width: '100%',
	    }
	
	    const authProcess = {
	        display: 'none',
	    }
	
	    const authFailed = {
	        display: 'none',
	    }
	
	    const errorVisible = {
	        display: 'none',
	        color: 'red',
	        marginTop: '5px',
	        marginBottom: '12px',
	    };
	
	    return (
	        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
		      <img style={imgSplashLogo} src={splashLogo} />
		    </div>
	        <main style={layout}>
	        <Paper style={paper}>
	            <div id="password_block">
	              <form noValidate autoComplete="off">
	                <TextField
	                	onKeyDown={this.loginByEnterKey}
	                    style={field}
	                    id="outlined-email-input"
	                    name="outlined-email-input"
	                    label="Enter Your E-mail"
	                    variant="outlined"
	                />
	                <br /><br />
	                
	                <TextField
	                	onKeyDown={this.loginByEnterKey}
	                	type="password"
		                style={field}
		                id="outlined-password-input"
		                name="outlined-password-input"
		                label="Enter Your Password"
		                variant="outlined"
			        />
			        <br />
	                
			        <Typography id="error" style={errorVisible} component="span" variant="caption" align="center">
		              Incorrect e-mail or password.
	                </Typography>
	              
	                <Typography component="span" variant="caption" align="left">
		              {Lang().SP.login.preamble}
	                </Typography>
	                
	                <div style={{marginTop: '39px', textAlign: 'center'}}>
	                  <Button id="login-btn" variant="contained" color="primary" style={button} onClick={this.login}>
		            	{Lang().SP.login.openLabel}
		              </Button>
	                </div>
	                
	                <div style={{marginTop: '27px', textAlign: 'center'}}>
	                  <Link href="/#/verification/email" color="inherit">
		                Forgot Password?
		              </Link>
	                </div>
	                
	               </form> 
	             </div>
	             
	            <div style={errorVisible}>{Lang().register.authFail}</div>
	            
	            <Typography component="h2" variant="h4" align="center" id="authProcess" style={authProcess}>
	              {Lang().register.auth}
	            </Typography>
	            
	        </Paper>
	        </main>
	        </React.Fragment>
	    );

    }
}

export default Login;