/** @jsx jsx */
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import { jsx } from "@emotion/core";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getGeocode } from "use-places-autocomplete";

const SearchResultRow = ({ main_text, secondary_text, place_id, onClick }) => {
  // const { Icon, name, slug } = row;
  // const buttonRef = useRef(null);
  // const current = buttonRef && buttonRef.current;
  // const history = useHistory();
  // useEffect(() => {
  //   if (active && current) {
  //     current.focus();
  //   }
  // }, [active, current]);

  // const onClick = async () => {
  //   const val = await getGeocode();
  //   // history.push(`/search-supplier/${term}?filter=${slug}`);
  // };
  // const onKeyPress = ({ keyCode }) => {
  //   if (keyCode === 40) {
  //     moveUp();
  //   } else if (keyCode === 38) {
  //     moveDown();
  //   } else if (keyCode === 13) {
  //     onClick();
  //   }
  // };
  return (
    <ListItem
      button
      // ref={buttonRef}
      // onKeyDown={onKeyPress}
      // tabIndex="-1"
      onClick={() => {
        onClick(place_id);
      }}
    >
      {/* {Icon && (
        <ListItemAvatar>
          <Avatar>
            <Icon />
          </Avatar>
        </ListItemAvatar>
      )} */}
      <ListItemText primary={main_text} secondary={secondary_text} />
    </ListItem>
  );
};

export default SearchResultRow;
