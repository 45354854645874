/* English language labels
 */

const English = {
	symbol: 'EN',
	menu: {
	  aboutUs: 'About',
	  covidHelp: 'COVID-19 support',
	  newsletter: 'Newsletter',
	  contactUs: 'Contact us',
	},
	headings: {
	  homeMain: 'COVID-19 Indigenous Supplier Database',
	  slogan: 'Mobilizing the Indigenous supply chain',
	  about: "In response to the COVID-19 pandemic, a group of Indigenous Organizations have formed the Indigenous Business COVID-19 Taskforce and created the largest database of verified Indigenous businesses that can supply Personal Protective Equipment (PPE) and other medical supplies and services to help combat COVID-19.",
	  registerUrlLabel: 'To register your business, please click',
	  //registerUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSddiwfiwUB5MBySs7m7cwggFE6lcor9kO-lnZptui6Oh4oUVQ/viewform'
	  registerUrl: 'http://taskforce.dev.cbs-inc.ca:2221/#/register-page/'
	},
	buttons: {
	  homePageSearch: 'Search',
	  filterByBusinessName: 'Business Name',
	  filterByOrganization: 'Organization',
	  filterByService: 'Service',
	  filterByProducts: 'Product',
	  filterByCovidService: 'COVID-19 Product',
	  filterByCovidProduct: 'COVID-19 Service'
	},
	fields: {
	  searchSelect: 'Business Name, Service, or Product',
	  placesSelect: 'Province, City, Postal Code'
	},
	labels: {
	  filterBy: 'Filter By',
	  resultsFor: 'Results for'
	},
	SP: {
		emailPasswordReset: {
			preamble: 'In order to reset your password, please enter your email address that was registered and associated with a Supplier profile',
		},
		emailVerification: {
			preamble: 'Enter the code that was sent to your E-Mail to set your password and access your profile',
		},
		login: {
			openLabel: 'Sign In',
			preamble: 'Enter your E-Mail and Password to sign in your Supplier Profile',
		},
		basic: {
			companyOverview: 'Company Overview',
			companyAddress: 'Company Address',
		},
		marketing: {
			socialLinks: 'Update your social pages links',
			websiteFieldLabel: 'Main website',
		},
		twoFactorLogin: {
			preamble: 'Choose one of the contact methods above to proceed with two-factor login.',
			enterCode: 'Enter the verification code in the field in order to proceed to your profile.',
			codeError: 'Error: wrong code.'
		}
	},
	register: {
		update: 'Update',
		close: 'Close',
		init: 'Initializing software portal ...',
		initFail: 'Initialization failed!',
		auth: 'Authorization!',
		authFail: 'Authorization failed!',
		sync: 'Synchronization',
		syncFail: 'Synchronization failed',
		indigenousSupplier: 'Indigenous Supplier details submission',
		governmentCanadaHeader: 'The Government of Canada is currently the largest public buyer of goods and services in Canada, purchasing about $22 billion worth every year.',
		councilAdvancementHeader: 'The Council for the Advancement of Native Development Officers (Cando) is currently part of an initiative from various national Indigenous organizations, to be part of the response against the COVID-19 pandemic.',
		membersThereforeHeader: 'Our members are therefore invited to be part of a comprehensive and searchable Indigenous Business database to be used by federal government departments that are looking for prospective suppliers in all sectors of economic activity.',
		fillingAttachedHeader: 'By filling the attached Google form, you will be registered as a supplier. This will enable government buyers to contact you regarding valuable procurement opportunities and position your business for economic growth.',
		registrarsName: "Registrar's Name",
		organization: 'What National Indigenous Organization contacted you?',
		cando: "CANDO",
		itac: "ITAC",
		nacca: "NACCA",
		psab: "PSAB",
		other: "Other",
		supplier: "I agree to have my company's information included in the supplier database.",
		yes: "yes",
		no: "no",
		requiredQuestion: 'This is a required question',
		next: 'Next',
		back: 'Back',
		companyInformationHeader: 'Company Information',
		companyname: 'Company Name',
		address: 'Address',
		city: 'City',
		province: 'Province',
		postalcode: 'Postal Code (e.g. Z9Z 9Z9)',
		email: 'Email',
		phonenumber: 'Phone Number (e.g. 123-456-7890)',
		mobile: 'Mobile: (e.g. 514-123-4567)',
		faxnumber: 'Fax Number (e.g. 123-456-7890)',
		description: 'Company Description (example formats: consulting, transportation, safety training, etc.)',
		products: 'What products and services does your company offer?',
		website: 'Company Website URL',
		facebook: 'Facebook Page URL',
		twitter: 'Twitter URL',
		LinkedIn: 'LinkedIn URL',
		other: 'Other',
		yourAnswer: 'Your answer',
		primaryContactDetailsHeader: 'Primary Contact Details',
		firstname: 'First Name',
		lastname: 'Last Name',
		title: 'Title (example formats: CEO, CFO, President, etc.)',
		phonenumber3: 'Phone Number (e.g. 123-456-7890)',
		extension: 'Extension',
		email3: 'Email address',
		findMoreHeader: 'Find out more – click here.',
		canadaLookingHeader: 'Canada is looking for Indigenous suppliers to help combat the Coronavirus disease COVID-19.',
		responsecovid: "Are you a business that can supply products and services in support of Canada’s response to COVID-19?",
		canadasSuppliers: "Canada's call for Suppliers",
		products_supply: 'Which of the following PRODUCTS can you supply?',
		products_supply_other: 'Other Products',
		services_supply: 'Can your company provide the following services?',
		services_supply_other: 'Other Services',
		comments_supply: 'Comments:',
		canadaSuppliersHeader: "Canada's call for Suppliers - retooling capabilities.",
		business_retool: "Can your business retool to manufacture products or services in support of Canada’s response to COVID-19?",
		retoolingHeader: 'Retooling',
		products_retool: 'Which of the following PRODUCTS 	can your company retool to supply?',
		products_retool_other: 'Other Products',
		services_retool: 'Which of the following SERVICES can your company retool to supply?',
		services_retool_other: 'Other Services',
		comments_retool: 'Comments:',
		сompanyNonCovidHeader: 'Company Products and Services',
		evenBusiness: "Please describe your Products and Services in the fields below correspondingly. Separate each service and product with commas. E.g. Transportation, Cleaning, Roofing, Plumbing, etc.",
		noncovid_products: "What Products does your company offer?",
		noncovid_services: "What Services does your company offer?",
		noncvd_supply_comments: 'Additional comments:',
		сandosFreeHeader: "Cando's Free One-year Subscription",
		registeringDatabaseHeader: "Registering to this database makes you eligible for a FREE one-year membership to the National Indigenous Organization Cando (The Council for the Advancement of Native Development Officers).",
		candoMembership: "A Cando membership is an investment in Indigenous Economic Development, as it serves hundreds of EDOs and business leaders across Canada. To be specific, this membership entails opportunities such as access to networking events, webinars, special pricing on conferences, exclusive press releases and the chance to win many prizes when you subscribe to their events. Furthermore, Cando has selected a variety of tools and links from a wide range of Indigenous economic development literatures designed to enable members to keep track of the most current and relevant trends in Indigenous Economic Development.",
		memberofcando: 'Would you like to be a member of Cando?',
		already: "I'm already a member",
		submit: "Submit",
	}
};

export default English;