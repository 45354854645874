import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { stepMessages } from '../shared/register-data';
import splashLogo from '../../assets/Splash_Logo.png';
import SVGlobals from './globals';

import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';


export class InitSupplierPassword extends Component
{
	showPasswordError = e => {
		document.querySelector("#error").style.display = 'block';
    };
	
	setNewPassword = e => {
		e.preventDefault()
		
		let pass        = document.getElementById("outlined-pass-input").value;
    	let pass_repeat = document.getElementById("outlined-passrepeat-input").value;
    	let request     = SVGlobals.items.Verification.parseRequest();
    	
    	if (pass.length < 5
			|| pass != pass_repeat
		) {
    		this.showPasswordError(e);
    		return false;
    	}
    	
    	document.querySelector('#setpass-btn').disabled = true;
		document.querySelector('#setpass-btn').innerText = 'Processing ...';
    	
    	BpWebSocket.send({
			call: 'setSelfPassword',
			payload: {
				passKey: SVGlobals.items.passKey,
				email: request.one,
				password: pass
			}
		});
    	
    	window.open(request.domain + 'supplier-page/uid:' + SVGlobals.items.passKey);
    	
    	document.querySelector('#new-password-form').style.display = 'none';
    	document.querySelector('#new-password-set').style.display = 'block';
    };

    render() {
    	SVGlobals.items.InitSupplierPassword = this;
	
	    const imgSplashLogo = {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		};
		
	    const layout = {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	        paddingTop: '45px',
	    	
	    };
	    const headerBlocks = {
	        marginBottom: '8px',
	    }
	    const paper = {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	    }
	    const button = {
	        marginBottom: '12px',
	        padding: '14px',
	        width: '100%'
	    }
	    const field = {
	        width: '100%',
	    }
	
	    const errorVisible = {
	        display: 'none',
	        color: 'red',
	        marginTop: '5px',
	        marginBottom: '12px',
	        marginLeft: '21px'
	    };
	
	    return (
	        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
		      <img style={imgSplashLogo} src={splashLogo} />
		    </div>
	        <main style={layout}>
	        <Paper style={paper}>
	          <div id="new-password-set" style={{display: 'none'}}>
	            <Typography component="span" variant="caption" align="left">
	              Your password is successfully set. You can close this tab now. 
	            </Typography>
	          </div>
	          <div id="new-password-form">
	            <div>
	              <form noValidate autoComplete="off" onSubmit={this.setNewPassword}>
	                
	                <TextField
	                	type="password"
	                    style={field}
	                    id="outlined-pass-input"
	                    name="outlined-pass-input"
	                    label="Enter Your New Password"
	                    variant="outlined"
	                />
	                <br /><br />
	                
	                <TextField
	                	type="password"
		                style={field}
		                id="outlined-passrepeat-input"
		                name="outlined-passrepeat-input"
		                label="Repeat Your New Password"
		                variant="outlined"
			        />
			        <br />
	              
	                <Typography component="span" variant="caption" align="left">
		              {Lang().SP.login.preamble}
	                </Typography>
	                
	                <div style={{marginTop: '39px', textAlign: 'center'}}>
	                  <Button id="setpass-btn" type="submit" variant="contained" color="primary" style={button}>
		            	Set New Password
		              </Button>
	                </div>
	                
	               </form> 
	             </div>
	             
	            <Typography id="error" style={errorVisible} component="span" variant="caption" align="left">
	              Password error: <br />
	               - passwords must match <br />
	               - password must be longer than 5 symbols
                </Typography>
	          </div>
	               
	        </Paper>
	        </main>
	        </React.Fragment>
	    );

    }
}

export default InitSupplierPassword;