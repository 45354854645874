/** @jsx jsx */
import { jsx } from "@emotion/core";
import { PlacesSelectResultsContainer } from "./styles";
import { List, Paper } from "@material-ui/core";

import PlacesResultRow from "./places-result-row";
import {
  getPlaceDetails,
  getLocation,
} from "../../../../../services/map.utils";
import { useQueryParams, NumberParam, StringParam } from "use-query-params";
import { useHistory } from "react-router-dom";

const PlacesSelectResults = ({
  term,
  results,
  activeItem,
  setActiveItem,
  setValue,
  clearSuggestions,
  selected,
  setSelected,
  setLocation
}) => {
  const [, setQuery] = useQueryParams({
    zip: StringParam,
    city: StringParam,
    state: StringParam,
    name: StringParam,
  });
  const history = useHistory();

  const onClick = async (place_id) => {
    try {
      const data = await getPlaceDetails(place_id);
      setValue(data.name);
      const location = getLocation(data);
      setLocation(location);
      setQuery(location);
      history.push({
        pathname: `/search-supplier/${term}`,
        search: history.location.search,
      });
      clearSuggestions();
      setSelected(true);
    } catch {}
  };

  return results.length && !selected ? (
    <PlacesSelectResultsContainer>
      <Paper elevation={0}>
        <List dense>
          {results.map((row, idx) => (
            <PlacesResultRow
              key={row.place_id}
              place_id={row.place_id}
              {...row.structured_formatting}
              onClick={onClick}
            />
          ))}
        </List>
      </Paper>
    </PlacesSelectResultsContainer>
  ) : null;
};

export default PlacesSelectResults;
