/** @jsx jsx */
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import { jsx } from "@emotion/core";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQueryParam, ArrayParam } from "use-query-params";

const SearchResultRow = ({
  row,
  result,
  active,
  moveUp,
  moveDown,
  term,
  setSearchFilter,
}) => {
  const { Icon, name, slug } = row;
  const buttonRef = useRef(null);
  const current = buttonRef && buttonRef.current;
  const [, setFilters] = useQueryParam("filter", ArrayParam);
  useEffect(() => {
    if (active && current) {
      current.focus();
    }
  }, [active, current]);

  const history = useHistory();

  const onClick = () => {
    setFilters([slug]);
    history.push({
      pathname: `/search-supplier/${term}`,
      search: history.location.search,
    });
  };
  const onKeyPress = ({ keyCode }) => {
    if (keyCode === 40) {
      moveUp();
    } else if (keyCode === 38) {
      moveDown();
    } else if (keyCode === 13) {
      onClick();
    }
  };
  return (
    <ListItem
      button
      ref={buttonRef}
      onKeyDown={onKeyPress}
      tabIndex="-1"
      onClick={onClick}
    >
      {Icon && (
        <ListItemAvatar>
          <Avatar>
            <Icon />
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText primary={name} secondary={result && `${result} results`} />
    </ListItem>
  );
};

export default SearchResultRow;
