/*global google*/
import * as R from "ramda";

export const reverseGeocode = (location) =>
  new Promise(async (resolve) => {
    try {
      const service = new google.maps.Geocoder();
      service.geocode(location, (results) => {
        resolve(results);
      });
    } catch (error) {
      resolve(null);
    }
  });

export const getSupplierLocations = async (suppliers) => {
  const service = new google.maps.DirectionsService();
  const addresses = suppliers.map(
    (item) =>
      `${item.address1}, ${item.city}, ${item.province}, ${item.postal_code}`
  );
  const origin = R.head(addresses);
  const destination = R.last(addresses);
  const waypoints = R.pipe(R.init, R.tail)(addresses);
  const request = R.applySpec({ origin, destination, waypoints });
  const data = await service.route(request);
  console.log(data);
};

export const getPixelPositionOffset = (x, y) => {
  return {
    x: -x / 2,
    y: -y - 40,
  };
};
