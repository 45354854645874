/** @jsx jsx */
import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import NullPage from './NullPage';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourthPage from './FourthPage';
import FifthPage from './FifthPage';
import SixPage from './SixPage';
import SevenPage from './SevenPage';
import EightPage from './EightPage';
import NinePage from './NinePage';
import Submit from './Submit';

export class RegisterPage extends Component {
    state = {
        step: 1,
        indigenousBusiness: '',
        registrasName: '',
        organization: '',
        supplier: '',
        companyname: '',
        address: '',
        city: '',
        province: '',
        postalcode: '',
        email: '',
        phonenumber: '',
        tollfree: '',
        faxnumber: '',
        description: '',
        products: '',
        website: '',
        facebook: '',
        twitter: '',
        LinkedIn: '',
        other: '',
        firstname: '',
        lastname: '',
        title: '',
        phonenumber3: '',
        extension: '',
        email3: '',
        responsecovid: '',
        business_retool: '',
        noncovid_products: '',
        noncovid_services: '',
        noncvd_supply_comments: '',
        memberofcando: '',
    };

    socketState = '';

    // Proceed to next step
    nextStep = (add = false) => {

        add = add !== false ? add : 1;

        const { step } = this.state;
        this.setState({
            step: step + add
        });
    };

    // Go back to prev step
    prevStep = (add = false) => {

        add = add !== false ? add : 1;

        const { step } = this.state;
        this.setState({
            step: step - add
        });
    };

    // Handle fields change
    handleChange = input => e => {
        if(e.target.type === 'checkbox') {
            this.setState({ [input]: e.target.checked });
        } else {
            this.setState({ [input]: e.target.value });
        }
    };

    render() {
        const { step } = this.state;
        const { indigenousBusiness, registrasName, organization, supplier, companyname, address, city, province, postalcode,
            phonenumber, email, tollfree, faxnumber, description, products, website, facebook, twitter,
            LinkedIn, other, firstname, lastname, title, phonenumber3, extension, email3, responsecovid,
            business_retool, noncovid_products, noncovid_services, noncvd_supply_comments, memberofcando,
        } = this.state;
        const values = { indigenousBusiness, registrasName, organization, supplier, companyname, address, city, province, postalcode,
            phonenumber, email, tollfree, faxnumber, description, products, website, facebook, twitter,
            LinkedIn, other, firstname, lastname, title, phonenumber3, extension, email3, responsecovid,
            business_retool, noncovid_products, noncovid_services, noncvd_supply_comments, memberofcando,
        };

        switch (step) {
            case 1:
                return (
                    <NullPage
                nextStep={this.nextStep}
                />
            );
            case 2:
                return (
                    <FirstPage
                    nextStep={this.nextStep}
                    handleChange={this.handleChange}
                    values={values}
                    />
                );
            case 3:
                return (
                    <SecondPage
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    socketState={this.socketState}
                    values={values}
                    />
                 );
            case 4:
                return (
                    <ThirdPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                socketState={this.socketState}
                values={values}
                />
            );
            case 5:
                return (
                    <FourthPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                socketState={this.socketState}
                values={values}
                />
            );
            case 6:
                return (
                    <FifthPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                socketState={this.socketState}
                values={values}
                />
            );
            case 7:
                return (
                    <SixPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                socketState={this.socketState}
                values={values}
                />
            );
            case 8:
                return (
                    <SevenPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                socketState={this.socketState}
                values={values}
                />
            );
            case 9:
                return (
                    <EightPage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                socketState={this.socketState}
                values={values}
                />
            );
            case 10:
                return (
                    <NinePage
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleChange={this.handleChange}
                socketState={this.socketState}
                values={values}
                />
            );
            case 11:
                return (
                    <Submit
                    values={values}
                    />
                );
            default:
                (console.log('This is a multi-step form built with React.'))
        }
    }
}

export default RegisterPage;