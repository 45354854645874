/**
 * Supplier Verification global use object
 * 
 */
import homeic1 from '../../assets/HomePage_ic1.png';

export const supplierOrg = {
    'HOMEIC1': homeic1,
}

const SVGlobals = {
    items: {}
}

export default SVGlobals;