import * as R from "ramda";
/* global google, document */

export const getPlaceDetails = (placeId) =>
  new Promise(async (resolve) => {
    try {
      const div = document.createElement("div");
      const placesService = new google.maps.places.PlacesService(div);
      placesService.getDetails({ placeId }, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results);
        }
      });
    } catch (error) {
      resolve(null);
    }
  });

export const isType = (type) => R.propSatisfies(R.includes(type), "types");

const getPlaceValue = (type) =>
  R.pipe(
    R.prop("address_components"),
    R.find(isType(type)),
    R.prop("short_name")
  );

export const getLocation = R.cond([
  [
    isType("locality"),
    R.applySpec({
      city: getPlaceValue("locality"),
      state: getPlaceValue("administrative_area_level_1"),
      zip: R.always(undefined),
      name: R.prop("name"),
    }),
  ],
  [
    isType("postal_code"),
    R.applySpec({
      state: getPlaceValue("administrative_area_level_1"),
      city: getPlaceValue("locality"),
      zip: getPlaceValue("postal_code"),
      name: R.prop("name"),
    }),
  ],
  [
    isType("administrative_area_level_1"),
    R.applySpec({
      state: getPlaceValue("administrative_area_level_1"),
      zip: R.always(undefined),
      city: R.always(undefined),
      name: R.prop("name"),
    }),
  ],
  [R.T, () => "asd"],
]);
