import css from '@emotion/css';
import { theme } from '../../../constants/theme';

const ToolBarStyles = css `
.container {
  width: 100%;
  height:  72px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  background-color: ${theme.colors.white};
}
.toolbarcontainer{
  cursor: pointer;
  align-item:center;
}
.textTypography {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  width: 164px;
  margin: 0 auto;
}
.toolbarSearch {
  height: 48px;
  display: flex;
  margin-right: 200px;
  align-item:center;
  justify-content: space-around;
}
`
export default ToolBarStyles;
