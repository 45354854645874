import styled from "@emotion/styled";

export const SearchSelectResultsContainer = styled.div`
  position: absolute;
  top: calc(100% + 6px);
  left: -25px;
  width: calc(100% + 24px);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 1000;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  > span {
    margin-left: 12px;
  }
`;
