import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import splashLogo from '../../assets/Splash_Logo.png';
import SVGlobals from './globals';

import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';

import logoSrc from '../../assets/HomePage_Logo.png';


export class emailCodeConfirm extends Component
{
	showCodeError = e => {
		document.querySelector("#error").style.display = "block";
		
    };
	
    submitCode = (code) => {
    	let request = SVGlobals.items.Verification.parseRequest();
    	
    	BpWebSocket.send({
			call: 'verifyEmailCode',
			payload: {
				supplierId: request.two,
				code: code.toUpperCase()
			}
		});

    };
    
    clearCodeField = () => {
    	document.querySelector('#outlined-code-input').value    = 'locked';
    	document.querySelector('#outlined-code-input').style.color = '#F0F0F0';
    	document.querySelector('#outlined-code-input').disabled = true;
    	
    	setTimeout(
			() => {
				document.querySelector('#outlined-code-input').value    = '';
		    	document.querySelector('#outlined-code-input').disabled = false;
		    	document.querySelector('#outlined-code-input').style.color = '';
		    	
			}, 5000
		)
    }
    
    checkCode = e => {
    	let code = document.querySelector('#outlined-code-input').value;
    	
    	if (code.length != 5) {
    		return false;
    	}
    	
    	this.submitCode(code);
    };
    
    render() {
    	SVGlobals.items.EmailCodeConfirmation = this;

	
	    const imgSplashLogo = {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		};
		
	    const layout = {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	        paddingTop: '12px',
	    	
	    };
	    const headerBlocks = {
	        marginBottom: '8px',
	    }
	    const paper = {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	    }
	    
	    const field = {
	        //width: '100%'
	    }
	
	    const codeText = { 
    		textAlign: 'center',
    		letterSpacing: '18px',
    		fontSize: '45px',
    		textTransform: 'uppercase'
		}
	    
	    const labelCodeText = {
    		
	    }
	    
	    const errorVisible = {
	        display: 'none',
	        color: 'red',
	        marginTop: '5px',
	        marginBottom: '12px',
	    };
	    
	    const form = {
    		width: '84%',
    		margin: 'auto',
    		textAlign: 'center'
	    }
	    
	    const headline = {
			textTransform: 'uppercase',
			color: '#94AFC3',
			fontSize: '45px',
			lineHeight: '54px',
	    }
	    
	    const logoImg = {
			width: '222px'
		}
	    
	    const logoStyle = {
    		textAlign: 'center',
	    }
	    
	    const preambleStyle = {
    		margin: 'auto',
    		width: '340px',
    		paddingTop: '27px',
    		paddingBottom: '45px'
	    }
	    
	    return (
	        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
		      <img style={imgSplashLogo} src={splashLogo} />
		    </div>
	        <main style={layout}>
	        <Paper style={paper}>
	        	<div style={logoStyle}>
	              <img style={logoImg} src={logoSrc} />
	            </div>
	            
	            <Typography style={headline} component="div" variant="caption" align="center">
	            	Indigenous<br />Suppliers Co-op
	            </Typography>
	        	
            	<Typography style={preambleStyle} component="div" variant="caption" align="center">
	              {Lang().SP.emailVerification.preamble}
                </Typography>
                
                <br />
                
	            <div>
	              <form noValidate autoComplete="off" style={form}>
	                <TextField
	                	onKeyUp={this.checkCode}
	                	onKeyDown={(e) =>{
	                        e.target.value = e.target.value.toString().slice(0,5)
	                    }}
	                	inputProps={{min: 0, style: codeText, spellCheck: 'false'}}
	                	InputLabelProps={{ style: labelCodeText }}
	                    style={field}
	                    id="outlined-code-input"
	                    name="outlined-code-input"
	                    label="Enter Your Code Here"
	                    variant="outlined"
	                />
	                
			        <br /><br />
	                
			        <Typography id="error" style={errorVisible} component="span" variant="caption" align="center">
		              Incorrect code. Please try again when unlocked or request another code.
	                </Typography>
		              <br /><br />
		              <br />
	               </form> 
	             </div>
	             
	            <div style={errorVisible}>{Lang().register.authFail}</div>
	            
	        </Paper>
	        </main>
	        </React.Fragment>
	    );

    }
}

export default emailCodeConfirm;