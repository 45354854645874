/** @jsx jsx */
import { jsx } from '@emotion/core'
import English from './en'
import French  from './fr'

const Lang = (props) => {
  var language = English; 
  var href     = document.location.href;
  
  if (href.indexOf('/en/') > 0) {
	  language = English;
  } else if (href.indexOf('/fr/') > 0) {
	  language = French;
  }
	
  return language;
};

export default Lang;
