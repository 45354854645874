import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { stepMessages } from '../shared/register-data';
import splashLogo from '../../assets/Splash_Logo.png';
import SPGlobals from './globals';

import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';


export class TwoFactorLogin extends Component
{
	showCodeError = () => {
		document.querySelector("#error").style.display = "block";
		
		let loginButton = document.querySelector('#two-factor-login')
    	
    	loginButton.disabled = true;
    	loginButton.innerText = 'Please wait ...';
    	
    	setTimeout(
			() => {
				let loginButton = document.querySelector('#two-factor-login')
		    	
		    	loginButton.disabled = false;
		    	loginButton.innerText = 'Login';
		    	document.querySelector("#error").style.display = "none";
			},
			5000
		)
    };
	
    proceedToVerify = e => {
        document.getElementById("code-confirmation").style.display = "block";
        document.getElementById("login-btn").style.display = "block";
        document.getElementById("contact-method-options").style.display = "none";
        document.getElementById("proceed-btn").style.display = "none";
        document.getElementById("two-factor-preamble").style.display = "none";
        document.getElementById("two-factor-entercode").style.display = "block";
        
    	
    	BpWebSocket.send({
			call: 'twoFactorRequest',
			payload: {
				sessionKey: SPGlobals.items.towFactorSessionKey,
				contactMethod: SPGlobals.items.twoFactorContactMethod
			}
		});
    };

    login = e => {
    	let code_value = document.querySelector('#code-input').value
    	if (code_value.length < 5) {
    		this.showCodeError()
    		return false;
    	}
    	
    	document.querySelector('#two-factor-login').innerText = 'Processing ...';
    	
    	BpWebSocket.send({
			call: 'twoFactorAuthorize',
			payload: {
				sessionKey: SPGlobals.items.towFactorSessionKey,
				code: code_value
			}
		});
    };
    
    loginByEnterKey = e => {
    	if (e.keyCode != 13) { 
    		return false;
    	}
    	
    	this.login(e);
    };
    
    render() {
    	SPGlobals.items.TwoFactorLogin = this;
	
	    const imgSplashLogo = {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		};
		
	    const layout = {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	        paddingTop: '45px',
	    	
	    };
	    const headerBlocks = {
	        marginBottom: '8px',
	    }
	    const paper = {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	    }
	    const button = {
	        marginBottom: '12px',
	        padding: '14px',
	        width: '100%'
	    }
	    const field = {
	        //width: '100%',
	    }
	
	    const authProcess = {
	        display: 'none',
	    }
	
	    const authFailed = {
	        display: 'none',
	    }
	
	    const errorVisible = {
	        display: 'none',
	        color: 'red',
	        marginTop: '5px',
	        marginBottom: '12px',
	    };
	    
	    const codeText = { 
    		textAlign: 'center',
    		letterSpacing: '18px',
    		fontSize: '45px',
    		textTransform: 'uppercase'
		};
	
	    return (
	        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
		      <img style={imgSplashLogo} src={splashLogo} />
		    </div>
	        <main style={layout}>
	        <Paper style={paper}>
	            <div>
	              <form noValidate autoComplete="off" onSubmit={(e) => { e.preventDefault() }}>
	              
                    <div id="contact-method-options">
	                  <FormControl>
		                <FormLabel id="contact-method-label">Choose contact method:</FormLabel>
		                <RadioGroup
		                  aria-labelledby="contact-method-label"
		                  name="contact-method-group"
	                	  onChange={(e, value) => {
	                		  SPGlobals.items.twoFactorContactMethod = value;
                		  }}
		                >
		                    <FormControlLabel value={SPGlobals.items.twoFactorContactMethod.email} control={<Radio />} label={"E-mail to: " + SPGlobals.items.twoFactorContactMethod.email} />
		                	<FormControlLabel value={SPGlobals.items.twoFactorContactMethod.phone} control={<Radio />} label={"Text to: " + SPGlobals.items.twoFactorContactMethod.phone} />
		                </RadioGroup>
		              </FormControl>
		            </div>
		            
			        <div id="code-confirmation" style={{display: 'none'}}>
				        <TextField
				        	onKeyDown={this.loginByEnterKey}
		                	inputProps={{min: 0, style: codeText, spellCheck: 'false'}}
		                    style={field}
		                    id="code-input"
		                    name="outlined-code-input"
		                    label="Enter Your Code Here"
		                    variant="outlined"
		                />
			        </div>
	              
	                <Typography id="two-factor-preamble" component="span" variant="caption" align="left">
		              {Lang().SP.twoFactorLogin.preamble}
	                </Typography>
	                <Typography id="two-factor-entercode" style={{display: 'none'}} component="span" variant="caption" align="left">
		              {Lang().SP.twoFactorLogin.enterCode}
	                </Typography>
	                
	                
	                <div id="proceed-btn" style={{marginTop: '39px', textAlign: 'center'}}>
	                  <Button id="two-factor-proceed" variant="contained" color="primary" style={button} onClick={this.proceedToVerify}>
		            	Proceed
		              </Button>
	                </div>
	                <div id="login-btn" style={{marginTop: '39px', textAlign: 'center', display: 'none'}}>
	                  <Button id="two-factor-login" variant="contained" color="primary" style={button} onClick={this.login}>
		            	Login
		              </Button>
	                </div>
	                
	               </form> 
	             </div>
	             
	            <div id="error" style={errorVisible}>{Lang().SP.twoFactorLogin.codeError}</div>
	            
	        </Paper>
	        </main>
	        </React.Fragment>
	    );

    }
}

export default TwoFactorLogin;