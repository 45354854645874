import css from '@emotion/css';
import { theme } from '../../../constants/theme';

const ToolBarStyles = css `

@media (max-width:800px) {

.container {
  height: auto !important;
  min-height:150px !important;
      width: 100% !important;
      flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
float: left !important;
padding: 10px 0px !important;
margin:0px !important;
justify-content: center !important;
  background-color:  ${theme.colors.white};
box-shadow: none;
}


.toolbarcontainer{
  /* margin-left: 156px; */
  cursor: pointer;
  align-item:center;
}
.textTypography {
  font-size: 18px;
  font-weight: bold;
  width: 164px;
  margin: 0 auto;
  cursor: pointer;
  display: none;
}


/*.toolbarSearch {
 width: 100% !important;
  height: auto !important;
  border-radius: 0px;
  flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
justify-content: center !important;
padding:0px 0px 20px 0px !important;
margin: 0px !important;
	box-shadow: none !important;
	background-color: rgb(246, 246, 246) !important;
	border-bottom: #CCCCCC 1px solid;

}*/
.toolbarSearch{
 width: 100% !important;
  height: auto !important;
  border-radius: 0px;
  flex-direction: column !important;
flex-wrap: wrap !important;
display: flex !important;
position: relative !important;
justify-content: center !important;
padding:0px !important;
margin: 0px !important;
	box-shadow: none !important;
	background-color:  none !important;
	border-bottom: none !important;

}

}

/*DESKTOP*/

/*.container {
  width: 100%;
  height:  72px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: ${theme.colors.white};
}*/

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.grey};
}
.css-1kw2abh{

}

.toolbarcontainer{
  /* margin-left: 156px; */
  cursor: pointer;
  align-item:center;
  width:100%;

}
.textTypography {
  font-size: 18px;
  font-weight: bold;
  width: 164px;
  margin: 0 auto;
  cursor: pointer;
  display: none;
}
.toolbarSearch {
padding:20px 0px;
width: 100%;
  height: 48px;
  display: flex;
  align-item:center;
  justify-content: center;
}
`
export default ToolBarStyles;