import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { selectorsMetadata, supplierData, stepMessages } from '../shared/register-data';
import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: '8px',
        marginLeft: '8px',
    },
    paper: {
        marginTop: '18px',
        marginBottom: '18px',
        padding: '28px',
    },
    layout: {
        width: '680px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headerName: {
        paddingBottom: '8px',
        marginBottom: '26px',
        marginTop: '12px',
        paddingTop: '8px',
        paddingLeft: '8px',
        backgroundColor: '#868B8E',
        color: '#FFFFFF',
    },
    headerMargins: {
        marginBottom: '12px',
    },
    headerBlocks: {
        marginBottom: '22px',
    },
    titleBlocks: {
        marginBottom: '8px',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function SupplyProfile() {

    const products_supply = selectorsMetadata.products_supply;
    const services_supply = selectorsMetadata.services_supply;

    const classes = useStyles();
    const theme = useTheme();

    const [products, setProducts] = React.useState(supplierData.products_supply);
    const [services, setServices] = React.useState(supplierData.services_supply);

    const handleChange = (event) => {
        const name = event.target.name;
        supplierData[name] = event.target.value;
    };
    
    const CSS = {
		field: {
	    	marginLeft: '7px',
	        marginRight: '7px',
	        width: '100%'	
		}
    }
    
    const enable_updateBtn = () => {
        let updateButton = document.querySelector('#update-btn')
    	updateButton.disabled = false;
        updateButton.innerText = 'Update';
    }
    
    const disable_updateBtn = () => {
        let updateButton = document.querySelector('#update-btn')
    	updateButton.disabled = true;
        updateButton.innerText = 'Updated';
    }
    
    const handleChangeProducts = (event) => {
        setProducts(event.target.value);
        supplierData.products_supply = event.target.value;
        
        enable_updateBtn();
    };

    const handleChangeServices = (event) => {
        setServices(event.target.value);
        supplierData.services_supply = event.target.value;
        
        enable_updateBtn();
    };
    
    const updateCompanyDetails = () => {
        BpWebSocket.send({
            call: 'supplierProfileUpdateRequest',
            payload: {
                'id': supplierData.id,
                //'products': supplierData.products_supply.join(', '),
                //'services': supplierData.services_supply.join(', ')
                'products': document.getElementById('products').value,
                'services': document.getElementById('services').value
            }
        });
        
        disable_updateBtn();
    }

    if(stepMessages.syncprof === 'init') {
        BpWebSocket.send({
            call: 'syncSupplyToProfile',
            payload: {
                supplierId: supplierData.id
            }
        });
        return (
            <React.Fragment>
                <Typography component="h2" variant="h4" align="center" id="syncProcess">
                    {Lang().register.sync}
                </Typography>
            </React.Fragment>
        );
    }

    if(stepMessages.syncprof === 'synced') {

        const products = supplierData.products_supply;
        const services = supplierData.services_supply;

        return (
            <React.Fragment>

	            {/*}<Typography variant="body1" component="span" gutterBottom>
	                {Lang().register.products_supply}
	            </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                    <Select
                        labelId="products_supply"
                        id="products_supply"
                        multiple
                        value={products}
						onChange={handleChangeProducts}
                        input={<Input id="products_supply_chip" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                    {products_supply.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, products, theme)}>
                            {name}
                        </MenuItem>
                    ))}
                    </Select>
                     </FormControl>
                    </Grid>
                </Grid>

                <Typography variant="body1" component="span" gutterBottom>
                {Lang().register.services_supply}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                    <Select
                        labelId="services_supply"
                        id="services_supply"
                        multiple
                        value={services}
						onChange={handleChangeServices}
                        input={<Input id="services_supply_chip" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                    {services_supply.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, services, theme)}>
                            {name}
                        </MenuItem>
                    ))}
                    </Select>
                    </FormControl>
                    </Grid>
                </Grid> */}
                
	            <br />
	            
                <TextField onKeyUp={enable_updateBtn} style={CSS.field} id="products" label="List your PRODUCTS separated by comma" defaultValue={products} variant="outlined" 
                	fullWidth
                    multiline
                    rows={5}
                	/>
                <br />
                <br />
                
                <TextField onKeyUp={enable_updateBtn} style={CSS.field} id="services" label="List your SERVICES separated by comma" defaultValue={services} variant="outlined" 
                	fullWidth
                    multiline
                    rows={5}
                	/>
                <br />
                
                {/*
                <TextField onKeyUp={enable_updateBtn} style={CSS.field} id="supply-other" label="Additional supply details, if applicable" defaultValue="" variant="outlined" 
                	fullWidth
                    multiline
                    rows={5}
                	/>*/}
                
                <div style={{marginTop: '39px', textAlign: 'center'}}>
	            	<Button id="update-btn" className={classes.button_update} variant="contained" color="primary" onClick={updateCompanyDetails}>
	                	{Lang().register.update}
	                </Button>
	            </div>

            </React.Fragment>
        );
    }
}