/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import SearchBarStyles from "./styles";
import { Box, Divider, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SearchSelect from "./search-select";
import PlacesSelect from "./places-select";
import queryString from "query-string";
import Lang from '../lang';


const SearchBar = (props) => {
  const { searchTerm, filter, type } = props;
  const [location, setLocation] = useState({});
  const [searchFilter, setSearchFilter] = useState(null);
  const [description, setDescription] = useState("");
  const history = useHistory();

  const sendSearch = () => {
    if(description || history.location.search){
      history.push({
        pathname: `/search-supplier/${description}`,
        search: history.location.search,
      });
    }

  };
  useEffect(() => {

    setDescription(searchTerm);
    setLocation(filter);
  }, [searchTerm, filter]);

  return (
    <div css={SearchBarStyles}>
      <Box classes={{ root: "searchComponent" }}>
        <Box
          classes={
            type === "home"
              ? { root: "homeComponent" }
              : { root: "searchDetailsComponent" }
          }
        >
          
          <SearchSelect
            {...{
              type,
              description,
              setDescription,
              setSearchFilter,
              searchFilter,
              sendSearch,
              withAutocomplete: type === "home",
            }}
          />
          
          <Box>
            <Divider
              classes={
                type === "home"
                  ? { root: "divider" }
                  : { root: "toolbarDivider" }
              }
              orientation="vertical"
            />
          </Box>
          
          <PlacesSelect {...{ type, location, setLocation, sendSearch, description }} />
        </Box>
        
        <Box
          classes={
            type === "home"
              ? { root: "homeButtonComponent" }
              : { root: "searchButtonComponent" }
          }
        >
          <Button
            classes={
              type === "home" ? { root: "Button" } : { root: "toolbarButton" }
            }
            onClick={() => sendSearch()}
          >
          {Lang().buttons.homePageSearch}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default SearchBar;
