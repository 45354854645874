import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Lang from '../shared/lang';
import splashLogo from '../../assets/Splash_Logo.png';

import { supplierData } from '../shared/register-data';
import BpWebSocket from '../shared/bp-websocket';
import SPGlobals from './globals';


const useStyles = makeStyles((theme) => ({
	  root: {
	    '& > *': {
	      margin: theme.spacing(1)
	    },
	  },
})); 	


export default function MarketingPage() {
	const classes = useStyles();
	
	const layout = {
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
    };
    
    const paper = {
        marginTop: '18px',
        marginBottom: '18px',
        padding: '28px',
    }
    
    const field = {
		marginLeft: '7px',
        marginRight: '7px',
        width: '36ch'	
    }
	
    const headerSection = {
        marginBottom: '9px',
    };
    
    const headerPage = {
        marginBottom: '27px',
    };
    
    const enable_updateBtn = () => {
        let updateButton = document.querySelector('#update-btn')
    	updateButton.disabled = false;
        updateButton.innerText = 'Update';
    }
    
    const disable_updateBtn = () => {
        let updateButton = document.querySelector('#update-btn')
    	updateButton.disabled = true;
        updateButton.innerText = 'Updated';
    }    
    
    const updateMarketingDetails = () => {
        BpWebSocket.send({
            call: 'supplierProfileUpdateRequest',
            payload: {
                'id': supplierData.id,
                'website': document.getElementById('main-website-url').value,
                'facebook': document.getElementById('facebook-url').value,
                'twitter': document.getElementById('twitter-url').value,
                'LinkedIn': document.getElementById('linkedin-url').value,
            }
        });
        
        disable_updateBtn();
    }
    
    const CSS = {
		imgSplashLogo: {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		}
    };

	return (
        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
	           <img style={CSS.imgSplashLogo} src={splashLogo} />
	        </div>
	        <main style={layout}>
	        <Paper style={paper}>
	            <Typography style={headerPage} component="h2" variant="h4" align="center" className={classes.titleBlocksHeader}>
                 Marketing and PR management
                </Typography>
	           
	            <form className={classes.root} noValidate autoComplete="off">
	                <Typography variant="body1" gutterBottom style={headerSection}>
		             {Lang().SP.marketing.socialLinks}
		            </Typography>
	            
	            	<TextField onKeyUp={enable_updateBtn} style={field} id="facebook-url" label="Facebook" defaultValue={supplierData.facebook} variant="outlined" />
	            	<TextField onKeyUp={enable_updateBtn} style={field} id="twitter-url" label="Twitter" defaultValue={supplierData.twitter} variant="outlined" />
	            	<br />
	            	<TextField onKeyUp={enable_updateBtn} style={field} id="linkedin-url" label="LinkedIn" defaultValue={supplierData.LinkedIn} variant="outlined" />
	            	
	            	<div style={{height: '12px'}}></div>
	            	
	            	<Typography variant="body1" gutterBottom style={headerSection}>
		             {Lang().SP.marketing.websiteFieldLabel}
		            </Typography>
	            	
		            <TextField onKeyUp={enable_updateBtn} style={field} id="main-website-url" label="URL" defaultValue={supplierData.website} variant="outlined" />
		            
		            <div style={{marginTop: '39px', textAlign: 'center'}}>
		            	<Button id="update-btn" variant="contained" color="primary" className={classes.button_update} onClick={updateMarketingDetails}>
	                    	{Lang().register.update}
	                    </Button>
                    </div>
	            </form>    
	        </Paper>
	        </main>
        
        </React.Fragment>
    );
}