import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Menu,
} from "@material-ui/core";

// import Button from '@material-ui/core/Button';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

import { supplierData } from '../shared/register-data';
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import SPGlobals from './globals';

const headersData = [
  {
    label: "Home",
    href: "/Home",
  },
  {
    label: "Basic",
    href: "/Basic",
  },
  {
    label: "Business",
    href: "/Business",
  },
  {
    label: "Marketing",
    href: "/Marketing",
  },
  {
    label: "Contacts",
    href: "/Contacts",
  },
 /* {
    label: "Certifications",
    href: "/Certifications",
  },
  {
    label: "Requests",
    href: "/Requests",
  },
  {
    label: "Documents",
    href: "/Documents",
  },*/
];

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#FFF",
    color: 'black',
    paddingLeft: "118px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  userMenu: {
	color: 'black',
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "black",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  menuButtonSelected: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
    backgroundColor: 'lightgrey'
  },
  toolbar: {
    display: "flex",
  },
  toolbarMobile: {
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
  logButton: {
    color: "white",
    fontWeight: "bold",
    fontSize: "larger",
  },
  middleBlockMenu: {
    marginLeft: "auto",
  },

}));

export default function Header() {
  const { header, logo, menuButton, menuButtonSelected, toolbar, toolbarMobile, drawerContainer, logButton, middleBlockMenu } = useStyles();

  const [state, setState] = React.useState({
    mobileView: false,
    drawerOpen: false,
  });
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    SPGlobals.items.SupplierPage.showScreen('login');
  };
  
  const openSettings = () => {
	setAnchorEl(null);
	SPGlobals.items.SupplierPage.showScreen('Settings');
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const openResetPassword = () => {
	  document.location.href = '/#/verification/email'
  }

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div className={middleBlockMenu}>{getMenuButtons()}</div>
        <div>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={logButton}><span style={{color: 'black'}}>. . .</span></Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={openResetPassword}>Reset Password</MenuItem>
            <MenuItem onClick={openSettings}>Settings</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
	  
	  
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={toolbarMobile}>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <div>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={logButton}>. . .</Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          	//className={userMenu}
          >
            {/*<MenuItem>My account</MenuItem>*/}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    const handleDrawerClose = () =>
      	setState((prevState) => ({ ...prevState, drawerOpen: false }));
	  
    return headersData.map(({ label, href }) => {
      return (
        <Link
        onClick={() => { SPGlobals.items.SupplierPage.showScreen(label + '_Page'); handleDrawerClose(); }}
          {...{
            //component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };
  
  
  /*if (supplierData.companyname.length > 0) {
	  const femmecubatorLogo = (
	    <Typography variant="h6" component="h1" className={logo}>
	      Profile: {supplierData.companyname} 
	    </Typography>
	  );
  } else {
	  const femmecubatorLogo = '';
  }/**/
  
  const femmecubatorLogo = (
    <Typography variant="h6" component="h1" className={logo}>
      {supplierData.companyname} 
    </Typography>
  );
  
  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
    	
    	if (SPGlobals.items.currentPage == label + '_Page') {
    		var currentMenuButtonClass = menuButtonSelected;
    	} else {
    		var currentMenuButtonClass = menuButton;
    	}
    	
	    return (
		    <Button
		    	onClick={e => { SPGlobals.items.SupplierPage.showScreen(label + '_Page'); }}
			    {...{
				    key: label,
				    color: "inherit",
				    to: href,
				    //component: RouterLink,
			        className: currentMenuButtonClass,
		        }}
		    >
		      {label}
		    </Button>
	    );

    });
  };

  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
