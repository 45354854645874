import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Lang from '../shared/lang';
import InputLabel from '@material-ui/core/InputLabel';
import splashLogo from '../../assets/Splash_Logo.png';

import { supplierData } from '../shared/register-data';
import BpWebSocket from '../shared/bp-websocket';
import SPGlobals from './globals';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        },
    },
}));

export default function BusinessPage() {
    const classes = useStyles();

    const layout = {
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
    };

    const paper = {
        marginTop: '18px',
        marginBottom: '18px',
        padding: '28px',
    }

    const field = {
        marginLeft: '7px',
        marginRight: '7px',
        width: '36ch'
    }

    const headerSection = {
        marginBottom: '9px',
    };

    const headerPage = {
        marginBottom: '27px',
    };
    const formControl = {
        margin: '3px',
        width: '100%'
    };
    
    const enable_updateBtn = () => {
        let updateButton = document.querySelector('#update-btn')
    	updateButton.disabled = false;
        updateButton.innerText = 'Update';
    }
    
    const disable_updateBtn = () => {
        let updateButton = document.querySelector('#update-btn')
    	updateButton.disabled = true;
        updateButton.innerText = 'Updated';
    }    
    
    const updateBusinessDetails = () => {
        
    	supplierData.year_founded = document.querySelector('#year_founded').value;
    	supplierData.number_employees = document.querySelector('#number_employees').value;
    	supplierData.percent_indigenous_employees = document.querySelector('#percent_indigenous_employees').value;
    	supplierData.duns_number = document.querySelector('#duns_number').value;
    	supplierData.cra_business_number = document.querySelector('#cra_business_number').value;
    	supplierData.provincial_business_number = document.querySelector('#provincial_business_number').value;
    	supplierData.gst_hst_number = document.querySelector('#gst_hst_number').value;
    	supplierData.qst_number = document.querySelector('#qst_number').value;
    	supplierData.pst_number = document.querySelector('#pst_number').value;
    	supplierData.procurement_business_number = document.querySelector('#procurement_business_number').value;
    	
    	const payload = {
            'id': supplierData.id,
            'indigenous_business': supplierData.indigenous_business,
            'year_founded': supplierData.year_founded,
            'number_employees': supplierData.number_employees,
            'percent_indigenous_employees': supplierData.percent_indigenous_employees,
            'business_legal_form': supplierData.business_legal_form,
            'duns_number': supplierData.duns_number,
            'cra_business_number': supplierData.cra_business_number,
            'tax_free': supplierData.tax_free,
            'provincial_business_number': supplierData.provincial_business_number,
            'gst_hst_number': supplierData.gst_hst_number,
            'qst_number': supplierData.qst_number,
            'pst_number': supplierData.pst_number,
            'procurement_business_number': supplierData.procurement_business_number,
            'payment_method': supplierData.payment_method,
            'indigenous_certification': supplierData.indigenous_certification,
            'quality_certification': supplierData.quality_certification
        };
        
        BpWebSocket.send({
            call: 'supplierProfileUpdateRequest',
            payload: payload
        });
        
        disable_updateBtn();
    }
    
    const CSS = {
		imgSplashLogo: {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		}
    };
    
    const handleChange = { 
		IndigenousBusiness: e => {
			supplierData.indigenous_business = e.target.value;
			enable_updateBtn();
		},
		LegalForm: e => {
			supplierData.business_legal_form = e.target.value;
			enable_updateBtn();
		},
		TaxFree: e => {
			supplierData.tax_free = e.target.value;
			enable_updateBtn();
		},
		PaymentMethod: e => {
			supplierData.payment_method = e.target.value;
			enable_updateBtn();
		},
		IndigenousCert: e => {
			supplierData.indigenous_certification = e.target.value;
			enable_updateBtn();
		},
		QualityCert: e => {
			supplierData.quality_certification = e.target.value;
			enable_updateBtn();
		},
	}
    
    return (
            <React.Fragment>
            <CssBaseline />
            <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
	           <img style={CSS.imgSplashLogo} src={splashLogo} />
	        </div>
            <main style={layout}>
                <Paper style={paper}>
                <Typography style={headerPage} component="h2" variant="h4" align="center" className={classes.titleBlocksHeader}>
                    Business Profile
                </Typography>

                <form className={classes.root} noValidate autoComplete="off">
                    
                	<FormControl style={field} variant="outlined">	
		            	<InputLabel id="indigenous_business-select-label"> Indigenous Business </InputLabel>
		            	<Select id="indigenous_business" labelId="indigenous_business-select-label" defaultValue={supplierData.indigenous_business} label="Indigenous Business"
		            	  onChange={handleChange.IndigenousBusiness}>
			                <MenuItem value="true">Yes</MenuItem>
			                <MenuItem value="false">No</MenuItem>
			            </Select>
		            </FormControl>
                    
                    <TextField onKeyUp={enable_updateBtn} style={field} id="year_founded" label="Year Founded" defaultValue={supplierData.year_founded} variant="outlined" />
                    <br />
                    <TextField onKeyUp={enable_updateBtn} style={field} id="number_employees" label="Number of Employees" defaultValue={supplierData.number_employees} variant="outlined" />
                    <TextField onKeyUp={enable_updateBtn} style={field} id="percent_indigenous_employees" label="% of Indigenous Empoyees" defaultValue={supplierData.percent_indigenous_employees} variant="outlined" />
                    <br />
                    
                    <FormControl style={field} variant="outlined">	
		            	<InputLabel id="business_legal_form-select-label"> Business Legal Form </InputLabel>
		            	<Select id="business_legal_form" labelId="business_legal_form-select-label" defaultValue={supplierData.business_legal_form} label="Business Legal Form"
		            	  onChange={handleChange.LegalForm}>
			                <MenuItem value="Sole_Proprietorship">Sole Proprietorship</MenuItem>
			                <MenuItem value="Partnership">Partnership</MenuItem>
			                <MenuItem value="Corporation">Corporation</MenuItem>
			                <MenuItem value="Joint_Venture">Joint Venture</MenuItem>
			            </Select>
		            </FormControl>
                    <TextField onKeyUp={enable_updateBtn} style={field} id="duns_number" label="D-U-N-S Number" defaultValue={supplierData.duns_number} variant="outlined" />
                    <br />
                    
                    <TextField onKeyUp={enable_updateBtn} style={field} id="cra_business_number" label="CRA Business Number" defaultValue={supplierData.cra_business_number} variant="outlined" />
                    <FormControl style={field} variant="outlined">	
	            		<InputLabel id="tax_free-select-label"> Tax Free (On Reserve Business) </InputLabel>
		            	<Select id="tax_free" labelId="tax_free-select-label" defaultValue={supplierData.tax_free} label="Tax Free (On Reserve Business)"
		            	  onChange={handleChange.TaxFree}>
			                <MenuItem value="true">Yes</MenuItem>
			                <MenuItem value="false">No</MenuItem>
			            </Select>
		            </FormControl>
                    <br />
                    
                    <TextField onKeyUp={enable_updateBtn} style={field} id="provincial_business_number" label="Provincial Business Number" defaultValue={supplierData.provincial_business_number} variant="outlined" />
                    <TextField onKeyUp={enable_updateBtn} style={field} id="gst_hst_number" label="GST/HST Number" defaultValue={supplierData.gst_hst_number} variant="outlined" />
                    <br />
                    
                    <TextField onKeyUp={enable_updateBtn} style={field} id="qst_number" label="QST NUmber" defaultValue={supplierData.qst_number} variant="outlined" />
                    <TextField onKeyUp={enable_updateBtn} style={field} id="pst_number" label="PST Number" defaultValue={supplierData.pst_number} variant="outlined" />
                    
                    <br />
                    <TextField onKeyUp={enable_updateBtn} style={field} id="procurement_business_number" label="Procurement Business Number" defaultValue={supplierData.procurement_business_number} variant="outlined" />
                    <FormControl style={field} variant="outlined">	
	            		<InputLabel id="payment_method-select-label"> Payment Method </InputLabel>
		            	<Select id="payment_method" labelId="payment_method-select-label" defaultValue={supplierData.payment_method} label="Payment Method"
		            	  onChange={handleChange.PaymentMethod}>
			                <MenuItem value="Cash">Cash</MenuItem>
			                <MenuItem value="Checks">Checks</MenuItem>
			                <MenuItem value="Debit_Card">Debit Card</MenuItem>
			                <MenuItem value="Credit_Card">Credit Card</MenuItem>
			                <MenuItem value="Mobile_Payments">Mobile Payments</MenuItem>
			                <MenuItem value="Electronic_Bank_Transfer">Electronic Bank Transfer</MenuItem>
			            </Select>
		            </FormControl>
                    <br />
                    
                    {/*<FormControl style={field} variant="outlined">	
	            		<InputLabel id="indigenous_certification-select-label"> Indigenous Certification </InputLabel>
		            	<Select id="indigenous_certification" labelId="indigenous_certification-select-label" defaultValue={supplierData.indigenous_certification} label="Indigenous Certification"
		            	  onChange={handleChange.IndigenousCert}>
			                <MenuItem value="true">Yes</MenuItem>
			                <MenuItem value="false">No</MenuItem>
			            </Select>
		            </FormControl>
		            <FormControl style={field} variant="outlined">	
	            		<InputLabel id="quality_certification-select-label"> Quality Certification </InputLabel>
		            	<Select id="quality_certification" labelId="quality_certification-select-label" defaultValue={supplierData.quality_certification} label="Quality Certification"
		            	  onChange={handleChange.QualityCert}>
			                <MenuItem value="true">Yes</MenuItem>
			                <MenuItem value="false">No</MenuItem>
			            </Select>
		            </FormControl>*/}
                    <br />

                    <div style={{marginTop: '39px', textAlign: 'center'}}>
                    <Button id="update-btn" variant="contained" color="primary" className={classes.button_update} onClick={updateBusinessDetails}>
                        {Lang().register.update}
                    </Button>
                    </div>
                </form>
                </Paper>
            </main>
            </React.Fragment>
    );
}
