import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import Lang from '../shared/lang';

export class ThirdPage extends Component
{
    rule1 = () => {
        const textValue = document.getElementById('firstname').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    rule2 = () => {
        const textValue = document.getElementById('lastname').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    rule3 = () => {
        const textValue = document.getElementById('title').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    rule4 = () => {
        const textValue = document.getElementById('phonenumber3').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }
    rule5 = () => {
        const textValue = document.getElementById('email3').value;
        if(textValue === '') {
            return false;
        }
        return true;
    }

    continue = e => {
        const rule1 = this.rule1();
        const rule2 = this.rule2();
        const rule3 = this.rule3();
        const rule4 = this.rule4();
        const rule5 = this.rule5();
        let stepState = true;
        let focus = false;

        if(!rule1) {
            document.getElementById("firstname").focus();
            document.getElementById("firstname-label").style.color = "#f44336";
            stepState = false;
            focus = true;
        } else {
            document.getElementById("firstname-label").style.color = "#0099CE";
        }

        if(!rule2) {
            if(focus === false){
                document.getElementById("lastname").focus();
                focus = true;
            }
            document.getElementById("lastname-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("lastname-label").style.color = "#0099CE";
        }

        if(!rule3) {
            if(focus === false){
                document.getElementById("title").focus();
                focus = true;
            }
            document.getElementById("title-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("title-label").style.color = "#0099CE";
        }

        if(!rule4) {
            if(focus === false){
                document.getElementById("phonenumber3").focus();
                focus = true;
            }
            document.getElementById("phonenumber3-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("phonenumber3-label").style.color = "#0099CE";
        }

        if(!rule5) {
            if(focus === false){
                document.getElementById("email3").focus();
                focus = true;
            }
            document.getElementById("email3-label").style.color = "#f44336";
            stepState = false;
        } else {
            document.getElementById("email3-label").style.color = "#0099CE";
        }

        if(stepState === true) {
            this.props.nextStep(5);
        }
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const errorVisible = {
            display: 'none',
        };
        const paper = {
            marginTop: '18px',
            marginBottom: '18px',
            padding: '28px',
        };
        const layout = {
            width: '680px',
            marginLeft: 'auto',
            marginRight: 'auto',
        };
        const requireColor = {
            color: 'red',
        }
        const headerName = {
            paddingBottom: '8px',
            marginBottom: '26px',
            paddingTop: '8px',
            paddingLeft: '8px',
            backgroundColor: '#868B8E',
            color: '#FFFFFF',
        };
        const headerBlocks = {
            marginBottom: '8px',
        };
        const headerMargins = {
            marginBottom: '12px',
        };
        const buttons = {
            display: 'flex',
            justifyContent: 'flex-end',
        };
        const titleBlocks = {
            marginBottom: '8px',
        };
        const button = {
            marginTop: '8px',
            marginLeft: '8px',
        };
        const { values, handleChange } = this.props;

        return (
            <React.Fragment>
            <CssBaseline />
            <main style={layout}>
            <Paper style={paper}>

            <React.Fragment>
                <Typography component="h2" variant="h4" align="center" style={titleBlocks}>
                    {Lang().register.indigenousSupplier}
                </Typography>
                <Typography variant="body1" gutterBottom style={headerName}>
                    {Lang().register.primaryContactDetailsHeader}
                </Typography>

                <Typography variant="body1" component="span" gutterBottom className={headerBlocks}>
                    {Lang().register.firstname}
                </Typography>
                <Box component="span" style={requireColor}> * </Box>
                <Grid container spacing={3} style={headerMargins}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    id="firstname"
                    name="firstname"
                    label={Lang().register.yourAnswer}
                    fullWidth
                    autoComplete="given-name"
                    onChange={handleChange('firstname')}
                    defaultValue={values.firstname}
                    />
                    </Grid>
                </Grid>

                <Typography variant="body1" component="span" gutterBottom className={headerBlocks}>
                    {Lang().register.lastname}
                </Typography>
                <Box component="span" style={requireColor}> * </Box>
                <Grid container spacing={3} style={headerMargins}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    id="lastname"
                    name="lastname"
                    label={Lang().register.yourAnswer}
                    fullWidth
                    autoComplete="given-name"
                    onChange={handleChange('lastname')}
                    defaultValue={values.lastname}
                    />
                    </Grid>
                </Grid>

                <Typography variant="body1" component="span" gutterBottom className={headerBlocks}>
                    {Lang().register.title}
                </Typography>
                <Box component="span" style={requireColor}> * </Box>
                <Grid container spacing={3} style={headerMargins}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    id="title"
                    name="title"
                    label={Lang().register.yourAnswer}
                    fullWidth
                    autoComplete="given-name"
                    onChange={handleChange('title')}
                    defaultValue={values.title}
                    />
                    </Grid>
                </Grid>

                <Typography variant="body1" component="span" gutterBottom className={headerBlocks}>
                    {Lang().register.phonenumber3}
                </Typography>
                <Box component="span" style={requireColor}> * </Box>
                <Grid container spacing={3} style={headerMargins}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    id="phonenumber3"
                    name="phonenumber3"
                    label={Lang().register.yourAnswer}
                    fullWidth
                    autoComplete="given-name"
                    onChange={handleChange('phonenumber3')}
                    defaultValue={values.phonenumber3}
                    />
                    </Grid>
                </Grid>

                <Typography variant="body1" component="span" gutterBottom className={headerBlocks}>
                    {Lang().register.extension}
                </Typography>
                <Grid container spacing={3} style={headerMargins}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                    id="extension"
                    name="extension"
                    label={Lang().register.yourAnswer}
                    fullWidth
                    autoComplete="given-name"
                    onChange={handleChange('extension')}
                    defaultValue={values.extension}
                    />
                    </Grid>
                </Grid>

                <Typography variant="body1" component="span" gutterBottom className={headerBlocks}>
                    {Lang().register.email3}
                </Typography>
                <Box component="span" style={requireColor}> * </Box>
                <Grid container spacing={3} style={headerMargins}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    id="email3"
                    name="email3"
                    label={Lang().register.yourAnswer}
                    fullWidth
                    autoComplete="given-name"
                    onChange={handleChange('email3')}
                    defaultValue={values.email3}
                    />
                    </Grid>
                </Grid>

                <React.Fragment>
                <div style={buttons}>
                <Button onClick={this.back} style={button}>
                    {Lang().register.back}
                </Button>
                <Button
                variant="contained"
                color="primary"
                style={button}
                onClick={this.continue}
                >
                    {Lang().register.next}
                </Button>
                </div>
                <div id="error" style={errorVisible}>error message!</div>
                </React.Fragment>

            </React.Fragment>
            </Paper>
            </main>
            </React.Fragment>
        );
    }
}

export default ThirdPage;