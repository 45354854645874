export const theme = {
  colors: {
    white: '#FFFFFF',
    darkerWhite: '#CCCCCC',
    ice: '#F1F7F9',
    cerulean: '#0099CE',
    darkCerulean: '#08457E',
    orange: '#E47522',
    salmonError: '#C55252',
    blueGrey: '#6B9EAF',
    lightGrey: '#444444',
    grey: '#f6f6f6',
    darkGrey: '#828282',
    lightSkyBlue: '#DDE4E7',
    greyishBrown: '#494949',
    transparentShadow: 'rgba(12, 72, 93, 0.2)',
    transparenterShadow: 'rgba(12, 72, 93, 0.1)',
    transparentWhite: 'rgba(255, 255, 255, 0.7)',
    transparentError: 'rgba(197, 82, 82, 0.1)',
    transparentCerulean: 'rgba(0, 153, 206, 0.1)',
    transparentCeruleanHovered: 'rgba(0, 153, 206, 0.3)',
    transparentOrangeHovered: 'rgba(228, 117, 34, 0.3)',
    blue: '#5b89b5',
    darkBlue:'#2D97FC',
    dark: '#2b394a',
  },
  sizes: {
    logoWidth: '120px',
    smallLogoWidth: '24px',
    drawerWidth: '260px',
    drawerWidthClosed: '70px',
    toolbarHeight: '63px',
    filterToolbarHeight: '48px',
  },
  paddings: {
    paddingLong: '24px',
    paddingSmall: '12px',
  },
  classes: { // insert these in emotion css with no , or ;
    searchBox: `
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: #FFFFFF;
      border-radius: 4px;
    `,
    selectBar: `
      color: #828282;
      height: 100%;
      background-color: #FFFFFF;
    `,
    button: `
      background-color: #5b89b5;
      border-radius: 4px;
      height: 100%;
      width: 100%;
      font-weight: bold;
      color: #FFFFFF;
    `,
    searchField: `
      color: #828282;
      height: 100%;
      background-color: #FFFFFF ;
    `
  },
  muiTheme: {
  palette: {
    primary: {
      main: '#94AFC3',
    },
    secondary: {
      main: '#DDE4E7',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
  },
}
}
