import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Lang from '../shared/lang';
import splashLogo from '../../assets/Splash_Logo.png';

import { supplierData } from '../shared/register-data';
import BpWebSocket from '../shared/bp-websocket';
import SPGlobals from './globals';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1)
        },
    },
}));


export default function ContactsPage() {
    const classes = useStyles();

    const layout = {
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
    };

    const paper = {
        marginTop: '18px',
        marginBottom: '18px',
        padding: '28px',
    }

    const field = {
        marginLeft: '7px',
        marginRight: '7px',
        width: '36ch'
    }

    const headerSection = {
        marginBottom: '9px',
        marginTop: '18px',
    };

    const headerPage = {
        marginBottom: '27px',
    };

    const column = {
        flexBasis: '77.77%',
    };

    const details = {
        alignItems: 'center',
    };

    const accordionBlock = {
        width: '50%',
    };
    
    const enable_updateBtn = (e) => {
    	let updateButton 
    		= e.target.parentNode.parentNode
    		   .parentNode.parentNode.parentNode.querySelector('.update-btn')
    	
    	updateButton.disabled = false;
        updateButton.innerText = 'Update Contact';
    }
    
    const disable_updateBtn = (e) => {
        let updateButton = e.target
        
    	updateButton.disabled = true;
        updateButton.innerText = 'Updated';
    } 
    
    const openContactRemove = (contactId, e) => {
    	const form_actions = e.target.parentNode.parentNode;
    	
    	form_actions.querySelector('.update-btn').style.display = 'none';
		form_actions.querySelector('.delete-btn').style.display = 'none';
    	form_actions.querySelector('.delete-confirmation-msg').style.display = 'block';
    	
    	setTimeout(() => {
    		form_actions.querySelector('.delete-confirmation-msg').innerHTML = 'Confirm or Cancel deletion: '
    		form_actions.querySelector('.delete-confirmation-btn').style.display = 'block';
    		form_actions.querySelector('.delete-cancel-btn').style.display = 'block';
    	}, 3000);
    }
    
    const hideContactRemove = (contactId, e) => {
    	const form_actions = e.target.parentNode.parentNode;
    	
    	form_actions.querySelector('.delete-confirmation-msg').innerHTML = '<span style="padding-right:54px">Preparing deletion ... </span>'
    	form_actions.querySelector('.delete-confirmation-msg').style.display = 'none';
    	form_actions.querySelector('.delete-confirmation-btn').style.display = 'none';
		form_actions.querySelector('.delete-cancel-btn').style.display = 'none';
		
    	form_actions.querySelector('.update-btn').style.display = 'block';
		form_actions.querySelector('.delete-btn').style.display = 'block';
    }
    
    const confirmContactRemove = (contactId, e) => {
    	BpWebSocket.send({
            call: 'supplierContactUpdateRequest',
            payload: {
            	'supplierId': supplierData.id,
            	'contactId': contactId,
            	'deleted': '1'
            }
        }); /**/
    	
    	let newContacts = [];
    	for (let i = 0; i < supplierData.contacts.length; i++) {
			if (supplierData.contacts[i].Id == contactId) {
    			continue;
    		}
			
			newContacts = newContacts.concat([supplierData.contacts[i]]);
		}
    	
    	supplierData.contacts = newContacts;
    	hideContactRemove(contactId, e);
    	SPGlobals
	    	.items
	    		.SupplierPage.showScreen('Contacts_Page');
    }
    
    const updateContact = (contactId, e) => {
    	console.log('Contact GUID: ', contactId);
    	
    	let form = e.target.parentNode.parentNode.parentNode;
        let payload = {
    		'supplierId': supplierData.id,
    		'contactId': contactId,
    		'firstName': form.querySelector('.contact-first-name input').value,
    		'lastName': form.querySelector('.contact-last-name input').value,
    		'title': form.querySelector('.contact-title input').value,
    		'phoneNumber': form.querySelector('.contact-phone-number input').value,
    		'email': form.querySelector('.contact-email input').value,
    		'description': form.querySelector('.contact-description textarea').value,
        };
        
        for (let i = 0; i < supplierData.contacts.length; i++) {
			if (supplierData.contacts[i].Id == contactId) {
				supplierData.contacts[i] = {
					'Id': contactId,
					'FirstName': payload.firstName,
					'LastName': payload.lastName,
					'Title': payload.title,
					'PhoneWork': payload.phoneNumber,
					'primaryEmail': payload.email,
					'Description': payload.description
				};
				
				break;
			}
		}
        
        BpWebSocket.send({
            call: 'supplierContactUpdateRequest',
            payload: payload
        });
        
        disable_updateBtn(e);
        SPGlobals
	    	.items
	    		.SupplierPage.showScreen('Contacts_Page');
    }
    
    const addNewContact = () => {
    	let fname_val = document.querySelector('#nc_firstname').value;
    	let lname_val = document.querySelector('#nc_lastname').value;
    	let phone_val = document.querySelector('#nc_phonenumber').value;
    	
    	if (fname_val.length == 0 || lname_val.length == 0 || phone_val.length == 0) {
    		return false;
    		
    	}
    	
    	let contactGuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        	(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    	);
    	
        let payload = {
    		'new': true,
    		'supplierId': supplierData.id,
    		'contactId': contactGuid,
    		'firstName': fname_val,
    		'lastName': lname_val,
    		'title': document.querySelector('#nc_title').value,
    		'phoneNumber': phone_val,
    		'email': document.querySelector('#nc_email').value,
    		'description': document.querySelector('#nc_description').value,
        };

        supplierData.contacts = supplierData.contacts.concat([{
        	'Id': contactGuid,
			'FirstName': payload.firstName,
			'LastName': payload.lastName,
			'Title': payload.title,
			'PhoneWork': payload.phoneNumber,
			'primaryEmail': payload.email,
			'Description': payload.description
        }]); 
        
        BpWebSocket.send({
            call: 'supplierContactUpdateRequest',
            payload: payload
        });
        
        //console.log('Contacts: ', supplierData.contacts);
        
        setOpen(false);
        SPGlobals
	    	.items
	    		.SupplierPage.showScreen('Contacts_Page');
    }

    const [open, setOpen, setState] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const CSS = {
		imgSplashLogo: {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		}
    };
    
    return (
        <React.Fragment>
        <CssBaseline />
        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
	        <img style={CSS.imgSplashLogo} src={splashLogo} />
	     </div>
        <main style={layout}>
        <Paper style={paper}>
            <Typography style={headerPage} component="h2" variant="h4" align="center" className={classes.titleBlocksHeader}>
                Business Contacts
            </Typography>

                <Typography variant="body1" gutterBottom style={headerSection}>
                    <Button variant="contained" onClick={handleClickOpen} color="primary">
	                    Add Contact
	                </Button>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">New Contact</DialogTitle>
                        <DialogContent>
                            <form className={classes.root} noValidate autoComplete="off">
                                <TextField required style={field} id="nc_firstname" label="First name" variant="outlined" />
                                <TextField required style={field} id="nc_lastname" label="Last name" variant="outlined" />
                                <br />
                                <TextField style={field} id="nc_title" label="Title" variant="outlined" />
                                <TextField required style={field} id="nc_phonenumber" label="Phone number" variant="outlined" />
                                <br />
                                <TextField style={field} id="nc_email" label="Email" variant="outlined" />
                                <TextField style={field}
                                    id="nc_description"
                                    label="Description"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                />
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={addNewContact} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Typography>

                <Typography variant="body1" gutterBottom style={headerSection}>
                    Contact List:
                </Typography>

                {supplierData.contacts?.map((contact) => {

                    return (
                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header1" >
                            <div style={column}>
                                <Typography>{contact.FirstName} {contact.LastName} - {contact.Title}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={details}>
                            <form className={classes.root} noValidate autoComplete="off">
                                <TextField onKeyUp={(e) => enable_updateBtn(e)} style={field} className="contact-first-name" label="First name" defaultValue={contact.FirstName} variant="outlined" />
                                <TextField onKeyUp={(e) => enable_updateBtn(e)} style={field} className="contact-last-name" label="Last name" defaultValue={contact.LastName} variant="outlined" />
                                <br />
                                <TextField onKeyUp={(e) => enable_updateBtn(e)} style={field} className="contact-title" label="Title" defaultValue={contact.Title} variant="outlined" />
                                <TextField onKeyUp={(e) => enable_updateBtn(e)} style={field} className="contact-phone-number" label="Phone number" defaultValue={contact.PhoneWork} variant="outlined" />
                                <br />
                                <TextField onKeyUp={(e) => enable_updateBtn(e)} style={field} className="contact-email" label="Email" defaultValue={contact.primaryEmail} variant="outlined" />
                                <TextField onKeyUp={(e) => enable_updateBtn(e)} style={field}
                                	className="contact-description"
                                    label="Description"
                                    defaultValue={contact.Description}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                />
                            </form>
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <Typography id="time0" component="span">
                            </Typography>
                            <Button
                            	className="update-btn"
                                size="small"
                                color="primary"
                                variant="contained"
                                name="0"
                                onClick={(e) => updateContact(contact.Id, e)}
                            >
                            	Update Contact
                            </Button>
                            <Button
                            	className="delete-btn"
	                            size="small"
	                            color="primary"
	                            variant="contained"
	                            name="0"
	                            onClick={(e) => openContactRemove(contact.Id, e)}
	                        >
	                        	Delete
	                        </Button>
                        	<br />
                        	<Typography className="delete-confirmation-msg" style={{display: 'none'}}><span style={{paddingRight: '54px'}}>Preparing deletion ... </span></Typography>
                        	<Button
                        		className="delete-confirmation-btn"
                        		style={{display: 'none'}}
                        		size="small"
	                            color="primary"
	                            variant="contained"
	                            name="0"
	                            onClick={(e) => confirmContactRemove(contact.Id, e)}
	                        >
                        		Confirm
                        	</Button>
                    		<Button
                        		className="delete-cancel-btn"
                        		style={{display: 'none'}}
                        		size="small"
	                            color="primary"
	                            variant="contained"
	                            name="0"
	                            onClick={(e) => hideContactRemove(contact.Id, e)}
	                        >
                        		Cancel
                        	</Button>
                        		
                        </AccordionActions>
                    </Accordion>

                    );
                })}

        </Paper>
        </main>
        </React.Fragment>
    );
}