/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Box, Typography, Chip } from "@material-ui/core";
import ToolBarStyles from "./styles";
import { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQueryParam, ArrayParam } from "use-query-params";
import { filterTypes } from "../../../../constants/config";
import * as R from "ramda";
import Lang from '../../../shared/lang';

const FilterComponent = (props) => {
  const [filter, setFilter] = useQueryParam("filter", ArrayParam);
  const filters = filter || [];
  const onChipClick = (key) => () => {
    const values = R.ifElse(
      R.includes(key),
      R.reject(R.equals(key)),
      R.append(key)
    )(filters);
    setFilter(values.length ? values : undefined);
  };
  return (
    <div css={ToolBarStyles}>
      <Box classes={{ root: "container" }}>
        <Typography classes={{ root: "Typography2" }}> {Lang().labels.filterBy}</Typography>
        <Box classes={{ root: "selectContainer" }}>
          {filterTypes.map((item) => (
            <Chip
              variant={R.includes(item.value)(filters) ? "default" : "outlined"}
              label={item.label}
              key={item.key}
              classes={{ root: R.includes(item.value)(filters) ? "chip" : "a" }}
              onClick={onChipClick(item.value)}
            />
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default FilterComponent;
