/** @jsx jsx */
import { jsx } from '@emotion/core'
import NoResultsPageStyles from './styles';
import results from '../../assets/no_results.png';
import {
  Box,
  Typography
} from '@material-ui/core';


const NoResultsPage = () => {
  return (
    <div css={NoResultsPageStyles}>
      <Box classes={{root: 'container'}}>
        <Box classes={{root: 'resultsContainer'}}>
          <img src={results} height="155" width="182" alt="Logo" />
          <Typography classes={{root: 'header'}}>
            No results found.
          </Typography>
          <Typography classes={{root: 'subheader'}}>
            Try a new search term.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default NoResultsPage;
