
/**
 * This object is to manage BP WebSocket connection
 *  - listeners
 *  - send
 *  
 */
const BpWebSocket = {
	/**
	 * Connection holder
	 * 
	 * @var WebSocket
	 */
	connection: undefined,
	
	/**
	 * On Open listeners holders
	 * 
	 * @var Array
	 */
	listenersOnOpen: [],
	
	/**
	 * On Message listeners holders
	 * 
	 * @var Array
	 */
	listenersOnMessage: [],
	
	/**
	 * On Error listeners holders
	 * 
	 * @var Array
	 */
	listenersOnError: [],
	
	/**
	 * Appends listener to On Open stack
	 * 
	 * @return BpWebSocket
	 * @param function func   A function to put into a listener
	 */
	listenOnOpen: function (func) {
		BpWebSocket.listenersOnOpen.push(func);
		return BpWebSocket;
	},

	/**
	 * Appends listener to On Message stack
	 * 
	 * @return BpWebSocket
	 * @param function func   A function to put into a listener
	 */
	listenOnMessage: function (func) {
		BpWebSocket.listenersOnMessage.push(func);
		return BpWebSocket;
	},
	
	/**
	 * Appends listener to On Error stack
	 * 
	 * @return BpWebSocket
	 * @param function func   A function to put into a listener
	 */
	listenOnError: function (func) {
		BpWebSocket.listenersOnError.push(func);
		return BpWebSocket;
	},
	
    /**
     * Parsing raw message
     * 
     * @return Object
     * @param string raw_message   A raw message data
     */
	parseToReturnMesssage: function (raw_message) {
		if (typeof raw_message != 'string'
			|| raw_message.length < 9
		) {
			return {
				call: 'null'
			};
		}
		
		var json_message = window.atob(raw_message);
		var message     = JSON.parse(json_message);
		
		if (typeof message != 'object') {
			return {
				call: 'null'
			};
		}
		
		return message;
	},
	
	/**
	 * Opens a WebSocket connection
	 * 
	 * @return BpWebSocket
	 * @param string address  Anb addre
	 */
	open: function (address) {
		BpWebSocket.connection = new WebSocket(address);
		
		BpWebSocket.connection.onopen = function (e) {
			for (var int = 0; int < BpWebSocket.listenersOnOpen.length; int++) {				
				var message = BpWebSocket.parseToReturnMesssage(e.data);
				BpWebSocket.listenersOnOpen[int](message);
			}
		}
		
		BpWebSocket.connection.onmessage = function (e) {
			for (var int = 0; int < BpWebSocket.listenersOnMessage.length; int++) {
				var message = BpWebSocket.parseToReturnMesssage(e.data);
				BpWebSocket.listenersOnMessage[int](message);
			}
		}
		
		BpWebSocket.connection.onerror = function (e) {
			for (var int = 0; int < BpWebSocket.listenersOnError.length; int++) {
				var message = BpWebSocket.parseToReturnMesssage(e.data);
				BpWebSocket.listenersOnError[int](message);
			}
		}		
	},
	
	/**
	 * Sends data to WebSocket connection
	 * 
	 * @param Object data  A data for message
	 */
	send: function (data) {
		var json_message = JSON.stringify(data);
		BpWebSocket.connection.send(json_message);
	}
}

export default BpWebSocket;
