import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { registerData, selectorsMetadata } from '../shared/register-data';
import Lang from '../shared/lang';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: '8px',
        marginLeft: '8px',
    },
    paper: {
        marginTop: '18px',
        marginBottom: '18px',
        padding: '28px',
    },
    layout: {
        width: '680px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headerName: {
        paddingBottom: '8px',
        marginBottom: '26px',
        marginTop: '12px',
        paddingTop: '8px',
        paddingLeft: '8px',
        backgroundColor: '#868B8E',
        color: '#FFFFFF',
    },
    headerMargins: {
        marginBottom: '12px',
    },
    headerBlocks: {
        marginBottom: '22px',
    },
    titleBlocks: {
        marginBottom: '8px',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function NullPage(parameters) {

    const products_retool = selectorsMetadata.products_retool;
    const services_retool = selectorsMetadata.services_retool;

    const classes = useStyles();
    const theme = useTheme();

    const [products, setProducts] = React.useState(registerData.products_retool);
    const [services, setServices] = React.useState(registerData.services_retool);

    const handleChange = (event) => {
        const name = event.target.name;
        registerData[name] = event.target.value;
    };

    const handleChangeProducts = (event) => {
        setProducts(event.target.value);
        registerData.products_retool = event.target.value;
    };

    const handleChangeServices = (event) => {
        setServices(event.target.value);
        registerData.services_retool = event.target.value;
    };

    const next = (event) => {
        parameters.nextStep();
    };

    const back = (event) => {
        parameters.prevStep();
    };

    return (
        <React.Fragment>

        <main className={classes.layout}>
        <Paper className={classes.paper}>

        <React.Fragment>
        <Typography component="h2" variant="h4" align="center" className={classes.titleBlocks}>
            {Lang().register.indigenousSupplier}
        </Typography>

        <Typography variant="body1" gutterBottom className={classes.headerName}>
            {Lang().register.retoolingHeader}
        </Typography>

        <Typography variant="body1" component="span" gutterBottom>
            {Lang().register.products_retool}
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
            <Select
                labelId="products_retool"
                id="products_retool"
                multiple
                value={products}
                onChange={handleChangeProducts}
                input={<Input id="products_retool_chip" />}
                renderValue={(selected) => (
                <div className={classes.chips}>
                    {selected.map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                    ))}
                </div>
                )}
                MenuProps={MenuProps}
            >
                {products_retool.map((name) => (
                    <MenuItem key={name} value={name} style={getStyles(name, products, theme)}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
            </Grid>
        </Grid>
        <Typography variant="body1" component="span" gutterBottom>
            {Lang().register.products_retool_other}
        </Typography>
        <Grid container spacing={3} className={classes.headerBlocks}>
        <Grid item xs={12} sm={6}>
        <TextField
            id="products_retool_other"
            name="products_retool_other"
            label={Lang().register.yourAnswer}
            fullWidth
            autoComplete="given-name"
            onChange={handleChange}
            defaultValue={registerData.products_retool_other}
        />
        </Grid>
        </Grid>

        <Typography variant="body1" component="span" gutterBottom>
            {Lang().register.services_retool}
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
            <Select
                labelId="services_retool"
                id="services_retool"
                multiple
                value={services}
                onChange={handleChangeServices}
                input={<Input id="services_retool_chip" />}
                renderValue={(selected) => (
                <div className={classes.chips}>
                    {selected.map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                    ))}
                </div>
                )}
                MenuProps={MenuProps}
            >
                {services_retool.map((name) => (
                    <MenuItem key={name} value={name} style={getStyles(name, services, theme)}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
            </Grid>
        </Grid>
        <Typography variant="body1" component="span" gutterBottom>
            {Lang().register.services_retool_other}
        </Typography>
        <Grid container spacing={3} className={classes.headerBlocks}>
        <Grid item xs={12} sm={6}>
        <TextField
            id="services_retool_other"
            name="services_retool_other"
            label={Lang().register.yourAnswer}
            fullWidth
            autoComplete="given-name"
            onChange={handleChange}
            defaultValue={registerData.services_retool_other}
        />
        </Grid>
        </Grid>

        <Typography variant="body1" component="span" gutterBottom>
            {Lang().register.comments_retool}
        </Typography>

        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <TextField
                id="comments_retool"
                name="comments_retool"
                label={Lang().register.yourAnswer}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange}
                defaultValue={registerData.comments_retool}
            />
            </Grid>
        </Grid>

        <React.Fragment>
        <div className={classes.buttons}>
            <Button onClick={back} className={classes.button}>
                {Lang().register.back}
            </Button>
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={next}
            >
                {Lang().register.next}
            </Button>
        </div>
        </React.Fragment>

        </React.Fragment>
        </Paper>
        </main>

        </React.Fragment>
    );
}