import css from '@emotion/css';

const ToolBarStyles = css `


@media (max-width:800px) {

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.Typography{
  font-size: 24px;
  font-weight: 900;
  color:#999999 !important;
  text-transform:uppercase  !important;
  line-height:26px   !important;
  padding: 0px  16px !important;
   display:block !important;
   position:relative !important;
   float:left !important;
   height:26px !important;

}
.Typography2{
  font-size: 16px  !important;
  font-weight: normal !important;
  margin-left: 22px !important;
  padding: 0px !important;
  color:  #666666 !important;
   line-height:26px   !important;
   display:block !important;
   position:relative !important;
   float:left !important;
   height:26px !important;

}

.Typography1{

  font-size: 16px  !important;
  font-weight: normal !important;
  margin-left: 22px !important;
  padding-top: 0px !important;
  color:  #666666 !important;
  padding: 0px !important;
  line-height:26px   !important;
   display:block !important;
   position:relative !important;
   float:left !important;
   height:26px !important;

}
.selectContainer {
  display: flex;
  flex-direction: row;
  padding-top: 10px !important;
  flex-grow: 2;
  justify-content: start;
  flex-wrap: wrap;
  padding-left: 10px !important;
  padding-right: 10px;
  background-color:#FFFFFF  !important;
  
}
.selectContainer span{
font-size:16px !important;
color: #999999 !important;
}

.MuiChip-root{
margin: 2px;

}
}

/*desktop*/

.selectContainer span{
font-size:16px !important;
color: #999999 !important;
}


.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.selectContainer {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  flex-grow: 2;
  justify-content: start;
  flex-wrap: wrap;
  padding-left: 32px;
  padding-right: 32px;
  background-color:#FFFFFF  !important;
}
.MuiChip-root{
margin: 2px;

}
`
export default ToolBarStyles;