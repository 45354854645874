/** @jsx jsx */
import { jsx } from '@emotion/core'
import ToolBarStyles from './styles';
import {
  Box,
  Typography
} from '@material-ui/core';
import FilterComponent from './filter-component';
import { useQueryParam, StringParam } from 'use-query-params';
import Lang from '../../shared/lang';

const ResultsSection = (props) => {
  const { searchTerm, count, location, filter, defaultValues, callbackFunction } = props;
  const locationValue = location? location.split("=")[1] : location;
  const [name] = useQueryParam('name', StringParam)
  return (
    <div css={ToolBarStyles}>
      <Box classes={{root: 'container'}}>
      <Box classes={{root: 'subContainer'}}>
      <Typography classes={{root: 'Typography2'}}> {Lang().labels.resultsFor}
      </Typography>
        <Typography classes={{root: 'Typography'}}>{searchTerm}
        </Typography>
        { name && <Typography classes={{root: 'Typography2'}}> in </Typography>}

          <Typography classes={{root: 'Typography'}}> {name}
          </Typography>
        </Box>
        <Typography classes={{root: 'Typography1'}}>{count} Results </Typography>
        <FilterComponent
        searchTerm={searchTerm}
        location={location}
        defaultValues={defaultValues}
        filter={filter}
        term={searchTerm}
        callbackFunction={callbackFunction}
        />
      </Box>
    </div>
  );
};

export default ResultsSection;
