/** @jsx jsx */
import { jsx } from '@emotion/core'
import ToolBarStyles from './styles';
import {
  Box
} from '@material-ui/core';
import  SearchBar  from '../../shared/search-bar';
import { useHistory } from "react-router-dom";

const ToolBar = (props) => {
  const history = useHistory();
  const navigateToHome = () => {
    history.push(`/`);
  }

  return (
    <div css={ToolBarStyles}>
      <Box classes={{root: 'container'}}>
        <Box classes={{root: 'textTypography'}} onClick={navigateToHome}> Supplier Database</Box>
        <Box classes={{root: 'toolbarSearch'}}>
          <SearchBar type={'toolbar'} searchTerm={''} filter={''}/>
        </Box>
      </Box>
    </div>
  );
};

export default ToolBar;
