/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Link,
  Grid,
} from '@material-ui/core';
import LoadingScreen from '../shared/loading-screen';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import DetailPageStyles from './styles';
import ToolBar from './toolbar';
import {getOrgLogo}  from '../../constants/config';
import {getSupplierDetailsByID} from '../../services/supplier.service';
import {
  useParams
} from "react-router-dom";

const DetailPage = (props) => {
  // const {id} = props
  const { id } = useParams();
  const [supplierData, setSupplierData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ contactInfo, setContactInfo ] = useState([]);
  const [org,setOrg] = useState(undefined);

  const retrieveSupplierByID = async (supplierID) => {
    setLoading(true);
    try {
      const inputData = await getSupplierDetailsByID(supplierID);
      if (inputData) {
        setSupplierData(inputData[0]);
        setContactInfo(inputData);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    const fetchData = async (id) => {
      console.log(id);
      await retrieveSupplierByID(id);
    };
    fetchData(id);
  }, [id]);

  const entites = {
    '&#039;': "'",
    '&quot;': '"',
  };

  for (var i = 0; i < contactInfo.length; i++) {
    if (typeof contactInfo[i] !== 'undefined') {
      if (typeof contactInfo[i].title === 'string') contactInfo[i].title = contactInfo[i].title.replace(/&#?\w+;/g, match => entites[match]);
      if (typeof contactInfo[i].first_name === 'string') contactInfo[i].first_name = contactInfo[i].first_name.replace(/&#?\w+;/g, match => entites[match]);
      if (typeof contactInfo[i].last_name === 'string') contactInfo[i].last_name = contactInfo[i].last_name.replace(/&#?\w+;/g, match => entites[match]);
      if (typeof contactInfo[i].cont_phone === 'string') contactInfo[i].cont_phone = contactInfo[i].cont_phone.replace(/&#?\w+;/g, match => entites[match]);
      if (typeof contactInfo[i].mobile === 'string') contactInfo[i].mobile = contactInfo[i].mobile.replace(/&#?\w+;/g, match => entites[match]);
      if (typeof contactInfo[i].email === 'string') contactInfo[i].email = contactInfo[i].email.replace(/&#?\w+;/g, match => entites[match]);
    }
  }

  if (typeof supplierData.name === 'string') supplierData.name = supplierData.name.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.address1 === 'string') supplierData.address1 = supplierData.address1.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.city === 'string') supplierData.city = supplierData.city.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.province === 'string') supplierData.province = supplierData.province.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.postal_code === 'string') supplierData.postal_code = supplierData.postal_code.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.website === 'string') supplierData.website = supplierData.website.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.phoneNumber === 'string') supplierData.phoneNumber = supplierData.phoneNumber.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.email === 'string') supplierData.email = supplierData.email.replace(/&#?\w+;/g, match => entites[match]);

  if (typeof supplierData.description === 'string') supplierData.description = supplierData.description.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.afi === 'string') supplierData.afi = supplierData.afi.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.service === 'string') supplierData.service = supplierData.service.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.product === 'string') supplierData.product = supplierData.product.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.covidService === 'string') supplierData.covidService = supplierData.covidService.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.covidProduct === 'string') supplierData.covidProduct = supplierData.covidProduct.replace(/&#?\w+;/g, match => entites[match]);
  if (typeof supplierData.comments === 'string') supplierData.comments = supplierData.comments.replace(/&#?\w+;/g, match => entites[match]);

  return (
    <div css={DetailPageStyles}>
    {loading && <LoadingScreen/>}
      <Box classes={{root: 'tootlbar'}}>
        <ToolBar />
        </Box>
    { supplierData &&
        <Box classes={{root:'mainContainer'}}>

          <Box classes={{root: 'detailContainer'}}>

            <Box classes={{root: 'headerComponent'}}>
            { supplierData.name && <Typography classes={{root: 'header'}}>{supplierData.name}</Typography>}
            </Box>
            <Box classes={{root:'addressContainer'}}>
              <Typography classes={{root: 'address'}}>
                {supplierData.address1}
              </Typography>
              <Typography classes={{root: 'address'}}>
                  {supplierData.city} {supplierData.province} {supplierData.postal_code}
              </Typography>
            </Box>
            <Box>
              <img src={getOrgLogo(supplierData.org)} height="50" width="64" alt="Logo" style={{objectFit: 'cover'}} onClick={()=>{setOrg(supplierData.org);console.log(supplierData.org)}} />
            </Box>
          </Box>
          <Box classes={{root: 'socialContent'}}>
            <Box classes={{root:'businessInfo'}}>
              <Typography classes={{root: 'header1'}}>
                Business Information
              </Typography>
              { supplierData.website && <Box classes={{root: 'websiteComponent'}}>
                <LanguageIcon/>
                <Link classes = {{root: 'LinkData'}} href={supplierData.website} target="_blank" rel="noreferrer">{supplierData.website}</Link>
              </Box>}
              { supplierData.phoneNumber && <Box classes={{root: 'websiteComponent'}}>
                <PhoneIcon/>
                <Typography classes = {{root: 'returnList'}}>{supplierData.phoneNumber}</Typography>
              </Box>}
              { supplierData.email && <Box classes={{root: 'websiteComponent'}}>
                <EmailIcon/>
                <Link classes = {{root: 'returnList'}} href={'mailto:' + supplierData.email}>{supplierData.email}</Link>
              </Box>}
            </Box>
            <Box classes={{root:'businessInfo'}}>
              <Typography  classes={{root: 'header1'}}>
                Social Media
              </Typography>
              <Box classes={{root: 'websiteComponent1'}}>
                <Grid   container spacing={2}>
                  <Grid container item  xs={6} spacing={2}>
                  {supplierData.facebook &&
                  <Grid container item xs>
                   <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.facebook} target="blank">Facebook</Link>
                  </Grid>}
                  {supplierData.linkedIn &&
                  <Grid container item xs>
                    <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.linkedIn} target="blank">LinkedIn</Link>
                  </Grid>}
                  {supplierData.pinterest &&
                  <Grid container item xs>
                     <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.pinterest}  target="blank">Pinterest</Link>
                  </Grid>}
                  {supplierData.twitter &&
                  <Grid container item xs>
                     <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.twitter} target="blank">Twitter</Link>
                  </Grid>}
                  {supplierData.instagram &&
                  <Grid container item xs>
                     <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.instagram}  target="blank">Instagram</Link>
                  </Grid>}
                  {supplierData.rss_feeds &&
                  <Grid container item xs>
                     <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.rss_feeds}  target="blank">RSS Feed</Link>
                  </Grid>}
                  {supplierData.Youtube &&
                  <Grid container item xs>
                     <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.youtube}  target="blank">Youtube</Link>
                  </Grid>}
                  {supplierData.flickr &&
                  <Grid container item xs>
                   <Link classes = {{root: 'LinkDataSocial'}} href={supplierData.flickr}  target="blank">Flickr</Link>
                  </Grid>}
                </Grid>
                </Grid>

              </Box>
            </Box>
          </Box>
          <Box classes={{root:'data'}}>
            <Typography classes={{root: 'header1'}}>
              About this business
            </Typography>
            <Typography>
              {supplierData.description}
            </Typography>
            <Box classes={{root: 'productInfo'}}>
              { supplierData.afi && <Box classes={{root: 'detailComponent'}}>
                <Typography classes={{root: 'subheader'}}>
                  AFI:
                </Typography>
                <Typography classes={{root: 'dataContent'}}>
                  {supplierData.afi}
                </Typography>
                </Box>}
            </Box>
            <Box classes={{root: 'productInfo'}}>
              { supplierData.service && <Box classes={{root: 'detailComponent'}}>
                <Typography classes={{root: 'subheader'}}>Services: </Typography>
                <Typography classes={{root: 'dataContent'}}>{supplierData.service} </Typography>
              </Box>}
              {supplierData.product && <Box  classes={{root: 'detailComponent'}}>
              <Typography classes={{root: 'subheader'}}>Products:</Typography>
              <Typography classes={{root: 'dataContent'}}>{supplierData.product} </Typography>
            </Box>}
            </Box>
            <Box classes={{root: 'productInfo'}}>
              { supplierData.covidService && <Box classes={{root: 'detailComponent'}}>
                <Typography classes={{root: 'subheader'}}>COVID-19 Services: </Typography>
                <Typography classes={{root: 'dataContent'}}>{supplierData.covidService}</Typography>
                </Box>
              }
              { supplierData.covidProduct && <Box classes={{root: 'detailComponent'}}>
                  <Typography classes={{root: 'subheader'}}>COVID-19 Products: </Typography>
                  <Typography classes={{root: 'dataContent'}}>{supplierData.covidProduct}</Typography>
                </Box>
              }
            </Box>
            <Box classes={{root: 'productInfo'}}>
              { supplierData.comments && <Box  classes={{root: 'detailComponent'}}>
                <Typography classes={{root: 'subheader'}}>Comments:</Typography>
                <Typography classes={{root: 'dataContent'}}>{supplierData.comments} </Typography>
              </Box>}
            </Box>
          </Box>
          <Box classes={{root:'data'}}>
            <Typography classes={{root: 'header1'}}>
            Business Contacts
            </Typography>
              <Box>
                <Grid container spacing={2}>
                {contactInfo.map(item =>(
                  <Grid container item xs={6} spacing={2} key={item.id}>
                  <Box classes={{root: 'contactData'}}>
                    <Typography classes={{root: 'contactName'}}>{item.first_name} {item.last_name}</Typography>
                    { item.title && <Typography classes={{root: 'contactSubInfo'}}>{item.title}</Typography>}
                    <Box classes={{root: 'websiteComponent'}}>
                      <PhoneIcon/>
                      <Typography classes = {{root: 'returnList'}}>{item.cont_phone}</Typography>
                    </Box>
                    { item.mobile && <Box classes={{root: 'websiteComponent'}}>
                      <PhoneAndroidIcon/>
                      <Typography classes = {{root: 'returnList'}}>{item.mobile}</Typography>
                    </Box>
                    }
                    { item.email && <Box classes={{root: 'websiteComponent'}}>
                      <EmailIcon/>
                      <Typography classes = {{root: 'returnList'}}>{item.cont_email}</Typography>

                    </Box>
                      }
                    </Box>
                  </Grid>
                  ))}
                </Grid>
              </Box>

          </Box>
        </Box>
      }


    </div>
  );
};

export default DetailPage;
