import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Lang from '../shared/lang';
import BpWebSocket from '../shared/bp-websocket';
import SVGlobals from './globals';

let BPSocketAddress = "wss://imscbpsock.datataskforce.ca";

var display = {
    init: function () {
        document.getElementById("initFailed").style.display = "none";
        document.getElementById("initProcess").style.display = "block";
    },

    failed: function () {
        document.getElementById("initFailed").style.display = "block";
        document.getElementById("initProcess").style.display = "none";
    },
}


var listener = {
    /**
     * Processes initialization message
     * 
     * @param object message  A message object
     */
	for_syncToDomain: function (message) {		
		if (message.call != 'syncToDomain'
			|| false == message.hasOwnProperty('payload')
		) {
			return false;
		}
		
		console.info('INCOMING MESSAGE for_syncToDomain: ');
		console.info(message);

		
		let request = SVGlobals.items.Verification.parseRequest();
		let initPage = '';
		
		switch (request.action) {
			case 'email':
				initPage = 'EmailPasswordReset';
				break;
			case 'code':
				initPage = 'EmailCodeConfirm';
				break;
				
			default:
				break;
		}

        SVGlobals
        	.items
        		.Verification.showScreen(initPage);
	},
	
	/**
     * Processes new password message
     * 
     * @param object message  A message object
     */
	for_verifyEmailCode: function (message) {		
		if (message.call != 'verifyEmailCode'
			|| false == message.hasOwnProperty('payload')
		) {
			return false;
		}
		
		if (false == message.payload.hasOwnProperty('passKey')) {
			SVGlobals.items.EmailCodeConfirmation.clearCodeField();
			SVGlobals.items.EmailCodeConfirmation.showCodeError();
			
			return false;
		}
		
		console.info('INCOMING MESSAGE for_verifyEmailCode: ');
		console.info(message);
		
		SVGlobals.items.passKey = message.payload.passKey;
        SVGlobals
        	.items
        		.Verification.showScreen('InitSupplierPassword');
	},
	
	/**
     * Processes new password message
     * 
     * @param object message  A message object
     */
	for_emailPasswordReset: function (message) {		
		if (message.call != 'emailPasswordReset'
			|| false == message.hasOwnProperty('payload')
		) {
			return false;
		}
		
		console.info('INCOMING MESSAGE for_emailPasswordReset: ');
		console.info(message);
		
		let newPasswordPage = SVGlobals.items.EmailPasswordReset;
		switch (message.payload.status) {
			case 'EMAIL_CONFIRMED':
				newPasswordPage.displayNewPassForm(false);
				newPasswordPage.displaySuccessMessage(true);
				newPasswordPage.displayEmailError(false);
				newPasswordPage.switchSubmitButtonStatus(false);
				
				break;
			case 'EMAIL_VERIFICATION_ERROR':
				newPasswordPage.displayNewPassForm(true);
				newPasswordPage.displaySuccessMessage(false);
				newPasswordPage.displayEmailError(true);
				
				newPasswordPage.switchSubmitButtonStatus(false);
				setTimeout(
					() => {
						newPasswordPage.switchSubmitButtonStatus(true);
					}, 5000
				);
				
				break;
	
			default:
				break;
		}
	},
	
	/**
	 * BPWebSocket Error handler
	 */
    for_errorAnswer: function (message) {
    	console.log(JSON.stringify(message));
    }
}

/**
 * NullPage handler for react framework
 * 
 * 
 */
export class NullPage extends Component
{
    /**
     * Connecting to the BPWebSocket 
     * 
     * @param data
     */
    connect() {
    	BpWebSocket
    		.listenOnOpen(function (e) {
    			BpWebSocket.send({
    				call: 'syncToDomain'
    			});
    			
    		})
    		.listenOnMessage(function (e) {
    			listener.for_syncToDomain(e);
    			listener.for_verifyEmailCode(e);
    			listener.for_emailPasswordReset(e);
    			
    		})
            .listenOnError(function (e) {
                listener.for_errorAnswer(e);
            })
    		.open(BPSocketAddress);
    };

    render() {    	
    	this.connect();

    	const layout = {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	    };
    	    
	    const paper = {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	    };
	    
        const initProcess = {
            marginBottom: '8px',
        };
        const initFailed = {
            marginBottom: '8px',
            display: 'none',
        };

        return (
            <React.Fragment>
            <CssBaseline />
            <main style={layout}>
	            <Paper style={paper}>
	
	            <React.Fragment>
	            <Typography component="h2" variant="h4" align="center" id="initProcess" style={initProcess}>
	                {Lang().register.init}
	            </Typography>
	            <Typography component="h2" variant="h4" align="center" id="initFailed" style={initFailed}>
	                {Lang().register.initFail}
	            </Typography>
	            </React.Fragment>
	
	            </Paper>
            </main>
            </React.Fragment>
        );
    }
}

export default NullPage;