/** @jsx jsx */
import {jsx} from '@emotion/core'
import React, { Component } from 'react';
import SVGlobals from './globals';

//Pages
import NullPage from './NullPage';
import EmailCodeConfirm from './EmailCodeConfirm';
import InitSupplierPassword from './InitSupplierPassword';
import EmailPasswordReset from './EmailPasswordReset';

export class Verification extends Component {
	parseRequest() {
		let request = document.location.href.split('verification/');
		if (request.length < 2) {
			return false;
		}
		
		let request_arr =  request[1].split(':');
		return {
			domain: request[0],
			action: request_arr[0]? request_arr[0] : '',
			one: request_arr[1]? request_arr[1] : '',
			two: request_arr[2]? request_arr[2] : '',
		}
	};
	
    state = {
		screen: 'NullPage'
    };

    setCall = (call = 'init') => {
        this.setState({
            call: call
        });
    };

    // Proceed to next step
    showScreen = (name = 'default') => {
    	this.setState({
            screen: name
        });
    };

    render() {
    	
        SVGlobals.items.Verification = this;
        SVGlobals.items.currentPage  = this.state.screen;
        
        //this.state.screen = 'blablabla';
        
        console.log('rendering: ' + this.state.screen);
        
        switch (this.state.screen) {
            case 'NullPage':
                return (
            		<NullPage />
                );
            case 'EmailPasswordReset':
            	return (
        			<EmailPasswordReset />
            	);
            case 'EmailCodeConfirm':
            	return (
        			<EmailCodeConfirm />
            	);
            case 'InitSupplierPassword':
            	return (
        			<InitSupplierPassword />
            	);
                

            default:
            	return (
                		<React.Fragment>
                        
                        	<div className="App"><br /><br /><br /> Error 404 - page {this.state.screen} not found.</div>
                        </React.Fragment>
                    );
        }
    }
}

export default Verification;