import css from '@emotion/css';
import { theme } from '../../constants/theme';

const NoResultsPageStyles = css `
.container {
  padding-top: 130px;
  background-color: ${theme.colors.white};
  text-align: center;
}
.resultsContainer{
  margin: 0 auto;
}

.header {
  padding-top: 24px;
  height: 28px;
  font-size: 24px;
  font-weight: 900;
  color: ${theme.colors.lightGrey};
}
.subheader {
  height: 22px;
  padding-top: 10px;
  font-size: 16px;
  line-height: 1.38;
  color: ${theme.colors.lightGrey};
}

`
export default NoResultsPageStyles;
