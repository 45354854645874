import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Lang from '../shared/lang';
import SPGlobals from './globals';
import BpWebSocket from '../shared/bp-websocket';
import homeic1 from '../../assets/HomePage_ic1.png';
import homeic2 from '../../assets/HomePage_ic2.png';
import homeic3 from '../../assets/HomePage_ic3.png';
import splashLogo from '../../assets/Splash_Logo.png';
import homePageLogo from '../../assets/HomePage_Logo.png';

import { supplierData, stepMessages } from '../shared/register-data';

export default function HomePage() {
	
	const CSS = {
		homePageLogo: {
			textAlign: 'center',
			
		},
			homePageLogoImg: {
				width: '222px'
			},
		homePageName: {
			textTransform: 'uppercase',
			color: '#94AFC3',
			fontSize: '45px',
			lineHeight: '54px',
			
		},
		homePageName2: {
			backgroundColor: '#94AFC3',
			color: 'white',
			textAlign: 'center',
			textTransform: 'none',
			fontSize: '14px',
			padding: '7px 135px',
		},
		imgSplashLogo: {
			bottom: '9px',
			position: 'fixed',
			width: '153px',
		},
		
		profileProgressIco: {
			borderRadius: '999px',
			width: '97px',
		    height: '97px',
		    backgroundColor: '#FFBD59',
		    position: 'absolute',
		    top: '9px',
		    left: '-63px',
		    padding: '12px',
		    fontWeight: 'bold',
		    textTransform: 'none'
		},
		
		myProfileBox: {
		   color: '#20385A',
		   borderRadius: '0px',
		   width: '192px',
		   height: '180px',
		   backgroundColor: '#BBC9D1',
		   margin: '21px',
		   display: 'inline-block',
		   paddingTop: '45px',
		},
			imgMyProfile: {
				position: 'absolute',
				top: '-75px',
				right: '33px',
				width: '120px',
			},
		
		myContactsBox: {
		   color: '#20385A',
		   borderRadius: '0px',
		   width: '192px',
		   height: '180px',
		   backgroundColor: '#BBC9D1',
		   margin: '21px',
		   display: 'inline-block',
		   paddingTop: '45px',
		},
			imgMyContacts: {
				position: 'absolute',
				top: '-75px',
				right: '33px',
				width: '120px',
			},
		
		
		myProfileBox: {
		   color: '#20385A',
		   borderRadius: '0px',
		   width: '192px',
		   height: '180px',
		   backgroundColor: '#BBC9D1',
		   margin: '21px',
		   display: 'inline-block',
		   paddingTop: '45px',
		},
			imgMyDocuments: {
				position: 'absolute',
				top: '-75px',
				right: '33px',
				width: '120px',
			},
		
			
		kpiIndicator: {
		  
		},
		kpiLabelHeader: {
			fontWeight: '600',
			marginBottom: '4px',
			fontSize: '18px',
		},
		kpiLabelText: {
			textTransform: 'none',
			lineHeight: 'normal',
			fontSize: 'small',
		},
	    layout: {
	        width: 'fit-content',
	        marginLeft: 'auto',
	        marginRight: 'auto',
	    },
	    paper: {
	        marginTop: '18px',
	        marginBottom: '18px',
	        padding: '28px',
	    },
	}
	
	const handleKpiClick_First = () => {
		SPGlobals
        .items
            .SupplierPage.showScreen('Basic_Page')
	}
	
	const handleKpiClick_Second = () => {
		SPGlobals
        .items
            .SupplierPage.showScreen('Contacts_Page')
	}
	
	const handleKpiClick_Third = () => {
		window.open(supplierData.concierge.institutionWebsite, '_blank').focus();
	}
	
    return (
        <React.Fragment>
	        <CssBaseline />
	        <div style={{position: 'absolute', height: '100%', width: '90px', backgroundColor: '#C6D4DF'}}>
	           <img style={CSS.imgSplashLogo} src={splashLogo} />
	         </div>
	        <main style={CSS.layout}>
	         <Paper style={CSS.paper}>
	            <div style={CSS.homePageLogo}>
	              <img style={CSS.homePageLogoImg} src={homePageLogo} />
	            </div>
	              
	              <Typography style={CSS.homePageName} component="div" variant="caption" align="center">
	              	Indigenous<br />Suppliers Co-op
	              </Typography>
              	  <Typography style={{marginBottom: '129px'}} component="div" variant="caption" align="center">
              	  	<span style={CSS.homePageName2}>Supplier Profile</span>
	              </Typography>
	            
	        	<div style={{marginTop: '39px', textAlign: 'center'}}>
	        		
	        		<Button style={CSS.myProfileBox} onClick={handleKpiClick_First}>
						<img style={CSS.imgMyProfile} src={homeic1} />
		                <Typography style={CSS.kpiLabelHeader} component="div" variant="caption" align="center">MY PROFILE</Typography>
						<div style={CSS.kpiLabelText}>Complete your basic business details, supplier profile, products and services.</div>
                	</Button>
                	
                	<Button style={CSS.myContactsBox} onClick={handleKpiClick_Second}>
						<img style={CSS.imgMyContacts} src={homeic2} />
		                <Typography style={CSS.kpiLabelHeader} component="div" variant="caption" align="center">MY CONTACTS</Typography>
						<div style={CSS.kpiLabelText}>Add, update or delete your business contacts.</div>
	            	</Button>
                	
	            	{supplierData.concierge && <Button style={CSS.myContactsBox} onClick={handleKpiClick_Third}>
						<img style={CSS.imgMyContacts} src={homeic3} />
		                <Typography style={CSS.kpiLabelHeader} component="div" variant="caption" align="center">CONCIERGE</Typography>
						<div style={CSS.kpiLabelText}>
							{supplierData.concierge.firstName} {supplierData.concierge.lastName}<br />
							{supplierData.concierge.phoneWork} {supplierData.concierge.email} <br />
							Click to learn more.
						</div>
	            	</Button>}
	            	
	        	</div>
	         
	         </Paper>
	        </main>

        </React.Fragment>
    );
}