/** @jsx jsx */
/*global google*/
import { jsx } from '@emotion/core'
import {
  GoogleMap,
  withGoogleMap,
  Marker,
  OverlayView,
  withScriptjs,
} from "react-google-maps";
import { useHistory } from "react-router-dom";
import {
  Box
} from '@material-ui/core';
import { useEffect, useState } from 'react';

import logo from '../../../assets/businessLocation.png';
import { getPixelPositionOffset } from '../../../constants/util';

const MapComponent = withGoogleMap((props) => {
  const { data } = props;
  const [ map, setMap ] = useState(null);
  const [ markers, setMarkers ] = useState(data);
  const history = useHistory()

  const onMapLoad = (gmap) => {
    setMap(gmap);
  };

  const fitBounds = async(values) => {
    const bound  = new window.google.maps.LatLngBounds();
    const data = values.filter(v => v);
    if(data.length){
      data.map(async item => {
        if(item && item.location){
          const point = { lat: item.location.lat(), lng: item.location.lng() };
          bound.extend(point);
        }
      });
      if(map){
        map.fitBounds(bound);
      }
    }


  };

  const onToggleOpen  = (businessName) => {
    const markerStatus = markers.map (item =>{
      if (item && item.business === businessName){
        const newMarkerState = {
          ...item,
          state: !item.state
        }
        return newMarkerState;
      }
      return item;
    })
    setMarkers(markerStatus);
  }

  const entites = {
      '&#039;': "'",
      '&quot;': '"',
  };

  for (var i = 0; i < markers.length; i++) {
    if (typeof markers[i] !== 'undefined') {
        if (typeof markers[i].key === 'string') markers[i].key = markers[i].key.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof markers[i].location === 'string') markers[i].location = markers[i].location.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof markers[i].business === 'string') markers[i].business = markers[i].business.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof markers[i].website === 'string') markers[i].website = markers[i].website.replace(/&#?\w+;/g, match => entites[match]);
        if (typeof markers[i].description === 'string') markers[i].description = markers[i].description.replace(/&#?\w+;/g, match => entites[match]);

    }
  }

  useEffect(() => {
    const fetchData = async (data) => {
      setMarkers(data);
      fitBounds(data);
    };
    fetchData(data);
  },[data]);

  return (

    <GoogleMap
       ref={onMapLoad}
    >
    { (markers && markers.length) && markers.map(item => (
      item &&
      <div  key = {item.key}>
        <Marker
          position={item.location}
          key={item.key}
          id={item.business}
          onClick={() => {
            onToggleOpen(item.business)
          }}
          icon={{
            url: logo,
            scaledSize:  new google.maps.Size(40,40)
            }}
          >

        </Marker>
        { item.state &&
          <OverlayView
            key={'M' + item.key}
            position={item.location}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <Box
              key = {'J'+ item.key}
              style={{ background: `white`, border: `1px solid #ccc`, padding: 15, width: 320}}
             >
             <Box
              key = {'T'+ item.key}
              style={{fontFamily: 'Open+Sans', fontSize: 16, fontWeight: 'bold', padding:15}}>{item.business}
             </Box>
             <Box key = { 'D' + item.key} style={{paddingLeft:15}}>
              <Box
                key = {'S' +  item.key}
                style={{fontFamily: 'Open+Sans', fontSize: 14, fontStyle: 'italic', color:'#5b89b5'}}>{item.description}
              </Box>
              <Box
                key = {'O' +  item.key}
                style={{display:'flex', justifyContent:'space-between', padding: 15}}
              >
                <button
                  key = {'B' + item.key}
                  onClick={()=>( history.push(`/search-details/${item.key}`))}
                  style={{width: 120, height: 44, fontFamily: 'Open+Sans', fontSize: 14,fontWeight: 'bold', textAlign: 'center',
                    color: '#ffffff', backgroundColor: '#2b394a', borderRadius: 4, cursor: 'pointer', border: 'none', outline:'none'}}
                  >VISIT PROFILE
                  </button>
                  <a
                    key = {'L' + item.key}
                    href={item.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <button
                     key = {'I' +  item.key}
                    style={{width: 120, height: 44, fontFamily: 'Open+Sans', fontSize: 14,fontWeight: 'bold', textAlign: 'center',
                      color: '#ffffff', backgroundColor: '#5b89b5', borderRadius: 4, cursor: 'pointer', border: 'none', outline:'none'}}
                    >VISIT WEBSITE</button>
                  </a>
              </Box>
              </Box>
            </Box>
          </OverlayView>
        }
        </div>
      ))
    }
  </GoogleMap>
  );
});

export default MapComponent;
