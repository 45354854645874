/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Box,
  CircularProgress,
} from '@material-ui/core';
import LoadingScreenStyles from './styles';

const LoadingScreen = () => {
  return (
    <div css={LoadingScreenStyles}>
      <Box classes={{root: 'loadingContainer'}}>
        <CircularProgress classes={{root: 'circularProgress'}} />
      </Box>
    </div>
  );
};

export default LoadingScreen;
