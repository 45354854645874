/** @jsx jsx */
import { jsx } from "@emotion/core";
import PaginationStyles from "./styles";
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useQueryParam, withDefault, NumberParam } from "use-query-params";

const PaginationSection = (props) => {
  const { getPageNumber, page, count, oldFilter } = props;
  const [, setPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const handleChange = (event, page) => {
    setPage(page);
  };
  return (
    <div css={PaginationStyles}>
      <Box classes={{ root: "container" }}>
        {count > 0 && (
          <Pagination
            count={count}
            page={page}
            shape="rounded"
            onChange={handleChange}
            classes={{ root: "page" }}
          />
        )}
      </Box>
    </div>
  );
};

export default PaginationSection;
