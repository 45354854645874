import React, { Component } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import { registerData } from '../shared/register-data';
let BPSocket = "wss://imscbpsock.datataskforce.ca";

export class Submit extends Component
{
    createGuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }
    send = e => {
        const response = new Object();
        const guid = this.createGuid();
        response.call = 'NewBusinessData';
        this.props.values.id = guid;
        this.props.values.products_supply = registerData.products_supply;
        this.props.values.products_supply_other = registerData.products_supply_other;
        this.props.values.services_supply = registerData.services_supply;
        this.props.values.services_supply_other = registerData.services_supply_other;
        this.props.values.comments_supply = registerData.comments_supply;
        this.props.values.products_retool = registerData.products_retool;
        this.props.values.products_retool_other = registerData.products_retool_other;
        this.props.values.services_retool = registerData.services_retool;
        this.props.values.services_retool_other = registerData.services_retool_other;
        this.props.values.comments_retool = registerData.comments_retool;
        response.payload = this.props.values;

        console.log('response ', response);

        const myJSON = JSON.stringify(response);
        let socketState = this.props.socketState;

        let conn = new WebSocket(BPSocket);
        conn.onopen = function(e) {
            console.log("Connection established!");
            console.log('myJSON ', myJSON)
            conn.send(myJSON);
            e.preventDefault();
        };

        conn.onerror = function(event) {
            console.error("WebSocket error observed:", event);
            e.preventDefault();
            document.getElementById("error").style.display = "block";
        };

        conn.onmessage = function(e) {
            console.log('e ', e);
        };
    };
    link = e => {
        e.preventDefault();
        window.close();
    };

    render() {
        const errorVisible = {
            display: 'none',
            color: 'red',
            marginTop: '5px',
        };
        const paper = {
            marginTop: '18px',
            marginBottom: '18px',
            padding: '28px',
        };
        const layout = {
            width: '680px',
            marginLeft: 'auto',
            marginRight: 'auto',
        };
        const requireColor = {
            color: 'red',
        }
        const requireText = {
            color: 'red',
            display: 'none',
        }
        const headerName = {
            paddingBottom: '8px',
            marginBottom: '26px',
            marginTop: '12px',
            paddingTop: '8px',
            paddingLeft: '8px',
            backgroundColor: '#868B8E',
            color: '#FFFFFF',
        };
        const headerBlocks = {
            marginBottom: '8px',
        };
        const buttons = {
            display: 'flex',
            justifyContent: 'flex-end',
        };
        const titleBlocks = {
            marginBottom: '8px',
        };
        const button = {
            marginTop: '8px',
            marginLeft: '8px',
        };
        const beforeLink = {
            marginTop: '12px',
        }

        return (
            <React.Fragment>
            <CssBaseline />
            <main style={layout}>
            <Paper style={paper}>

            <React.Fragment>
	            <Typography component="h2" variant="h4" align="center" style={titleBlocks}>
	                Thank You For Your Submission. <br />Once your application is processed, you will be contacted by our management team.
	            </Typography>

            </React.Fragment>

            </Paper>
            </main>
            </React.Fragment>
        );
    }
}

export default Submit;
